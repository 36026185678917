import React, { useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import "./faq.css";
import { FaCircle, FaArrowRight } from "react-icons/fa";
export const TripsFaq = ({ itinerarySubData }) => {
  const [selected, setSelected] = useState(0);
  const toggle = (i) => {
    if (selected === i) {
      return setSelected(i);
    }
    setSelected(i);
  };
  return (
    <>
      <div className="wrapper">
        <div className="accordion">
          {itinerarySubData.map((item, i) => (
            <div className="item items" key={i}>
              <div className={selected === i ? "title active" : "title"} onClick={() => toggle(i)}>
                <h5
                  className={
                    selected === i ? "heading-h5 active" : "heading-h5"
                  }
                >
                  <span>< FaCircle /></span> {item.itineraryHeading}
                </h5>
                {/* <span
                  className={selected === i ? "faq-icons active" : "faq-icons"}
                >
                  {selected === i ? (
                    <IoIosArrowDown className="" />
                  ) : (
                    <IoIosArrowDown />
                  )}
                </span> */}
              </div>
              <div className={selected === i ? "content show" : "content"}>
                {item.itineraryText1 && <p className=" "><span><FaArrowRight /></span> {item.itineraryText1}</p>}
                {item.itineraryText2 && <p className=" "><span><FaArrowRight /></span> {item.itineraryText2}</p>}
                {item.itineraryText3 && <p className=" "><span><FaArrowRight /></span> {item.itineraryText3}</p>}
                {item.itineraryText4 && <p className=" "><span><FaArrowRight /></span> {item.itineraryText4}</p>}
                {item.itineraryText5 && <p className=" "><span><FaArrowRight /></span> {item.itineraryText5}</p>}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

