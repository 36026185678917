import Img1 from "../assets/manali.jpg"
import KashmirImg from "../assets/kashmir/kashmir-tour-4.webp"
import KashmirImgBanner from "../assets/kashmir/kashmir-banner.png"
import KashmirImg2 from "../assets/kashmir/kashmir-tour-2.webp"
import KashmirImg3 from "../assets/kashmir/kashmir-tour-3.webp"
import KashmirImg1 from "../assets/kashmir/kashmir-tour.webp"
import KashmirImg4 from "../assets/kashmir/kashmir-tour-1.webp"
import KashmirImg5 from "../assets/kashmir/kashmir-tour-11.webp"
import Kashmir from "../assets/kashmir/kashmir1.webp"
import Kashmir2 from "../assets/kashmir/kashmir2.webp"
import Kashmir3 from "../assets/kashmir/kashmir3.webp"
import Kashmir4 from "../assets/kashmir/kashmir4.webp"
import Kashmir5 from "../assets/kashmir/kashmir5.webp"
import Kashmir6 from "../assets/kashmir/kashmir6.webp"
import Kashmir7 from "../assets/kashmir/kashmir7.webp"
import Kashmir8 from "../assets/kashmir/kashmir8.webp"
import Kashmir9 from "../assets/kashmir/kashmir9.webp"
import Kashmir10 from "../assets/kashmir/kashmir10.webp"


import KashmirBanner1 from "../assets/kashmir/kashmir-banner-1.png"
import KashmirBanner2 from "../assets/kashmir/kashmir-banner-2.png"
import KashmirBanner3 from "../assets/kashmir/kashmir-banner-3.png"
import KashmirBanner4 from "../assets/kashmir/kashmir-banner-4.png"


import SpitiValley from "../assets/spiti-v/spiti.webp"
import SpitiValley2 from "../assets/spiti-v/spiti2.webp"
import SpitiValleyImg1 from "../assets/spiti-v/spiti1-new.png"
import SpitiValleyImg2 from "../assets/spiti-v/spiti3.png"
import SpitiValleyBanner from "../assets/spiti-v/spiti-banner.png"

import SpitiValleyBanner1 from "../assets/spiti-v/spiti-banner-1.png"
import SpitiValleyBanner2 from "../assets/spiti-v/spiti-banner-2.png"


import ManaliImg1 from "../assets/manali/manali1.webp"
import ManaliImg2 from "../assets/manali/manali2.webp"
import ManaliImg3 from "../assets/manali/manali3.webp"
import ManaliImg4 from "../assets/manali/manali4.webp"
import ManaliImg5 from "../assets/manali/manali5.webp"
import ManaliImg6 from "../assets/manali/manali6.webp"
import ManaliImgD1 from "../assets/manali/manali2.jpg"
import ManaliImgD2 from "../assets/manali/Untitled-3.jpg"
import ManaliImgbanner from "../assets/manali/manali-banner.png"
import ManaliImgbanner1 from "../assets/manali/manali-banner-1.png"
import ManaliImgbanner2 from "../assets/manali/manali-banner-2.png"

import LadakhImg1 from "../assets/ladakh/ladakh1.jpg"
import LadakhImg2 from "../assets/ladakh/ladakh2.webp"
import LadakhImg3 from "../assets/ladakh/ladakh3.webp"
import LadakhImg4 from "../assets/ladakh/ladakh4.webp"
import LadakhImg5 from "../assets/ladakh/ladakh5.webp"
import LadakhImg6 from "../assets/ladakh/ladakh6.webp"
import LadakhImg7 from "../assets/ladakh/ladakh7.webp"
import LadakhImg8 from "../assets/ladakh/ladakh8.webp"
import LadakhImg9 from "../assets/ladakh/ladakh9.webp"
import LadakhImg10 from "../assets/ladakh/ladakh10.webp"
import LadakhBanner from "../assets/ladakh/ladakh-banner.png"
import LadakhBanner1 from "../assets/ladakh/ladakh-banner-1.png"
import LadakhBanner2 from "../assets/ladakh/ladakh-banner-2.png"

import KedarNathImg1 from "../assets/kedarnath/kedarnath1.webp"
import KedarNathImg2 from "../assets/kedarnath/kedarnath2.webp"
import KedarNathImg3 from "../assets/kedarnath/kedarnath3.webp"
import KedarNathImg4 from "../assets/kedarnath/kedarnath4.webp"
import KedarNathImg5 from "../assets/kedarnath/kedarnath5.webp"
import KedarNathImg6 from "../assets/kedarnath/kedarnath6.webp"
import KedarNathImg7 from "../assets/kedarnath/kedarnath7.jpg"
import KedarNathImg8 from "../assets/kedarnath/kedarnath8.webp"
import KedarNathImg9 from "../assets/kedarnath/kedarnath9.webp"
import KedarNathImg10 from "../assets/kedarnath/kedarnath10.webp"
import KedarNathImg11 from "../assets/kedarnath/kedarnath11.webp"
import KedarNathImg12 from "../assets/kedarnath/kedarnath12.webp"
import KedarNathImg13 from "../assets/kedarnath/kedarnath13.webp"
import KedarNathImg14 from "../assets/kedarnath/kedarnath14.webp"
import KedarNathImg15 from "../assets/kedarnath/kedarnath15.webp"
import KedarNathImg16 from "../assets/kedarnath/kedarnath16.webp"
import KedarNathBanner from "../assets/kedarnath/kedarnath-banner.png"
import KedarNathBanner1 from "../assets/kedarnath/kedarnath-banner-1.png"
import KedarNathBanner2 from "../assets/kedarnath/kedarnath-banner-2.png"
import KedarNathBannner3 from "../assets/kedarnath/chardham-banner-3.png"


export const TripData = [
    {
        title: "Kashmir",
        img: KashmirImg,
        url: "/kashmir",
        description: `Kashmir, also known as "Paradise on Earth," is a breathtaking region for travellers with its stunning landscapes, rich heritage, and warm hospitality`,
        bannerImg: KashmirImgBanner,
        tripSubTours: [
            {
                name: "Kashmir Backpacking Trip - 4N/5D",
                photo: KashmirImg1,
                mainImg: KashmirBanner1,
                links: "/Kashmir-Backpacking-Trip-4N/5D",
                days: "5 Days 4 Nights ",
                totalReview: 5,
                price: 16999,
                discount: 10,
                date: "12/02/2024",
                ages: "Min Age: 12+",
                pickup: "Pickup: Airpot",
                content1: "Explore Kashmir with a variety of tours and activities to suit your interests and preferences, from short getaways to extended adventures. Choose from relaxing stays or action-packed itineraries, all designed to create a memorable Kashmir experience.",
                services: [
                    {
                        serviceName: "Departure & Return Location - ",
                        serviceText: "Delhi"
                    },

                ],
                inclusions: [
                    {
                        inclusionsText: "Team Captain"
                    },
                    {
                        inclusionsText: "Comfy Hotel Stay"
                    },
                    {
                        inclusionsText: "Delicious Food "
                    },
                    {
                        inclusionsText: "1-Hour Shikara Ride on Dal Lake, Srinagar"
                    },
                    {
                        inclusionsText: "Complimentary Houseboat Stay."
                    },
                    {
                        inclusionsText: "All transportation in Kashmir"
                    },

                ],
                exclusions: [
                    {
                        exclusionsText: "Air or rail fares "
                    },
                    {
                        exclusionsText: "Anything not explicitly listed in the inclusions is not covered."
                    },
                    {
                        exclusionsText: "Lunch"
                    },
                    {
                        exclusionsText: "Any personal expenses"
                    },
                    {
                        exclusionsText: "Gondola ride"
                    },
                    {
                        exclusionsText: "Parking tickets"
                    },
                ],
                complementaries: [
                    {
                        complementariesText: ""
                    }
                ],
                expect: [
                    {
                        expectHeading: "What to Expect?",
                        expectText: `Kashmir, often referred to as "Paradise on Earth," is a region in northern India renowned for its stunning natural beauty, rich cultural heritage, and warm hospitality. Here's a glimpse of what you can expect on your trip to Kashmir:`,
                        expectSubData: [
                            {
                                expectSubName: "Serene lakes",
                                expectSubText: " Kashmir is home to numerous pristine lakes, including the iconic Dal Lake in Srinagar, renowned for its houseboats and shikara rides."
                            },
                            {
                                expectSubName: "Snow-capped mountains",
                                expectSubText: " The majestic Himalayas form a breathtaking backdrop to the valleys, offering stunning views and opportunities for trekking and mountaineering."
                            },
                            {
                                expectSubName: "Adventure sports",
                                expectSubText: " Kashmir is a paradise for adventure enthusiasts, offering activities like skiing in Gulmarg, trekking in Aru Valley, and white-water rafting on the Lidder River."
                            },
                            {
                                expectSubName: "Camping",
                                expectSubText: " Experience the serenity of nature by camping amidst the picturesque landscapes."
                            },
                            {
                                expectSubName: "Lush meadows",
                                expectSubText: " Valleys like Pahalgam and Sonmarg boast verdant meadows carpeted with wildflowers, especially during spring and summer."
                            },

                        ]
                    }
                ],
                itineraryName: "Itinerary",
                itinerarySubData: [
                    {
                        itineraryHeading: "Day 1: Arrival in Srinagar-",
                        itineraryText1: "Check into your hotel in Srinagar.",
                        itineraryText2: "In the afternoon, explore the city at your own pace. Take a shikara ride on Dal Lake, visit the Hazratbal Shrine, or wander through the Mughal Gardens.",
                        itineraryText3: "In the evening, enjoy a traditional Kashmiri meal at a local restaurant.,   ",
                    },
                    {
                        itineraryHeading: "Day 2: Gulmarg -",
                        itineraryText1: "Early morning, depart from Srinagar for Gulmarg. The drive is about 50 km and takes approximately 2 hours.",
                        itineraryText2: "Enjoy the valley's scenic beauty along the way, passing through Tangmarg, a small town known for its handicrafts.",
                        itineraryText3: "Upon arrival in Gulmarg, take a cable car ride (Gondola) to the top of Apharwat Peak, the highest point in Gulmarg. The views from the top are simply breathtaking.",
                        itineraryText4: "In the evening, return to Srinagar.",

                    },
                    {
                        itineraryHeading: "Day 3: Sonmarg -",
                        itineraryText1: `Early morning, leave from Srinagar for Sonmarg, a hill station known as the "Meadow of Gold." (approximately 80 km, 2-3 hours).`,
                        itineraryText2: "On the way, you can stop at Mammer and Gagangir, two beautiful villages located on the banks of the Sindh River.",
                        itineraryText3: "Once you reach Sonmarg, you can visit the Thajiwas Glacier, a popular tourist destination. You can either trek to the glacier (approximately 2 km) or take a pony ride.",
                        itineraryText4: "Drive back to Srinagar in the evening & overnight stay in a houseboat",

                    },
                    {
                        itineraryHeading: "Day 4: Pahalgam -",
                        itineraryText1: "Depart from Srinagar for Pahalgam, a scenic hill station nestled amidst the Himalayas. ",
                        itineraryText2: `Explore Pahalgam's natural beauty, visiting Betaab Valley, the filming location of the Bollywood movie "Betaab," and Aru Valley, known for its meadows, streams, and horseback riding opportunities.`,
                        itineraryText3: 'Enjoy the stunning scenery of Chandanwari, the starting point for the Amarnath Yatra pilgrimage.',
                        itineraryText4: "Return to Srinagar and relax at your hotel or explore the local markets."
                    },
                    {
                        itineraryHeading: "Day 5: Departure - ",
                        itineraryText1: "En route to Srinagar Airport/Jammu Airport, consider a quick shopping stop in the city center. At the Airport, our driver will help you with luggage screening and drop you at the terminal gate."
                    }
                ],
                relatedData: [
                    {
                        name: "Kashmir Backpacking Trip - 5N/6D",
                        photo: KashmirImg2,
                        links: "/Kashmir-Backpacking-Trip-5N/6D",
                        days: "6 Days 5 Nights       ",
                        totalReview: 5,
                        price: 18999,
                        discount: 10,
                    },
                    {
                        name: "Kashmir Backpacking Trip - 6N/7D       ",
                        photo: KashmirImg3,
                        links: "/Kashmir-Backpacking-Trip-6N/7D",
                        days: "7 Days 6 Nights       ",
                        totalReview: 5,
                        price: 20999,
                        discount: 10,
                    }
                ],
                imageData: [
                    {
                        photo: Kashmir
                    },
                    {
                        photo: KashmirImg
                    },
                    {
                        photo: KashmirImg2
                    },
                    {
                        photo: KashmirImg3
                    },
                    {
                        photo: KashmirImg4
                    },
                    {
                        photo: KashmirImg5
                    },


                ]
            },
            {
                name: "Kashmir Backpacking Trip - 5N/6D",
                photo: KashmirImg2,
                mainImg: KashmirBanner2,
                links: "/Kashmir-Backpacking-Trip-5N/6D",
                days: "6 Days 5 Nights       ",
                totalReview: 5,
                price: 18999,
                discount: 10,
                date: "12/02/2024",
                ages: "Min Age: 12+",
                pickup: "Pickup: Airpot",
                content1: "Explore Kashmir with a variety of tours and activities to suit your interests and preferences, from short getaways to extended adventures. Choose from relaxing stays or action-packed itineraries, all designed to create a memorable Kashmir experience.",
                services: [
                    {
                        serviceName: "Departure & Return Location - ",
                        serviceText: "Delhi"
                    },

                ],
                inclusions: [
                    {
                        inclusionsText: "Team Captain"
                    },
                    {
                        inclusionsText: "Comfy Hotel Stay"
                    },
                    {
                        inclusionsText: "Delicious Food "
                    },
                    {
                        inclusionsText: "1-Hour Shikara Ride on Dal Lake, Srinagar"
                    },
                    {
                        inclusionsText: "Complimentary Houseboat Stay."
                    },
                    {
                        inclusionsText: "All transportation in Kashmir"
                    },

                ],
                exclusions: [
                    {
                        exclusionsText: "Air or rail fares "
                    },
                    {
                        exclusionsText: "Anything not explicitly listed in the inclusions is not covered."
                    },
                    {
                        exclusionsText: "Lunch"
                    },
                    {
                        exclusionsText: "Any personal expenses"
                    },
                    {
                        exclusionsText: "Gondola ride"
                    },
                    {
                        exclusionsText: "Parking tickets"
                    },
                ],
                complementaries: [
                    {
                        complementariesName: "Complementaries",
                        complementariesText: ""
                    }
                ],
                expect: [
                    {
                        expectHeading: "What to Expect?",
                        expectText: `Kashmir, often referred to as "Paradise on Earth," is a region in northern India renowned for its stunning natural beauty, rich cultural heritage, and warm hospitality. Here's a glimpse of what you can expect on your trip to Kashmir:`,
                        expectSubData: [
                            {
                                expectSubName: "Serene lakes",
                                expectSubText: " Kashmir is home to numerous pristine lakes, including the iconic Dal Lake in Srinagar, renowned for its houseboats and shikara rides."
                            },
                            {
                                expectSubName: "Snow-capped mountains",
                                expectSubText: " The majestic Himalayas form a breathtaking backdrop to the valleys, offering stunning views and opportunities for trekking and mountaineering."
                            },
                            {
                                expectSubName: "Adventure sports",
                                expectSubText: " Kashmir is a paradise for adventure enthusiasts, offering activities like skiing in Gulmarg, trekking in Aru Valley, and white-water rafting on the Lidder River."
                            },
                            {
                                expectSubName: "Camping",
                                expectSubText: " Experience the serenity of nature by camping amidst the picturesque landscapes."
                            },
                            {
                                expectSubName: "Lush meadows",
                                expectSubText: " Valleys like Pahalgam and Sonmarg boast verdant meadows carpeted with wildflowers, especially during spring and summer."
                            },

                        ]
                    }
                ],
                itineraryName: "Itinerary",
                itinerarySubData: [
                    {
                        itineraryHeading: "Day 1:Arrival in Srinagar-",
                        itineraryText1: "Check into your hotel in Srinagar.",
                        itineraryText2: "In the afternoon, explore the city at your own pace. Take a shikara ride on Dal Lake, visit the Hazratbal Shrine, or wander through the Mughal Gardens.",
                        itineraryText3: "In the evening, enjoy a traditional Kashmiri meal at a local restaurant.   ",
                    },
                    {
                        itineraryHeading: "Day 2: Gulmarg -",
                        itineraryText1: "Early morning, depart from Srinagar for Gulmarg. The drive is about 50 km and takes approximately 2 hours.",
                        itineraryText2: "Enjoy the valley's scenic beauty along the way, passing through Tangmarg, a small town known for its handicrafts.",
                        itineraryText3: "Upon arrival in Gulmarg, take a cable car ride (Gondola) to the top of Apharwat Peak, the highest point in Gulmarg. The views from the top are simply breathtaking.",
                        itineraryText4: "In the evening, return to Srinagar.",

                    },
                    {
                        itineraryHeading: "Day 3: Sonmarg -",
                        itineraryText1: `Early morning, leave from Srinagar for Sonmarg, a hill station known as the "Meadow of Gold." (approximately 80 km, 2-3 hours).`,
                        itineraryText2: "On the way, you can stop at Mammer and Gagangir, two beautiful villages located on the banks of the Sindh River.",
                        itineraryText3: "Once you reach Sonmarg, you can visit the Thajiwas Glacier, a popular tourist destination. You can either trek to the glacier (approximately 2 km) or take a pony ride.",
                        itineraryText4: "Drive back to Srinagar in the evening & overnight stay in a houseboat",

                    },
                    {
                        itineraryHeading: "Day 4: Doodhpatri                        -",
                        itineraryText1: "Early morning, depart from Srinagar for a scenic drive to Doodhpathri, a valley renowned for its meadows, pine forests, and mesmerizing views. ",
                        itineraryText2: `Upon arrival in Doodhpathri, engage in activities like trekking, horseback riding, or simply relax and soak in the beauty of the surroundings.`,
                        itineraryText3: 'In the evening, return to Srinagar for your overnight stay.',
                    },
                    {
                        itineraryHeading: "Day 5: Pahalgam -",
                        itineraryText1: "Depart from Srinagar for Pahalgam, a scenic hill station nestled amidst the Himalayas. ",
                        itineraryText2: `Explore Pahalgam's natural beauty, visiting Betaab Valley, the filming location of the Bollywood movie "Betaab," and Aru Valley, known for its meadows, streams, and horseback riding opportunities.`,
                        itineraryText3: 'Enjoy the stunning scenery of Chandanwari, the starting point for the Amarnath Yatra pilgrimage.',
                        itineraryText4: "Return to Srinagar and relax at your hotel or explore the local markets."
                    },
                    {
                        itineraryHeading: "Day 6: Departure - ",
                        itineraryText1: "En route to Srinagar Airport/Jammu Airport, consider a quick shopping stop in the city center. At the Airport, our driver will help you with luggage screening and drop you at the terminal gate."
                    }
                ],
                relatedData: [
                    {
                        name: "Kashmir Backpacking Trip - 4N/5D",
                        photo: KashmirImg1,
                        links: "/Kashmir-Backpacking-Trip-4N/5D",
                        days: "5 Days 4 Nights ",
                        totalReview: 5,
                        price: 16999,
                        discount: 10,
                    },
                    {
                        name: "Kashmir Backpacking Trip - 6N/7D       ",
                        photo: KashmirImg3,
                        links: "/Kashmir-Backpacking-Trip-6N/7D",
                        days: "7 Days 6 Nights       ",
                        totalReview: 5,
                        price: 20999,
                        discount: 10,
                    }
                ],
                imageData: [
                    {
                        photo: KashmirImg
                    },
                    {
                        photo: Kashmir2
                    },
                    {
                        photo: Kashmir3
                    },
                    {
                        photo: Kashmir4
                    },
                    {
                        photo: Kashmir5
                    },
                ]
            },
            {
                name: "Kashmir Backpacking Trip - 6N/7D       ",
                photo: KashmirImg3,
                mainImg: KashmirBanner3,
                links: "/Kashmir-Backpacking-Trip-6N/7D",
                days: "7 Days 6 Nights       ",
                totalReview: 5,
                price: 20999,
                discount: 10,
                date: "12/02/2024",
                ages: "Min Age: 12+",
                pickup: "Pickup: Airpot",
                content1: "Explore Kashmir with a variety of tours and activities to suit your interests and preferences, from short getaways to extended adventures. Choose from relaxing stays or action-packed itineraries, all designed to create a memorable Kashmir experience.",

                services: [
                    {
                        serviceName: "Departure & Return Location - ",
                        serviceText: "Delhi"
                    },

                ],
                inclusions: [
                    {
                        inclusionsText: "Team Captain"
                    },
                    {
                        inclusionsText: "Comfy Hotel Stay"
                    },
                    {
                        inclusionsText: "Delicious Food "
                    },
                    {
                        inclusionsText: "1-Hour Shikara Ride on Dal Lake, Srinagar"
                    },
                    {
                        inclusionsText: "Complimentary Houseboat Stay."
                    },
                    {
                        inclusionsText: "All transportation in Kashmir"
                    },

                ],
                exclusions: [
                    {
                        exclusionsText: "Air or rail fares "
                    },
                    {
                        exclusionsText: "Anything not explicitly listed in the inclusions is not covered."
                    },
                    {
                        exclusionsText: "Lunch"
                    },
                    {
                        exclusionsText: "Any personal expenses"
                    },
                    {
                        exclusionsText: "Gondola ride"
                    },
                    {
                        exclusionsText: "Parking tickets"
                    },
                ],
                complementaries: [
                    {
                        complementariesName: "Complementaries",
                        complementariesText: ""
                    }
                ],
                expect: [
                    {
                        expectHeading: "What to Expect?",
                        expectText: `Kashmir, often referred to as "Paradise on Earth," is a region in northern India renowned for its stunning natural beauty, rich cultural heritage, and warm hospitality. Here's a glimpse of what you can expect on your trip to Kashmir:`,
                        expectSubData: [
                            {
                                expectSubName: "Serene lakes",
                                expectSubText: "Kashmir is home to numerous pristine lakes, including the iconic Dal Lake in Srinagar, renowned for its houseboats and shikara rides."
                            },
                            {
                                expectSubName: "Snow-capped mountains",
                                expectSubText: " The majestic Himalayas form a breathtaking backdrop to the valleys, offering stunning views and opportunities for trekking and mountaineering."
                            },
                            {
                                expectSubName: "Adventure sports",
                                expectSubText: " Kashmir is a paradise for adventure enthusiasts, offering activities like skiing in Gulmarg, trekking in Aru Valley, and white-water rafting on the Lidder River."
                            },
                            {
                                expectSubName: "Camping",
                                expectSubText: " Experience the serenity of nature by camping amidst the picturesque landscapes."
                            },
                            {
                                expectSubName: "Lush meadows",
                                expectSubText: " Valleys like Pahalgam and Sonmarg boast verdant meadows carpeted with wildflowers, especially during spring and summer."
                            },

                        ]
                    }
                ],
                itineraryName: "Itinerary",
                itinerarySubData: [
                    {
                        itineraryHeading: "Day 1: Jammum To Srinagar ",
                        itineraryText1: "Arrive in Jammu, Transfer to Srinagar                         ",
                        itineraryText2: "Check into your hotel in Srinagar.",
                        itineraryText3: "You can rest or self-explore. ",
                        itineraryText4: "Enjoy the evening at leisure, explore local markets, or take a stroll around Dal Lake. ",
                        itineraryText5: "Overnight stay in your Srinagar hotel.                        "
                    },
                    {
                        itineraryHeading: "Day 2: Doodhpatri                        ",
                        itineraryText1: "Early morning, depart from Srinagar for a scenic drive to Doodhpathri, a valley renowned for its meadows, pine forests, and mesmerizing views.",
                        itineraryText2: "Upon arrival in Doodhpathri, engage in activities like trekking, and horseback riding, or simply relax and soak in the beauty of the surroundings.",
                        itineraryText3: "In the evening, return to Srinagar for your overnight stay.",


                    },
                    {
                        itineraryHeading: "Day 3: Gulmarg ",
                        itineraryText1: `Early morning, depart from Srinagar for Gulmarg. The drive is about 50 km and takes approximately 2 hours.`,
                        itineraryText2: "Enjoy the valley's scenic beauty along the way, passing through Tangmarg, a small town known for its handicrafts.",
                        itineraryText3: "Upon arrival in Gulmarg, take a cable car ride (Gondola) to the top of Apharwat Peak, the highest point in Gulmarg. The views from the top are simply breathtaking.",
                        itineraryText4: "In the evening, return to Srinagar.",

                    },
                    {
                        itineraryHeading: "Day 4: Sonmarg ",
                        itineraryText1: `Early morning, leave from Srinagar for Sonmarg, a hill station known as the "Meadow of Gold." (approximately 80 km, 2-3 hours).`,
                        itineraryText2: "On the way, you can stop at Mammer and Gagangir, two beautiful villages located on the banks of the Sindh River.",
                        itineraryText3: "Once you reach Sonmarg, you can visit the Thajiwas Glacier, a popular tourist destination. You can either trek to the glacier (approximately 2 km) or take a pony ride.",
                        itineraryText4: "Drive back to Srinagar in the evening & overnight stay in a houseboat",

                    },
                    {
                        itineraryHeading: "Day 5: Explore Srinagar  ",
                        itineraryText1: "Explore the city at your own pace. ",
                        itineraryText2: `Take a shikara ride on Dal Lake, visit the Hazratbal Shrine, or wander through the Mughal Gardens.`,
                        itineraryText3: 'In the evening, enjoy a traditional Kashmiri meal at a local restaurant.',
                    },
                    {
                        itineraryHeading: "Day 6: Srinagar to Pahalgam  ",
                        itineraryText1: "Depart from Srinagar for Pahalgam, a scenic hill station nestled amidst the Himalayas.  ",
                        itineraryText2: `Explore Pahalgam's natural beauty, visiting Betaab Valley, the filming location of the Bollywood movie "Betaab," and Aru Valley, known for its meadows, streams, and horseback riding opportunities.`,
                        itineraryText3: 'Enjoy the stunning scenery of Chandanwari, the starting point for the Amarnath Yatra pilgrimage.',
                        itineraryText4: "Return to Srinagar and relax at your hotel or explore the local markets."
                    },
                    {
                        itineraryHeading: "Day 7: Departure - ",
                        itineraryText1: "En route to Srinagar Airport/Jammu Airport, consider a quick shopping stop in the city center. At the Airport, our driver will help you with luggage screening and drop you at the terminal gate."
                    }
                ]
                ,
                relatedData: [
                    {
                        name: "Kashmir Backpacking Trip - 8N/9D     ",
                        photo: KashmirImg4,
                        links: "/Kashmir-Backpacking-Trip-8N/9D",
                        days: "9 Days 8 Nights       ",
                        totalReview: 5,
                        price: 26999,
                        discount: 10,
                    },
                    {
                        name: "Kashmir Backpacking Trip - 5N/6D",
                        photo: KashmirImg2,
                        links: "/Kashmir-Backpacking-Trip-5N/6D",
                        days: "6 Days 5 Nights       ",
                        totalReview: 5,
                        price: 18999,
                        discount: 10,
                    }
                ],
                imageData: [

                    {
                        photo: Kashmir6
                    },
                    {
                        photo: Kashmir7
                    },
                    {
                        photo: Kashmir8
                    },
                    {
                        photo: Kashmir9
                    },
                    {
                        photo: Kashmir10
                    },


                ]
            },
            {
                name: "Kashmir Backpacking Trip - 8N/9D     ",
                photo: KashmirImg4,
                mainImg: KashmirBanner4,
                links: "/Kashmir-Backpacking-Trip-8N/9D",
                days: "9 Days 8 Nights       ",
                totalReview: 5,
                price: 26999,
                discount: 10,
                date: "12/02/2024",
                ages: "Min Age: 12+",
                pickup: "Pickup: Airpot",
                content1: "Explore Kashmir with a variety of tours and activities to suit your interests and preferences, from short getaways to extended adventures. Choose from relaxing stays or action-packed itineraries, all designed to create a memorable Kashmir experience.",
                services: [
                    {
                        serviceName: "Departure & Return Location - ",
                        serviceText: "Delhi"
                    },

                ],
                inclusions: [
                    {
                        inclusionsText: "Team Captain"
                    },
                    {
                        inclusionsText: "Comfy Hotel Stay"
                    },
                    {
                        inclusionsText: "Delicious Food "
                    },
                    {
                        inclusionsText: "1-Hour Shikara Ride on Dal Lake, Srinagar"
                    },
                    {
                        inclusionsText: "Complimentary Houseboat Stay."
                    },
                    {
                        inclusionsText: "All transportation in Kashmir"
                    },

                ],
                exclusions: [
                    {
                        exclusionsText: "Air or rail fares "
                    },
                    {
                        exclusionsText: "Anything not explicitly listed in the inclusions is not covered."
                    },
                    {
                        exclusionsText: "Lunch"
                    },
                    {
                        exclusionsText: "Any personal expenses"
                    },
                    {
                        exclusionsText: "Gondola ride"
                    },
                    {
                        exclusionsText: "Parking tickets"
                    },
                ],
                complementaries: [
                    {
                        complementariesName: "Complementaries",
                        complementariesText: ""
                    }
                ],
                expect: [
                    {
                        expectHeading: "What to Expect?",
                        expectText: `Kashmir, often referred to as "Paradise on Earth," is a region in northern India renowned for its stunning natural beauty, rich cultural heritage, and warm hospitality. Here's a glimpse of what you can expect on your trip to Kashmir:`,
                        expectSubData: [
                            {
                                expectSubName: "Serene lakes",
                                expectSubText: " Kashmir is home to numerous pristine lakes, including the iconic Dal Lake in Srinagar, renowned for its houseboats and shikara rides."
                            },
                            {
                                expectSubName: "Snow-capped mountains",
                                expectSubText: " The majestic Himalayas form a breathtaking backdrop to the valleys, offering stunning views and opportunities for trekking and mountaineering."
                            },
                            {
                                expectSubName: "Adventure sports",
                                expectSubText: " Kashmir is a paradise for adventure enthusiasts, offering activities like skiing in Gulmarg, trekking in Aru Valley, and white-water rafting on the Lidder River."
                            },
                            {
                                expectSubName: "Camping",
                                expectSubText: " Experience the serenity of nature by camping amidst the picturesque landscapes."
                            },
                            {
                                expectSubName: "Lush meadows",
                                expectSubText: "Valleys like Pahalgam and Sonmarg boast verdant meadows carpeted with wildflowers, especially during spring and summer."
                            },

                        ]
                    }
                ],
                itineraryName: "Itinerary",
                itinerarySubData: [
                    {
                        itineraryHeading: "Day 1: Pickup from Delhi",
                        itineraryText1: "Pick up from your preferred location in Delhi in the early evening.                         ",
                        itineraryText2: "Board a comfortable Volvo AC Sleeper bus to Srinagar. Enjoy the scenic journey through the Himalayas as you relax and unwind.",
                        itineraryText3: "The journey takes approximately 16 hours, so you can settle in for a good night's sleep.                    "
                    },
                    {
                        itineraryHeading: "Day 2: Arrival in Srinagar     ",
                        itineraryText1: "Check into your hotel in Srinagar.                        ",
                        itineraryText2: "In the afternoon, explore the city at your own pace. Take a shikara ride on Dal Lake, visit the Hazratbal Shrine, or wander through the Mughal Gardens.",
                        itineraryText3: "In the evening, enjoy a traditional Kashmiri meal at a local restaurant.",


                    },
                    {
                        itineraryHeading: "Day 3: Doodhpatri  ",
                        itineraryText1: `Early morning, depart from Srinagar for a scenic drive to Doodhpathri, a valley renowned for its meadows, pine forests, and mesmerizing views.`,
                        itineraryText2: "Upon arrival in Doodhpathri, engage in activities like trekking, and horseback riding, or simply relax and soak in the beauty of the surroundings.",
                        itineraryText3: "In the evening, return to Srinagar for your overnight stay.",

                    },
                    {
                        itineraryHeading: "Day 4: Gulmarg  ",
                        itineraryText1: `Early morning, depart from Srinagar for Gulmarg. The drive is about 50 km and takes approximately 2 hours.`,
                        itineraryText2: "Enjoy the valley's scenic beauty along the way, passing through Tangmarg, a small town known for its handicrafts.",
                        itineraryText3: "Upon arrival in Gulmarg, take a cable car ride (Gondola) to the top of Apharwat Peak, the highest point in Gulmarg. The views from the top are simply breathtaking.",
                        itineraryText4: "In the evening, return to Srinagar."

                    },
                    {
                        itineraryHeading: "Day 5: Sonmarg ",
                        itineraryText1: `Early morning, leave from Srinagar for Sonmarg, a hill station known as the "Meadow of Gold." (approximately 80 km, 2-3 hours).`,
                        itineraryText2: "On the way, you can stop at Mammer and Gagangir, two beautiful villages located on the banks of the Sindh River.",
                        itineraryText3: "Once you reach Sonmarg, you can visit the Thajiwas Glacier, a popular tourist destination. You can either trek to the glacier (approximately 2 km) or take a pony ride.",
                        itineraryText4: "Drive back to Srinagar in the evening & overnight stay in a houseboat",

                    },
                    {
                        itineraryHeading: "Day 6: Explore Srinagar  ",
                        itineraryText1: "Explore the city at your own pace. ",
                        itineraryText2: `Take a shikara ride on Dal Lake, visit the Hazratbal Shrine, or wander through the Mughal Gardens.`,
                        itineraryText3: 'In the evening, enjoy a traditional Kashmiri meal at a local restaurant.',
                    },
                    {
                        itineraryHeading: "Day 7: Srinagar to Pahalgam           ",
                        itineraryText1: "Depart from Srinagar for Pahalgam, a scenic hill station nestled amidst the Himalayas.  ",
                        itineraryText2: `Explore Pahalgam's natural beauty, visiting Betaab Valley, the filming location of the Bollywood movie "Betaab," and Aru Valley, known for its meadows, streams, and horseback riding opportunities.                        `,
                        itineraryText3: 'Enjoy the stunning scenery of Chandanwari, the starting point for the Amarnath Yatra pilgrimage.',
                        itineraryText4: "Return to Srinagar and relax at your hotel or explore the local markets."
                    },
                    {
                        itineraryHeading: "Day 8: Departure  ",
                        itineraryText1: "En route to Srinagar Airport/Jammu Airport, consider a quick shopping stop in the city center. At the Airport, our driver will help you with luggage screening and drop you at the terminal gate.                        "
                    }
                ],
                relatedData: [
                    {
                        name: "Kashmir Backpacking Trip - 4N/5D",
                        photo: KashmirImg1,
                        links: "/Kashmir-Backpacking-Trip-4N/5D",
                        days: "5 Days 4 Nights ",
                        totalReview: 5,
                        price: 16999,
                        discount: 10,
                    },
                    {
                        name: "Kashmir Backpacking Trip - 5N/6D",
                        photo: KashmirImg2,
                        links: "/Kashmir-Backpacking-Trip-5N/6D",
                        days: "6 Days 5 Nights       ",
                        totalReview: 5,
                        price: 18999,
                        discount: 10,
                    }
                ],
                imageData: [
                    {
                        photo: Kashmir
                    },
                    {
                        photo: KashmirImg
                    },
                    {
                        photo: KashmirImg2
                    },
                    {
                        photo: KashmirImg3
                    },
                    {
                        photo: KashmirImg4
                    },
                    {
                        photo: KashmirImg5
                    },
                ]
            }
        ]

    },
    {
        title: "Manali",
        img: ManaliImg3,
        url: "/manali",
        description: `Calling all adventure seekers and nature lovers! Manali is situated in the Himalayas. It's like a paradise with breathtaking beauty, thrilling activities, and a peaceful vibe. 
        `,
        bannerImg: ManaliImgbanner,
        tripSubTours: [
            {
                name: "Manali Budget Plan - 4N5D",
                photo: ManaliImg1,
                mainImg: ManaliImgbanner1,
                links: "/manali-budget-plan-4N5D",
                days: "5 Days 4 Nights",
                totalReview: 5,
                price: 14999,
                discount: 10,
                date: "12/02/2024",
                ages: "Min Age: 12+",
                pickup: "Pickup: Airpot",
                content1: "Explore manali with a variety of tours and activities to suit your interests and preferences, from short getaways to extended adventures. Choose from relaxing stays or action-packed itineraries, all designed to create a memorable manali experience.",
                services: [
                    {
                        serviceName: "Departure & Return Location - ",
                        serviceText: "Delhi"
                    },

                ],
                inclusions: [
                    {
                        inclusionsText: "Team Captain"
                    },
                    {
                        inclusionsText: "Pick up & Drop Transport from Delhi to Manali and Manali to Delhi (Volvo/Tempo Traveller as per availability)                        "
                    },
                    {
                        inclusionsText: "Comfy Hotel Stay - 02 Nights Accommodation"
                    },
                    {
                        inclusionsText: "Delicious Food  - 2 Breakfasts & 2 Dinners."
                    },
                    {
                        inclusionsText: "All Sightseeing & Transfers (As per itinerary) Arrival/ Departure Assistance                        Rooftop Music Party & Bonfire(New Year)"
                    },
                ],
                exclusions: [
                    {
                        exclusionsText: "Anything not explicitly listed in the inclusions is not covered.                        "
                    },
                    {
                        exclusionsText: "Lunch"
                    },
                    {
                        exclusionsText: "Any personal expenses"
                    },
                    {
                        exclusionsText: "Parking tickets"
                    },

                ],
                complementaries: [
                    {
                        complementariesName: "Complementaries",
                        complementariesText: ""
                    }
                ],
                expect: [
                    {
                        expectHeading: "What to Expect?",
                        expectText: `Manali is a hill station nestled in the Beas River Valley of Himachal Pradesh, India. It is known for its natural beauty, adventure activities, and pleasant climate. Here's a glimpse of what you can expect on a trip to Manali:`,
                        expectSubData: [
                            {
                                expectSubName: "Stunning Scenery",
                                expectSubText: "Manali is surrounded by snow-capped mountains, lush green valleys, and cascading waterfalls."
                            },
                            {
                                expectSubName: "Pleasant Climate",
                                expectSubText: " Manali has a pleasant climate throughout the year. The summers are cool and refreshing, while the winters are cold and snowy.                                "
                            },
                            {
                                expectSubName: "Tibetan Culture",
                                expectSubText: "  Manali has a strong Tibetan influence. You can visit Tibetan monasteries, sample Tibetan cuisine, and shop for Tibetan handicrafts.                                 "
                            },
                            {
                                expectSubName: "Food",
                                expectSubText: " Manali offers a variety of cuisines, including Himachali, Tibetan, and North Indian food.                                 "
                            },


                        ]
                    }
                ],
                itineraryName: "Itinerary",
                itinerarySubData: [
                    {
                        itineraryHeading: "Day 1: Journey Begins In Delhi ",
                        itineraryText1: "Meet at R.K Ashram or Majnu Ka Tila                          ",
                        itineraryText2: "Your travel squad's meeting point                         ",
                        itineraryText3: "Ready to explore together!",
                    },
                    {
                        itineraryHeading: "Day 2: Explore Manali                         ",
                        itineraryText1: "Get ready for a great time exploring the town! Start fresh, energize yourself, and enjoy your sightseeing adventure to the fullest.                        ",

                    },
                    {
                        itineraryHeading: "Day 3: Solang Valley",
                        itineraryText1: `Explore snowy wonderlands. Thrilling activities: River Crossing, Paragliding, Hot Air Ballooning, Zorbing, Pony Riding, etc.`,

                    },
                    {
                        itineraryHeading: "Day 4: Off to Delhi via Kullu-Naggar:     ",
                        itineraryText1: "Check out hassle-free from the cozy hotel at 09:00 AM  ",
                        itineraryText2: `Explore Kullu-Naggar's beauty, then explore the market. 
                        `,
                        itineraryText3: 'Depart for Delhi on a comfy evening Volvo ride.'
                    },
                    {
                        itineraryHeading: "Day 5:  Reach Delhi       ",
                        itineraryText1: "In the early morning, we'll reach Delhi, from where you can go your way with Happy memories.                        "
                    }
                ],
                relatedData: [
                    {
                        name: "Manali Budget Plan - 4N5D",
                        photo: ManaliImg1,
                        links: "/manali-budget-plan-4N5D",
                        days: "5 Days 4 Nights",
                        totalReview: 5,
                        price: 14999,
                        discount: 10,
                    },
                    {
                        name: "Manali Deluxe Plan - 5N6D",
                        photo: ManaliImg4,
                        links: "/manali-deluxe-plan-5N6D",
                        days: "6 Days 5 Nights",
                        totalReview: 5,
                        price: 11999,
                        discount: 10,
                    }
                ],
                imageData: [
                    {
                        photo: ManaliImg2
                    },
                    {
                        photo: ManaliImg3
                    },
                    {
                        photo: ManaliImg4
                    },
                    {
                        photo: ManaliImg5
                    },

                ]
            },
            {
                name: "Manali Deluxe Plan - 5N6D",
                photo: ManaliImg4,
                mainImg: ManaliImgbanner2,
                links: "/manali-deluxe-plan-5N6D",
                days: "6 Days 5 Nights",
                totalReview: 5,
                price: 11999,
                discount: 10,
                date: "12/02/2024",
                ages: "Min Age: 12+",
                pickup: "Pickup: Airpot",
                content1: "Explore manali with a variety of tours and activities to suit your interests and preferences, from short getaways to extended adventures. Choose from relaxing stays or action-packed itineraries, all designed to create a memorable manali experience.",
                services: [
                    {
                        serviceName: "Departure & Return Location - ",
                        serviceText: "Delhi"
                    },

                ],
                inclusions: [
                    {
                        inclusionsText: "Team Captain                        "
                    },
                    {
                        inclusionsText: "Pick up & Drop Transport from Delhi to Manali and Manali to Delhi (Volvo/Tempo Traveller as per availability)  "
                    },
                    {
                        inclusionsText: "Comfy Hotel Stay - 02 Nights Accommodation"
                    },
                    {
                        inclusionsText: "Delicious Food  - 2 Breakfasts & 2 Dinners."
                    },
                    {
                        inclusionsText: "All Sightseeing & Transfers (As per itinerary) Arrival/ Departure Assistance                        Rooftop Music Party & Bonfire(New Year)"
                    },


                ],
                exclusions: [
                    {
                        exclusionsText: "Anything not explicitly listed in the inclusions is not covered.                        "
                    },
                    {
                        exclusionsText: "Lunch"
                    },
                    {
                        exclusionsText: "Any personal expenses"
                    },
                    {
                        exclusionsText: "Parking tickets"
                    },

                ],
                complementaries: [
                    {
                        complementariesName: "Complementaries",
                        complementariesText: ""
                    }
                ],
                expect: [
                    {
                        expectHeading: "What to Expect?",
                        expectText: `Manali is a hill station nestled in the Beas River Valley of Himachal Pradesh, India. It is known for its natural beauty, adventure activities, and pleasant climate. Here's a glimpse of what you can expect on a trip to Manali:`,
                        expectSubData: [
                            {
                                expectSubName: "Stunning Scenery",
                                expectSubText: "Manali is surrounded by snow-capped mountains, lush green valleys, and cascading waterfalls."
                            },
                            {
                                expectSubName: "Pleasant Climate",
                                expectSubText: " Manali has a pleasant climate throughout the year. The summers are cool and refreshing, while the winters are cold and snowy.                                "
                            },
                            {
                                expectSubName: "Tibetan Culture",
                                expectSubText: "  Manali has a strong Tibetan influence. You can visit Tibetan monasteries, sample Tibetan cuisine, and shop for Tibetan handicrafts.                                 "
                            },
                            {
                                expectSubName: "Food",
                                expectSubText: " Manali offers a variety of cuisines, including Himachali, Tibetan, and North Indian food.                                 "
                            },


                        ]
                    }
                ],
                itineraryName: "Itinerary",
                itinerarySubData: [
                    {
                        itineraryHeading: "Day 1: Journey Begins In Delhi ",
                        itineraryText1: "Meet at R.K Ashram or Majnu Ka Tila                          ",
                        itineraryText2: "Your travel squad's meeting point                         ",
                        itineraryText3: "Ready to explore together!",
                    },
                    {
                        itineraryHeading: "Day 2: Explore Manali                         ",
                        itineraryText1: "Get ready for a great time exploring the town! Start fresh, energize yourself, and enjoy your sightseeing adventure to the fullest. ",

                    },
                    {
                        itineraryHeading: "Day 3: Solang Valley",
                        itineraryText1: `Explore snowy wonderlands. Thrilling activities: River Crossing, Paragliding, Hot Air Ballooning, Zorbing, Pony Riding, etc.`,

                    },
                    {
                        itineraryHeading: "Day 4: Jogini Waterfall",
                        itineraryText1: `Start your day early with a trek to Jogini Waterfall. This is a moderate trek that takes about 2-3 hours to complete. `,
                        itineraryText2: "In the afternoon, head to Kullu and Manikaran.",
                        itineraryText3: "In the evening, return to Manali and relax at your hotel. You can also explore Mall Road in the evening for some shopping and local snacks.",

                    },
                    {
                        itineraryHeading: "Day 5: Off to Delhi via Kullu-Naggar:     ",
                        itineraryText1: "Check out hassle-free from the cozy hotel at 09:00 AM  ",
                        itineraryText2: `Explore Kullu-Naggar's beauty, then explore the market. 
                        `,
                        itineraryText3: 'Depart for Delhi on a comfy evening Volvo ride.'
                    },
                    {
                        itineraryHeading: "Day 6:  Reach Delhi       ",
                        itineraryText1: "In the early morning, we'll reach Delhi, from where you can go your way with Happy memories. "
                    }
                ],
                relatedData: [
                    {
                        name: "Manali Budget Plan - 4N5D",
                        photo: ManaliImg1,
                        links: "/manali-budget-plan-4N5D",
                        days: "5 Days 4 Nights",
                        totalReview: 5,
                        price: 14999,
                        discount: 10
                    },
                    {
                        name: "Manali Deluxe Plan - 5N6D",
                        photo: ManaliImg4,
                        links: "/manali-deluxe-plan-5N6D",
                        days: "6 Days 5 Nights",
                        totalReview: 5,
                        price: 11999,
                        discount: 10
                    }
                ],
                imageData: [
                    {
                        photo: ManaliImg1
                    },
                    {
                        photo: ManaliImg6
                    },
                    {
                        photo: ManaliImg3
                    },
                    {
                        photo: ManaliImg4
                    },
                    {
                        photo: ManaliImg5
                    },

                ]
            },

        ]

    },
    {
        title: "Ladakh ",
        img: LadakhImg8,
        url: "/ladakh",
        bannerImg: LadakhBanner,
        description: `Get Ready for an unforgettable adventure to Ladakh, the "Land of High Passes," in the Himalayas. Leh Ladakh is a paradise for nature lovers and adventure seekers alike. `,
        tripSubTours: [
            {
                name: "Ladakh Deluxe Tour - Delhi to Srinagar - 11D/10N ",
                photo: LadakhImg4,
                mainImg: LadakhBanner1,
                links: "/ladakh-deluxe-tour-delhi-to-strinagar-11D/10N",
                days: "11 Days 10 Nights",
                totalReview: 5,
                price: 29999,
                discount: 10,
                date: "12/02/2024",
                ages: "Min Age: 12+",
                pickup: "Pickup: Airpot",
                content1: `Get Ready for an unforgettable adventure to Ladakh, the "Land of High Passes," in the Himalayas. Leh Ladakh is a paradise for nature lovers and adventure seekers alike. `,

                services: [
                    {
                        serviceName: "Departure & Return Location - ",
                        serviceText: "Delhi"
                    },

                ],
                inclusions: [
                    {
                        inclusionsText: "Team Captain                        "
                    },
                    {
                        inclusionsText: "Royal Enfield Himalayan motorcycle with fuel  "
                    },
                    {
                        inclusionsText: "Comfy Hotel Stay"
                    },
                    {
                        inclusionsText: "Delicious Food "
                    },
                    {
                        inclusionsText: "1-Hour Shikara Ride on Dal Lake, Srinagar"
                    },
                    {
                        inclusionsText: "Complimentary Houseboat Stay.",
                    },
                    {
                        inclusionsText: "All transportation",
                    },
                    {
                        inclusionsText: "Portable oxygen cylinders & First Aid Kit",
                    },
                    {
                        inclusionsText: "Helmets for Rider and Pillion, Knee Guard & Riding Jacket for Both Rider & Pillion",
                    },
                    {
                        inclusionsText: "Mechanic ",
                    },
                    {
                        inclusionsText: "Inner Line Permit ",
                    },
                    {
                        inclusionsText: "A backup vehicle for Luggage ",
                    },
                    {
                        inclusionsText: "Certificate of Riding on completion",
                    },

                ],
                exclusions: [
                    {
                        exclusionsText: "Air or rail fares "
                    },
                    {
                        exclusionsText: "Anything not explicitly listed in the inclusions is not covered."
                    },
                    {
                        exclusionsText: "Lunch"
                    },
                    {
                        exclusionsText: "Any personal expenses"
                    },
                    {
                        exclusionsText: "Parking tickets"
                    },
                    {
                        exclusionsText: "GST @ 5% is Excluded Inner line permits and Restricted area permits for Ladakh region "
                    },


                ],
                complementaries: [
                    {
                        complementariesName: "Complementaries",
                        complementariesText: ""
                    }
                ],
                expect: [
                    {
                        expectHeading: "What to Expect?",
                        expectText: `Ladakh
                        is a hill station nestled in the Beas River Valley of Himachal Pradesh, India. It is known for its natural beauty, adventure activities, and pleasant climate. Here's a glimpse of what you can expect on a trip to Ladakh
                        :`,
                        expectSubData: [
                            {
                                expectSubName: "Challenging terrain",
                                expectSubText: "The roads in Ladakh can be rough and challenging, with steep climbs, loose gravel, and water crossings. "
                            },
                            {
                                expectSubName: "High altitude",
                                expectSubText: " Ladakh is located at a high altitude, so be sure to acclimatize properly before you start riding.              "
                            },
                            {
                                expectSubName: "Unique culture",
                                expectSubText: "Ladakh is a Buddhist region with a rich culture and history. You'll have the opportunity to visit monasteries, gompas, and meet the friendly Ladakhi people.                "
                            },
                            {
                                expectSubName: "Unforgettable experience",
                                expectSubText: "A Ladakh bike tour is a challenging but rewarding experience that will stay with you for a lifetime.                                "
                            },
                            {
                                expectSubName: "Breathtaking landscapes",
                                expectSubText: "Ladakh is home to some of the highest mountain passes in the world, including the Khardung La and Chang La.  "
                            },


                        ]
                    }
                ],
                itineraryName: "Itinerary",
                itinerarySubData: [
                    {
                        itineraryHeading: "Day 1:  Delhi to Manali                       ",
                        itineraryText1: "Meet at R.K Ashram or Majnu Ka Tila                          ",
                        itineraryText2: "Your travel squad's meeting point                         ",
                        itineraryText3: "Ready to explore together!",
                    },
                    {
                        itineraryHeading: "Day 2:  Reached Manali                       ",
                        itineraryText1: "Get ready for a great time exploring the town! Start fresh, energize yourself, and enjoy your sightseeing adventure to the fullest.                        ",

                    },
                    {
                        itineraryHeading: "Day 3: Manali to Sarchu                        ",
                        itineraryText1: `Take it easy on this day to allow your body to adjust to the higher altitude of Manali
                        `,
                        itineraryText2: "You'll cross the Baralacha La Pass (16,040 ft), the second-highest motorable pass in the region.",
                        itineraryText3: "Sarchu is a high-altitude plateau (around 14,000 ft) with basic accommodation options. Relax and enjoy the serene beauty of the surrounding mountains."

                    },
                    {
                        itineraryHeading: "Day 4: Sarchu to Leh                        ",
                        itineraryText1: "Today's journey is from Jispa/Sarchu to Leh, which is about 250 km and takes around 8 hours.",
                        itineraryText2: `The route takes you through some of the most spectacular scenery in Ladakh, including the high mountain passes of Baralacha La (4,890 m), Lachung La (5,050 m), and Tanglang La (5,350 m), which is the second highest motorable road in the world after Khardung La.`,
                        itineraryText3: 'You will also come across beautiful lakes like Suraj Tal and Pangong Tso.'
                    },
                    {
                        itineraryHeading: "Day 5:  Leh to Nubra valley                        ",
                        itineraryText1: "Get an early start as you embark on a thrilling journey from Leh to Nubra Valley via the Khardung La Pass                     ",
                        itineraryText2: "Upon reaching Nubra Valley, check into your hotel in Hunder or Diskit and spend the rest of the day exploring the surrounding area.  ",
                        itineraryText3: `You can visit the Diskit Monastery, the largest monastery in Nubra Valley, or take a camel ride on the sand dunes of Hunder, also known as the "cold desert."`
                    },
                    {
                        itineraryHeading: "Day 6:  Turtuk Village                        ",
                        itineraryText1: "Known as a village divided by a border, Turtuk has many stories to tell, Turtuk opened its doors to inquisitive souls in 2010.         ",
                        itineraryText2: "Turtuk was part of Pakistan–occupied Kashmir up until 1971 when Major Chewang Rinchen got the village under India’s command.                         ",
                        itineraryText3: `Many born before 1947, went from being Indians to Pakistanis to Indians again. "`,
                        itineraryText4: "Only 300 families call Turtuk home, many have relatives on the other side. of the border."
                    },
                    {
                        itineraryHeading: "Day 7:  Nubra Valley to Pangong Lake                        ",
                        itineraryText1: "Get an early start today as it's a long drive to Pangong Lake.                        ",
                        itineraryText2: "Take the Shyok Road, a more scenic route than going back to Leh and then to Pangong Lake.                 ",
                        itineraryText3: `Pangong Tso, also known as Pangong Lake, is a high-altitude lake famous for its stunning beauty and changing colors. `,

                    },
                    {
                        itineraryHeading: "Day 8:  Pangong to Leh                        ",
                        itineraryText1: "Enjoy the sunrise over Pangong Lake before returning to Leh.   ",
                        itineraryText2: "Today's route takes you through Chang La Pass (5,360 meters), another high mountain pass.        ",
                        itineraryText3: `On the way back to Leh, you can stop at Hemis Monastery, the largest monastery in Ladakh.`,
                        itineraryText4: "You can also visit Thiksey Monastery, perched on a hilltop and offers stunning views of the surrounding valley."
                    },
                    {
                        itineraryHeading: "Day 9: Leh to Kargil                        ",
                        itineraryText1: "After breakfast, embark on a scenic drive from Leh to Kargil, a distance of about 220 kilometers. ",
                        itineraryText2: "Along the way, stop at some of the following places:   ",
                        itineraryText3: `Magnetic Hill: An optical illusion that makes it appear vehicles roll uphill on their own.`,
                        itineraryText4: "Hall of Fame: A war memorial dedicated to the Indian soldiers who died in the Kargil War.",
                        itineraryText5: "Indus-Zanskar confluence: The meeting point of the Indus and Zanskar rivers."
                    },
                    {
                        itineraryHeading: "Day 10:  Kargil to Srinagar                        ",
                        itineraryText1: "This journey is about 200 kilometers and can take anywhere between 7 to 10 hours depending on your travel mode and sightseeing stops. ",
                        itineraryText2: "On your way to Srinagar, you can make a detour to Sonamarg, a hill station known for its meadows and glaciers. ",
                        itineraryText3: `You can also visit the Drass War Memorial, another war memorial dedicated to the Indian soldiers who fought in the Kargil War.
                        `,
                    },
                    {
                        itineraryHeading: "Day 11:  Checkout from Hotel                        ",
                        itineraryText1: "After a hearty breakfast, check out from your hotel and transfer to Leh Airport for your onward journey. ",

                    },
                ],
                relatedData: [
                    {
                        name: "Ladakh Deluxe Tour - Delhi to Srinagar - 11D/10N ",
                        photo: LadakhImg4,
                        links: "/ladakh-deluxe-tour-delhi-to-strinagar-11D/10N",
                        days: "11 Days 10 Nights",
                        totalReview: 5,
                        price: 29999,
                        discount: 10,
                    }
                    , {
                        name: "Ladakh Premium Tour - Delhi to Srinagar - 11D/10N                ",
                        photo: LadakhImg9,
                        links: "/ladakh-premium-tour-delhi-to-strinagar-11D/10N",
                        days: "11 Days 10 Nights",
                        totalReview: 5,
                        price: 56999,
                        discount: 10,
                    }
                ],
                imageData: [
                    {
                        photo: LadakhImg1
                    },
                    {
                        photo: LadakhImg2
                    },
                    {
                        photo: LadakhImg3
                    },
                    {
                        photo: LadakhImg4
                    },
                    {
                        photo: LadakhImg5
                    },
                ]
            },
            {
                name: "Ladakh Premium Tour - Delhi to Srinagar - 11D/10N                ",
                photo: LadakhImg9,
                mainImg: LadakhBanner2,
                links: "/ladakh-premium-tour-delhi-to-strinagar-11D/10N",
                days: "11 Days 10 Nights",
                totalReview: 5,
                price: 56999,
                discount: 10,
                date: "12/02/2024",
                ages: "Min Age: 12+",
                pickup: "Pickup: Airpot",
                content1: `Get Ready for an unforgettable adventure to Ladakh, the "Land of High Passes," in the Himalayas. Leh Ladakh is a paradise for nature lovers and adventure seekers a like.`,

                services: [
                    {
                        serviceName: "Departure & Return Location - ",
                        serviceText: "Delhi"
                    },

                ],
                inclusions: [
                    {
                        inclusionsText: "Team Captain                        "
                    },
                    {
                        inclusionsText: "Royal Enfield Himalayan motorcycle with fuel  "
                    },
                    {
                        inclusionsText: "Comfy Hotel Stay"
                    },
                    {
                        inclusionsText: "Delicious Food "
                    },
                    {
                        inclusionsText: "1-Hour Shikara Ride on Dal Lake, Srinagar"
                    },
                    {
                        inclusionsText: "Complimentary Houseboat Stay.",
                    },
                    {
                        inclusionsText: "All transportation",
                    },
                    {
                        inclusionsText: "Portable oxygen cylinders & First Aid Kit",
                    },
                    {
                        inclusionsText: "Helmets for Rider and Pillion, Knee Guard & Riding Jacket for Both Rider & Pillion",
                    },
                    {
                        inclusionsText: "Mechanic ",
                    },
                    {
                        inclusionsText: "Inner Line Permit ",
                    },
                    {
                        inclusionsText: "A backup vehicle for Luggage ",
                    },
                    {
                        inclusionsText: "Certificate of Riding on completion",
                    },

                ],
                exclusions: [
                    {
                        exclusionsText: "Air or rail fares "
                    },
                    {
                        exclusionsText: "Anything not explicitly listed in the inclusions is not covered."
                    },
                    {
                        exclusionsText: "Lunch"
                    },
                    {
                        exclusionsText: "Any personal expenses"
                    },
                    {
                        exclusionsText: "Parking tickets"
                    },
                    {
                        exclusionsText: "GST @ 5% is Excluded Inner line permits and Restricted area permits for Ladakh region "
                    },


                ],
                complementaries: [
                    {
                        complementariesName: "Complementaries",
                        complementariesText: ""
                    }
                ],
                expect: [
                    {
                        expectHeading: "What to Expect?",
                        expectText: `Ladakh                        is a hill station nestled in the Beas River Valley of Himachal Pradesh, India. It is known for its natural beauty, adventure activities, and pleasant climate. Here's a glimpse of what you can expect on a trip to Ladakh
                        :`,
                        expectSubData: [
                            {
                                expectSubName: "Challenging terrain",
                                expectSubText: "The roads in Ladakh can be rough and challenging, with steep climbs, loose gravel, and water crossings.  "
                            },
                            {
                                expectSubName: "High altitude",
                                expectSubText: " Ladakh is located at a high altitude, so be sure to acclimatize properly before you start riding.                                 "
                            },
                            {
                                expectSubName: "Unique culture",
                                expectSubText: "Ladakh is a Buddhist region with a rich culture and history. You'll have the opportunity to visit monasteries, gompas, and meet the friendly Ladakhi people.                                "
                            },
                            {
                                expectSubName: "Unforgettable experience",
                                expectSubText: "A Ladakh bike tour is a challenging but rewarding experience that will stay with you for a lifetime.                                "
                            },
                            {
                                expectSubName: "Breathtaking landscapes",
                                expectSubText: "Ladakh is home to some of the highest mountain passes in the world, including the Khardung La and Chang La.  "
                            },


                        ]
                    }
                ],
                itineraryName: "Itinerary",
                itinerarySubData: [
                    {
                        itineraryHeading: "Day 1:  Delhi to Manali                       ",
                        itineraryText1: "Meet at R.K Ashram or Majnu Ka Tila                          ",
                        itineraryText2: "Your travel squad's meeting point                         ",
                        itineraryText3: "Ready to explore together!",
                    },
                    {
                        itineraryHeading: "Day 2:  Reached Manali                       ",
                        itineraryText1: "Get ready for a great time exploring the town! Start fresh, energize yourself, and enjoy your sightseeing adventure to the fullest.                        ",

                    },
                    {
                        itineraryHeading: "Day 3: Manali to Sarchu                        ",
                        itineraryText1: `Take it easy on this day to allow your body to adjust to the higher altitude of Manali
                        `,
                        itineraryText2: "You'll cross the Baralacha La Pass (16,040 ft), the second-highest motorable pass in the region.",
                        itineraryText3: "Sarchu is a high-altitude plateau (around 14,000 ft) with basic accommodation options. Relax and enjoy the serene beauty of the surrounding mountains."

                    },
                    {
                        itineraryHeading: "Day 4: Sarchu to Leh                        ",
                        itineraryText1: "Today's journey is from Jispa/Sarchu to Leh, which is about 250 km and takes around 8 hours.",
                        itineraryText2: `The route takes you through some of the most spectacular scenery in Ladakh, including the high mountain passes of Baralacha La (4,890 m), Lachung La (5,050 m), and Tanglang La (5,350 m), which is the second highest motorable road in the world after Khardung La.`,
                        itineraryText3: 'You will also come across beautiful lakes like Suraj Tal and Pangong Tso.'
                    },
                    {
                        itineraryHeading: "Day 5:  Leh to Nubra valley                        ",
                        itineraryText1: "Get an early start as you embark on a thrilling journey from Leh to Nubra Valley via the Khardung La Pass                     ",
                        itineraryText2: "Upon reaching Nubra Valley, check into your hotel in Hunder or Diskit and spend the rest of the day exploring the surrounding area.  ",
                        itineraryText3: `You can visit the Diskit Monastery, the largest monastery in Nubra Valley, or take a camel ride on the sand dunes of Hunder, also known as the "cold desert."`
                    },
                    {
                        itineraryHeading: "Day 6:  Turtuk Village                        ",
                        itineraryText1: "Known as a village divided by a border, Turtuk has many stories to tell, Turtuk opened its doors to inquisitive souls in 2010.         ",
                        itineraryText2: "Turtuk was part of Pakistan–occupied Kashmir up until 1971 when Major Chewang Rinchen got the village under India’s command.                         ",
                        itineraryText3: `Many born before 1947, went from being Indians to Pakistanis to Indians again. "`,
                        itineraryText4: "Only 300 families call Turtuk home, many have relatives on the other side. of the border."
                    },
                    {
                        itineraryHeading: "Day 7:  Nubra Valley to Pangong Lake                        ",
                        itineraryText1: "Get an early start today as it's a long drive to Pangong Lake.                        ",
                        itineraryText2: "Take the Shyok Road, a more scenic route than going back to Leh and then to Pangong Lake.                 ",
                        itineraryText3: `Pangong Tso, also known as Pangong Lake, is a high-altitude lake famous for its stunning beauty and changing colors. `,

                    },
                    {
                        itineraryHeading: "Day 8:  Pangong Lake to Hanle  ",
                        itineraryText1: "Obtain necessary permits to enter the restricted area of Hanle. ",
                        itineraryText2: `Take a challenging drive through the Changthang plateau to reach Handle, one of the world's highest villages with an astronomical observatory.`,
                        itineraryText3: "Spend the night in a homestay or guesthouse in Hanle.                        "
                    },
                    {
                        itineraryHeading: "Day 9: HANLE - Umlingla-la - Hanle                        ",
                        itineraryText1: "Take a day trip to Umling La, the highest motorable road in the world, offering breathtaking views of the Himalayas.",
                        itineraryText2: "Be prepared for the high altitude and harsh weather conditions.  ",
                    },
                    {
                        itineraryHeading: "Day 10:   Explore Leh                        ",
                        itineraryText1: "Spend the day exploring Leh at your own pace.                        ",
                        itineraryText2: "Visit the Leh Palace, the former royal palace of the Kings of Ladakh                        ",
                        itineraryText3: `Explore the bustling markets of Leh and do some souvenir shopping.          `,
                        itineraryText4: "Visit the Hall of Fame, a war memorial dedicated to the Indian soldiers who died in the Indo-Pakistani wars."
                    },
                    {
                        itineraryHeading: "Day 11: Leh to Kargil                        ",
                        itineraryText1: "After breakfast, embark on a scenic drive from Leh to Kargil, a distance of about 220 kilometers.",
                        itineraryText2: "Along the way, stop at some of the following places:",
                        itineraryText3: "Magnetic Hill: An optical illusion that makes it appear vehicles roll uphill on their own.",
                        itineraryText4: "Hall of Fame: A war memorial dedicated to the Indian soldiers who died in the Kargil War.",
                        itineraryText5: "Indus-Zanskar confluence: The meeting point of the Indus and Zanskar rivers."

                    },
                    {
                        itineraryHeading: "Day 12: Kargil to Srinagar                        ",
                        itineraryText1: "This journey is about 200 kilometers and can take anywhere between 7 to 10 hours depending on your travel mode and sightseeing stops.                        ",
                        itineraryText2: "On your way to Srinagar, you can make a detour to Sonamarg, a hill station known for its meadows and glaciers.",
                        itineraryText3: "You can also visit the Drass War Memorial, another war memorial dedicated to the Indian soldiers who fought in the Kargil War."
                    },
                    {
                        itineraryHeading: "Day 13: Checkout from Hotel                        ",
                        itineraryText1: "After a hearty breakfast, check out from your hotel and transfer to Leh Airport for your onward journey."
                    }
                ],
                relatedData: [
                    {
                        name: "Ladakh Deluxe Tour - Delhi to Srinagar - 11D/10N ",
                        photo: LadakhImg4,
                        links: "/ladakh-deluxe-tour-delhi-to-strinagar-11D/10N",
                        days: "11 Days 10 Nights",
                        totalReview: 5,
                        price: 29999,
                        discount: 10,
                    }
                    , {
                        name: "Ladakh Premium Tour - Delhi to Srinagar - 11D/10N                ",
                        photo: LadakhImg9,
                        links: "/ladakh-premium-tour-delhi-to-strinagar-11D/10N",
                        days: "11 Days 10 Nights",
                        totalReview: 5,
                        price: 56999,
                        discount: 10,
                    }
                ],
                imageData: [
                    {
                        photo: LadakhImg6
                    },
                    {
                        photo: LadakhImg7
                    },
                    {
                        photo: LadakhImg8
                    },
                    {
                        photo: LadakhImg9
                    },
                    {
                        photo: LadakhImg10
                    },
                ]
            },
            {
                name: "Ladakh Premium Tour - Srinagar to Delhi ",
                photo: LadakhImg2,
                mainImg: LadakhBanner2,
                links: "/ladakh-premium-tour-srinagar-to-delhi",
                days: "9 Days 8 Nights",
                totalReview: 5,
                price: 56999,
                discount: 10,
                date: "12/02/2024",
                ages: "Min Age: 12+",
                pickup: "Pickup: Airpot",
                content1: `Get Ready for an unforgettable adventure to Ladakh, the "Land of High Passes," in the Himalayas. Leh Ladakh is a paradise for nature lovers and adventure seekers alike.`,

                services: [
                    {
                        serviceName: "Departure & Return Location - ",
                        serviceText: "Delhi"
                    },

                ],
                inclusions: [
                    {
                        inclusionsText: "Team Captain                        "
                    },
                    {
                        inclusionsText: "Royal Enfield Himalayan motorcycle with fuel  "
                    },
                    {
                        inclusionsText: "Comfy Hotel Stay"
                    },
                    {
                        inclusionsText: "Delicious Food "
                    },
                    {
                        inclusionsText: "1-Hour Shikara Ride on Dal Lake, Srinagar"
                    },
                    {
                        inclusionsText: "Complimentary Houseboat Stay.",
                    },
                    {
                        inclusionsText: "All transportation",
                    },
                    {
                        inclusionsText: "Portable oxygen cylinders & First Aid Kit",
                    },
                    {
                        inclusionsText: "Helmets for Rider and Pillion, Knee Guard & Riding Jacket for Both Rider & Pillion",
                    },
                    {
                        inclusionsText: "Mechanic ",
                    },
                    {
                        inclusionsText: "Inner Line Permit ",
                    },
                    {
                        inclusionsText: "A backup vehicle for Luggage ",
                    },
                    {
                        inclusionsText: "Certificate of Riding on completion",
                    },

                ],
                exclusions: [
                    {
                        exclusionsText: "Air or rail fares "
                    },
                    {
                        exclusionsText: "Anything not explicitly listed in the inclusions is not covered."
                    },
                    {
                        exclusionsText: "Lunch"
                    },
                    {
                        exclusionsText: "Any personal expenses"
                    },
                    {
                        exclusionsText: "Parking tickets"
                    },
                    {
                        exclusionsText: "GST @ 5% is Excluded Inner line permits and Restricted area permits for Ladakh region "
                    },


                ],
                complementaries: [
                    {
                        complementariesName: "Complementaries",
                        complementariesText: ""
                    }
                ],
                expect: [
                    {
                        expectHeading: "What to Expect?",
                        expectText: `Ladakh   is a hill station nestled in the Beas River Valley of Himachal Pradesh, India. It is known for its natural beauty, adventure activities, and pleasant climate. Here's a glimpse of what you can expect on a trip to Ladakh
                        :`,
                        expectSubData: [
                            {
                                expectSubName: "Challenging terrain",
                                expectSubText: "The roads in Ladakh can be rough and challenging, with steep climbs, loose gravel, and water crossings.  "
                            },
                            {
                                expectSubName: "High altitude",
                                expectSubText: " Ladakh is located at a high altitude, so be sure to acclimatize properly before you start riding.                                 "
                            },
                            {
                                expectSubName: "Unique culture",
                                expectSubText: "Ladakh is a Buddhist region with a rich culture and history. You'll have the opportunity to visit monasteries, gompas, and meet the friendly Ladakhi people.                                "
                            },
                            {
                                expectSubName: "Unforgettable experience",
                                expectSubText: "A Ladakh bike tour is a challenging but rewarding experience that will stay with you for a lifetime.                                "
                            },
                            {
                                expectSubName: "Breathtaking landscapes",
                                expectSubText: "Ladakh is home to some of the highest mountain passes in the world, including the Khardung La and Chang La.  "
                            },


                        ]
                    }
                ],
                itineraryName: "Itinerary",
                itinerarySubData: [
                    {
                        itineraryHeading: "Day 1:  Journey Begins From Srinagar       ",
                        itineraryText1: "Touch down in Srinagar.                     ",
                        itineraryText2: "Airport pickup will be arranged.                         ",
                        itineraryText3: "Unpack at your hotel.           ",
                        itineraryText4: "Explore the paradise [1 Hour Shikara Ride] after taking some rest. "
                    },
                    {
                        itineraryHeading: "Day 2: Srinagar to Kargil                 ",
                        itineraryText1: "This journey is about 200 kilometers and can take anywhere between 7 to 10 hours depending on your travel mode and sightseeing stops.            ",
                        itineraryText2: "On your way to kargil, you can make a detour to Sonamarg, a hill station known for its meadows and glaciers.",
                        itineraryText3: "You can also visit the Drass War Memorial, another war memorial dedicated to the Indian soldiers who fought in the Kargil War.     "

                    },
                    {
                        itineraryHeading: "Day 3: Kargil to Leh  ",
                        itineraryText1: `After breakfast, embark on a scenic drive from Kargil to Leh, a distance of about 220 kilometers.
                        `,
                        itineraryText2: "Along the way, stop at some of the following places:",
                        itineraryText3: "Magnetic Hill: An optical illusion that makes it appear vehicles roll uphill on their own.",
                        itineraryText4: "Hall of Fame: A war memorial dedicated to the Indian soldiers who died in the Kargil War.",
                        itineraryText5: "Indus-Zanskar confluence: The meeting point of the Indus and Zanskar rivers."

                    },
                    {
                        itineraryHeading: "Day 4: Leh to Nubra valley          ",
                        itineraryText1: "Get an early start as you embark on a thrilling journey from Leh to Nubra Valley via the Khardung La Pass",
                        itineraryText2: `Upon reaching Nubra Valley, check into your hotel in Hunder or Diskit and spend the rest of the day exploring the surrounding area. 
                        `,
                        itineraryText3: `You can visit the Diskit Monastery, the largest monastery in Nubra Valley, or take a camel ride on the sand dunes of Hunder, also known as the "cold desert."
                        `
                    },
                    {
                        itineraryHeading: "Day 5:   Turtuk Village                 ",
                        itineraryText1: "Known as a village divided by a border, Turtuk has many stories to tell, Turtuk opened its doors to inquisitive souls in 2010.  ",
                        itineraryText2: "Turtuk was part of Pakistan–occupied Kashmir up until 1971 when Major Chewang Rinchen got the village under India’s command.  ",
                        itineraryText3: `Many born before 1947, went from being Indians to Pakistanis to Indians again. `,
                        itineraryText4: "Only 300 families call Turtuk home, many have relatives on the other side. of the border."
                    },
                    {
                        itineraryHeading: "Day 6: Nubra valley to Pangong Lake ",
                        itineraryText1: "Get an early start today as it's a long drive to Pangong Lake.      ",
                        itineraryText2: "Take the Shyok Road, a more scenic route than going back to Leh and then to Pangong Lake.                      ",
                        itineraryText3: `Pangong Tso, also known as Pangong Lake, is a high-altitude lake famous for its stunning beauty and changing colors.`,

                    },
                    {
                        itineraryHeading: "Day 7: Pangong Lake to hanle     ",
                        itineraryText1: "Obtain necessary permits to enter the restricted area of Hanle.",
                        itineraryText2: "Take a challenging drive through the Changthang plateau to reach Handle, one of the world's highest villages with an astronomical observatory.                        ",
                        itineraryText3: `Spend the night in a homestay or guesthouse in Hanle.`,

                    },
                    {
                        itineraryHeading: "Day 8: HANLE - Umlingla-la - Hanle                        ",
                        itineraryText1: "Take a day trip to Umling La, the highest motorable road in the world, offering breathtaking views of the Himalayas.",
                        itineraryText2: "Be prepared for the high altitude and harsh weather conditions.  ",
                    },
                    {
                        itineraryHeading: "Day 9:  LEH          ",
                        itineraryText1: "Spend the day exploring Leh at your own pace.",
                        itineraryText2: "Visit the Leh Palace, the former royal palace of the Kings of Ladakh. ",
                        itineraryText3: `Explore the bustling markets of Leh and do some souvenir shopping.`,
                        itineraryText4: "Visit the Hall of Fame, a war memorial dedicated to the Indian soldiers who died in the Indo-Pakistani wars."
                    },
                    {
                        itineraryHeading: "Day 10: Leh to sarchu         ",
                        itineraryText1: "Today's journey is from Leh to Jispa/Sarchu, which is about 250 km and takes around 8 hours.",
                        itineraryText2: "The route takes you through some of the most spectacular scenery in Ladakh, including the high mountain passes of Baralacha La (4,890 m), Lachung La (5,050 m), and Tanglang La (5,350 m), which is the second highest motorable road in the world after Khardung La.                        ",
                        itineraryText3: "You will also come across beautiful lakes like Suraj Tal and Pangong Tso.",

                    },
                    {
                        itineraryHeading: "Day 11:  SARCHU to Manali                 ",
                        itineraryText1: "You'll cross the Baralacha La Pass (16,040 ft), the second-highest motorable pass in the region. ",
                        itineraryText2: "Relax and enjoy the serene beauty of the surrounding mountains.                        ",
                    },
                    {
                        itineraryHeading: "Day 12:  Head out from Manali     ",
                        itineraryText1: "Easy check-out from the hotel! Explore local sights hassle-free. Visit the local market if time allows. Drop at the bus stand Enjoy a comfortable Volvo bus journey to Delhi.",
                        itineraryText2: `(Note:- Scooty without Fuel will be provided for sightseeing)
                        `
                    },
                    {
                        itineraryHeading: "Day 13: Delhi Drop  ",
                        itineraryText1: "Wrap up your trip in Delhi, in the morning, ensuring a smooth exit.                        ",

                    }
                ],
                relatedData: [
                    {
                        name: "Ladakh Deluxe Tour - Delhi to Srinagar - 11D/10N ",
                        photo: LadakhImg4,
                        links: "/ladakh-deluxe-tour-delhi-to-strinagar-11D/10N",
                        days: "11 Days 10 Nights",
                        totalReview: 5,
                        price: 29999,
                        discount: 10,
                    }
                    , {
                        name: "Ladakh Premium Tour - Delhi to Srinagar - 11D/10N                ",
                        photo: LadakhImg9,
                        links: "/ladakh-premium-tour-delhi-to-strinagar-11D/10N",
                        days: "11 Days 10 Nights",
                        totalReview: 5,
                        price: 56999,
                        discount: 10,
                    }
                ],
                imageData: [
                    {
                        photo: LadakhImg6
                    },
                    {
                        photo: LadakhImg7
                    },
                    {
                        photo: LadakhImg8
                    },
                    {
                        photo: LadakhImg9
                    },
                    {
                        photo: LadakhImg10
                    },
                ]
            },
            {
                name: "Ladakh Deluxe Tour - Srinagar to Delhi ",
                photo: LadakhImg5,
                mainImg: LadakhBanner2,
                links: "/ladakh-deluxe-tour-srinagar-to-delhi",
                days: "9 Days 8 Nights",
                totalReview: 5,
                price: 56999,
                discount: 10,
                date: "12/02/2024",
                ages: "Min Age: 12+",
                pickup: "Pickup: Airpot",
                content1: `Get Ready for an unforgettable adventure to Ladakh, the "Land of High Passes," in the Himalayas. Leh Ladakh is a paradise for nature lovers and adventure seekers alike.
                `,

                services: [
                    {
                        serviceName: "Departure & Return Location - ",
                        serviceText: "Delhi"
                    },

                ],
                inclusions: [
                    {
                        inclusionsText: "Team Captain                        "
                    },
                    {
                        inclusionsText: "Royal Enfield Himalayan motorcycle with fuel  "
                    },
                    {
                        inclusionsText: "Comfy Hotel Stay"
                    },
                    {
                        inclusionsText: "Delicious Food "
                    },
                    {
                        inclusionsText: "1-Hour Shikara Ride on Dal Lake, Srinagar"
                    },
                    {
                        inclusionsText: "Complimentary Houseboat Stay.",
                    },
                    {
                        inclusionsText: "All transportation",
                    },
                    {
                        inclusionsText: "Portable oxygen cylinders & First Aid Kit",
                    },
                    {
                        inclusionsText: "Helmets for Rider and Pillion, Knee Guard & Riding Jacket for Both Rider & Pillion",
                    },
                    {
                        inclusionsText: "Mechanic ",
                    },
                    {
                        inclusionsText: "Inner Line Permit ",
                    },
                    {
                        inclusionsText: "A backup vehicle for Luggage ",
                    },
                    {
                        inclusionsText: "Certificate of Riding on completion",
                    },

                ],
                exclusions: [
                    {
                        exclusionsText: "Air or rail fares "
                    },
                    {
                        exclusionsText: "Anything not explicitly listed in the inclusions is not covered."
                    },
                    {
                        exclusionsText: "Lunch"
                    },
                    {
                        exclusionsText: "Any personal expenses"
                    },
                    {
                        exclusionsText: "Parking tickets"
                    },
                    {
                        exclusionsText: "GST @ 5% is Excluded Inner line permits and Restricted area permits for Ladakh region "
                    },


                ],
                complementaries: [
                    {
                        complementariesName: "Complementaries",
                        complementariesText: ""
                    }
                ],
                expect: [
                    {
                        expectHeading: "What to Expect?",
                        expectText: `Ladakh   is a hill station nestled in the Beas River Valley of Himachal Pradesh, India. It is known for its natural beauty, adventure activities, and pleasant climate. Here's a glimpse of what you can expect on a trip to Ladakh
                        :`,
                        expectSubData: [
                            {
                                expectSubName: "Challenging terrain",
                                expectSubText: "The roads in Ladakh can be rough and challenging, with steep climbs, loose gravel, and water crossings.  "
                            },
                            {
                                expectSubName: "High altitude",
                                expectSubText: " Ladakh is located at a high altitude, so be sure to acclimatize properly before you start riding.                                 "
                            },
                            {
                                expectSubName: "Unique culture",
                                expectSubText: "Ladakh is a Buddhist region with a rich culture and history. You'll have the opportunity to visit monasteries, gompas, and meet the friendly Ladakhi people.                                "
                            },
                            {
                                expectSubName: "Unforgettable experience",
                                expectSubText: "A Ladakh bike tour is a challenging but rewarding experience that will stay with you for a lifetime.                                "
                            },
                            {
                                expectSubName: "Breathtaking landscapes",
                                expectSubText: "Ladakh is home to some of the highest mountain passes in the world, including the Khardung La and Chang La.  "
                            },


                        ]
                    }
                ],
                itineraryName: "Itinerary",
                itinerarySubData: [
                    {
                        itineraryHeading: "Day 1:  Journey Begins From Srinagar       ",
                        itineraryText1: "Touch down in Srinagar.                     ",
                        itineraryText2: "Airport pickup will be arranged.                         ",
                        itineraryText3: "Unpack at your hotel.           ",
                        itineraryText4: "Explore the paradise [1 Hour Shikara Ride] after taking some rest. "
                    },
                    {
                        itineraryHeading: "Day 2: Srinagar to Kargil                 ",
                        itineraryText1: "This journey is about 200 kilometers and can take anywhere between 7 to 10 hours depending on your travel mode and sightseeing stops.            ",
                        itineraryText2: "On your way to kargil, you can make a detour to Sonamarg, a hill station known for its meadows and glaciers.",
                        itineraryText3: "You can also visit the Drass War Memorial, another war memorial dedicated to the Indian soldiers who fought in the Kargil War.     "

                    },
                    {
                        itineraryHeading: "Day 3: Kargil to Leh  ",
                        itineraryText1: `After breakfast, embark on a scenic drive from Kargil to Leh, a distance of about 220 kilometers.
                        `,
                        itineraryText2: "Along the way, stop at some of the following places:",
                        itineraryText3: "Magnetic Hill: An optical illusion that makes it appear vehicles roll uphill on their own.",
                        itineraryText4: "Hall of Fame: A war memorial dedicated to the Indian soldiers who died in the Kargil War.",
                        itineraryText5: "Indus-Zanskar confluence: The meeting point of the Indus and Zanskar rivers."

                    },
                    {
                        itineraryHeading: "Day 4: Leh to Nubra valley          ",
                        itineraryText1: "Get an early start as you embark on a thrilling journey from Leh to Nubra Valley via the Khardung La Pass",
                        itineraryText2: `Upon reaching Nubra Valley, check into your hotel in Hunder or Diskit and spend the rest of the day exploring the surrounding area. 
                        `,
                        itineraryText3: `You can visit the Diskit Monastery, the largest monastery in Nubra Valley, or take a camel ride on the sand dunes of Hunder, also known as the "cold desert."
                        `
                    },
                    {
                        itineraryHeading: "Day 5:   Turtuk Village                 ",
                        itineraryText1: "Known as a village divided by a border, Turtuk has many stories to tell, Turtuk opened its doors to inquisitive souls in 2010.  ",
                        itineraryText2: "Turtuk was part of Pakistan–occupied Kashmir up until 1971 when Major Chewang Rinchen got the village under India’s command.  ",
                        itineraryText3: `Many born before 1947, went from being Indians to Pakistanis to Indians again. `,
                        itineraryText4: "Only 300 families call Turtuk home, many have relatives on the other side. of the border."
                    },
                    {
                        itineraryHeading: "Day 6: Nubra valley to Pangong Lake ",
                        itineraryText1: "Get an early start today as it's a long drive to Pangong Lake.      ",
                        itineraryText2: "Take the Shyok Road, a more scenic route than going back to Leh and then to Pangong Lake.                      ",
                        itineraryText3: `Pangong Tso, also known as Pangong Lake, is a high-altitude lake famous for its stunning beauty and changing colors.`,

                    },
                    {
                        itineraryHeading: "Day 7: Pangong Lake to Leh  ",
                        itineraryText1: "Chang-La Pass: One of the highest motorable passes in the world. ",
                        itineraryText2: "Tangste Village: Immerse yourself in the local culture, interact with residents, and capture the beauty.   ",
                        itineraryText3: `Durbok Village: A hidden gem surrounded by rugged mountains  `,
                        itineraryText4: "Thikse Monastery. ",
                        itineraryText5: "Shey Palace: Conclude the day with a visit to the historic Shey Palace, a former royal residence."

                    },

                    {
                        itineraryHeading: "Day 8: Leh to sarchu         ",
                        itineraryText1: "Today's journey is from Leh to Jispa/Sarchu, which is about 250 km and takes around 8 hours.",
                        itineraryText2: "The route takes you through some of the most spectacular scenery in Ladakh, including the high mountain passes of Baralacha La (4,890 m), Lachung La (5,050 m), and Tanglang La (5,350 m), which is the second highest motorable road in the world after Khardung La.                        ",
                        itineraryText3: "You will also come across beautiful lakes like Suraj Tal and Pangong Tso.",

                    },
                    {
                        itineraryHeading: "Day 9:  SARCHU to Manali                 ",
                        itineraryText1: "You'll cross the Baralacha La Pass (16,040 ft), the second-highest motorable pass in the region. ",
                        itineraryText2: "Relax and enjoy the serene beauty of the surrounding mountains.                        ",
                    },
                    {
                        itineraryHeading: "Day 10:  Head out from Manali     ",
                        itineraryText1: "Easy check-out from the hotel! Explore local sights hassle-free. Visit the local market if time allows. Drop at the bus stand Enjoy a comfortable Volvo bus journey to Delhi.",
                        itineraryText2: `(Note:- Scooty without Fuel will be provided for sightseeing)
                        `
                    },
                    {
                        itineraryHeading: "Day 11: Delhi Drop  ",
                        itineraryText1: "Wrap up your trip in Delhi, in the morning, ensuring a smooth exit.                        ",

                    }
                ],
                relatedData: [
                    {
                        name: "Ladakh Deluxe Tour - Delhi to Srinagar - 11D/10N ",
                        photo: LadakhImg4,
                        links: "/ladakh-deluxe-tour-delhi-to-strinagar-11D/10N",
                        days: "11 Days 10 Nights",
                        totalReview: 5,
                        price: 29999,
                        discount: 10,
                    }
                    , {
                        name: "Ladakh Premium Tour - Delhi to Srinagar - 11D/10N                ",
                        photo: LadakhImg9,
                        links: "/ladakh-premium-tour-delhi-to-strinagar-11D/10N",
                        days: "11 Days 10 Nights",
                        totalReview: 5,
                        price: 56999,
                        discount: 10,
                    }
                ],
                imageData: [
                    {
                        photo: LadakhImg6
                    },
                    {
                        photo: LadakhImg7
                    },
                    {
                        photo: LadakhImg8
                    },
                    {
                        photo: LadakhImg9
                    },
                    {
                        photo: LadakhImg10
                    },
                ]
            },
            {
                name: "Ladakh Premium - Leh to Leh - 8N & 9D                ",
                photo: LadakhImg6,
                mainImg: LadakhBanner2,
                links: "/ladakh-premium-leh-to-leh-8N&9D",
                days: "9 Days 8 Nights",
                totalReview: 5,
                price: 56999,
                discount: 10,
                date: "12/02/2024",
                ages: "Min Age: 12+",
                pickup: "Pickup: Airpot",
                content1: `Get Ready for an unforgettable adventure to Ladakh, the "Land of High Passes," in the Himalayas. Leh Ladakh is a paradise for nature lovers and adventure seekers alike.
                `,

                services: [
                    {
                        serviceName: "Departure & Return Location - ",
                        serviceText: "Delhi"
                    },

                ],
                inclusions: [
                    {
                        inclusionsText: "Team Captain                        "
                    },
                    {
                        inclusionsText: "Royal Enfield Himalayan motorcycle with fuel  "
                    },
                    {
                        inclusionsText: "Comfy Hotel Stay"
                    },
                    {
                        inclusionsText: "Delicious Food "
                    },
                    {
                        inclusionsText: "1-Hour Shikara Ride on Dal Lake, Srinagar"
                    },
                    {
                        inclusionsText: "Complimentary Houseboat Stay.",
                    },
                    {
                        inclusionsText: "All transportation",
                    },
                    {
                        inclusionsText: "Portable oxygen cylinders & First Aid Kit",
                    },
                    {
                        inclusionsText: "Helmets for Rider and Pillion, Knee Guard & Riding Jacket for Both Rider & Pillion",
                    },
                    {
                        inclusionsText: "Mechanic ",
                    },
                    {
                        inclusionsText: "Inner Line Permit ",
                    },
                    {
                        inclusionsText: "A backup vehicle for Luggage ",
                    },
                    {
                        inclusionsText: "Certificate of Riding on completion",
                    },

                ],
                exclusions: [
                    {
                        exclusionsText: "Air or rail fares "
                    },
                    {
                        exclusionsText: "Anything not explicitly listed in the inclusions is not covered."
                    },
                    {
                        exclusionsText: "Lunch"
                    },
                    {
                        exclusionsText: "Any personal expenses"
                    },
                    {
                        exclusionsText: "Parking tickets"
                    },
                    {
                        exclusionsText: "GST @ 5% is Excluded Inner line permits and Restricted area permits for Ladakh region "
                    },


                ],
                complementaries: [
                    {
                        complementariesName: "Complementaries",
                        complementariesText: ""
                    }
                ],
                expect: [
                    {
                        expectHeading: "What to Expect?",
                        expectText: `Ladakh   is a hill station nestled in the Beas River Valley of Himachal Pradesh, India. It is known for its natural beauty, adventure activities, and pleasant climate. Here's a glimpse of what you can expect on a trip to Ladakh
                        :`,
                        expectSubData: [
                            {
                                expectSubName: "Challenging terrain",
                                expectSubText: "The roads in Ladakh can be rough and challenging, with steep climbs, loose gravel, and water crossings.  "
                            },
                            {
                                expectSubName: "High altitude",
                                expectSubText: " Ladakh is located at a high altitude, so be sure to acclimatize properly before you start riding.                                 "
                            },
                            {
                                expectSubName: "Unique culture",
                                expectSubText: "Ladakh is a Buddhist region with a rich culture and history. You'll have the opportunity to visit monasteries, gompas, and meet the friendly Ladakhi people.                                "
                            },
                            {
                                expectSubName: "Unforgettable experience",
                                expectSubText: "A Ladakh bike tour is a challenging but rewarding experience that will stay with you for a lifetime.                                "
                            },
                            {
                                expectSubName: "Breathtaking landscapes",
                                expectSubText: "Ladakh is home to some of the highest mountain passes in the world, including the Khardung La and Chang La.  "
                            },


                        ]
                    }
                ],
                itineraryName: "Itinerary",
                itinerarySubData: [
                    {
                        itineraryHeading: "Day 1:   Arrival at Leh ",
                        itineraryText1: "Arrive at leh airport, greeted by our representative and transfer to hotel           ",
                        itineraryText2: "Take it easy today to acclimate to the altitude          ",
                        itineraryText3: "Explore Leh’s Local Market, Shanti Stupa & Leh Palace                         ",
                        itineraryText4: "Overnight Stay at leh "
                    },
                    {
                        itineraryHeading: "Day 2: Leh Local sightseeing              ",
                        itineraryText1: "Explore Leh city, visit the Hall of Fame, Sangam Point      ",
                        itineraryText2: "Lunch at Nemo Market, back to Leh, and explore Leh Bazaar ",
                        itineraryText3: "Leisure time in the evening.  ",
                        itineraryText4: "Overnight Stay at Leh."

                    },
                    {
                        itineraryHeading: "Day 3:  Leh to Nubra valley ",
                        itineraryText1: `Get an early start as you embark on a thrilling journey from Leh to Nubra Valley via the Khardung La Pass
                        `,
                        itineraryText2: "Upon reaching Nubra Valley, check into your hotel in Hunder or Diskit and spend the rest of the day exploring the surrounding area. ",
                        itineraryText3: `You can visit the Diskit Monastery, the largest monastery in Nubra Valley, or take a camel ride on the sand dunes of Hunder, also known as the "cold desert."`

                    },
                    {
                        itineraryHeading: "Day 4: Nubra valley to Turtuk Village     ",
                        itineraryText1: "Turtuk is a charming village in the Balti tribe region under Indian administration ",
                        itineraryText2: `Spend time exploring the village and have lunch. Return to your camp in Nubra. Overnight stay at the camp
                        `,
                    },
                    {
                        itineraryHeading: "Day 5:   NUBRA VALLEY to Pangong Lake      ",
                        itineraryText1: "Witness the Sunrise at Pangong Lake ",
                        itineraryText2: "Ride through Shayok Valley to Nubra, indulging in Zipline, Camel Riding & Cultural Shows.  ",
                        itineraryText3: `Overnight Stay at Nubra `,
                    },
                    {
                        itineraryHeading: "Day 6: Pangong to Hanle ",
                        itineraryText1: "Man-Merak: Start your journey from Leh to Man-Merak                         ",
                        itineraryText2: "Chushul: Explore the scenic beauty of Chushul ",
                        itineraryText3: `Tsaga La Pass: Journey through Tsaga La Pass, enjoy breathtaking views Rhongo: Visit the charming village of Rhongo `,
                        itineraryText4: "Rezang La war memorial: Pay respects at the historic Rezang La War Memorial Loma Band"

                    },
                    {
                        itineraryHeading: "Day 7: Hanle - Umlinga - HANLE               ",
                        itineraryText1: "Start your journey to Umlinga Pass, the world's highest motorable pass. ",
                        itineraryText2: "Head towards Hanle Monastery, explore its serene surroundings.  ",
                        itineraryText3: `Visit Hanle Observatory, India's only Dark Sky Reserve. Experience the unique celestial views and immerse yourself in the beauty of the night sky. `,
                        itineraryText4: "Start your journey back to Leh, enjoying the scenic landscapes.",

                    },

                    {
                        itineraryHeading: "Day 8:  Hanle to Leh     ",
                        itineraryText1: "Explore Leh city, visit the Hall of Fame, Sangam Point ",
                        itineraryText2: "Lunch at Nemo Market, back to Leh, and explore Leh Bazaar.                        ",

                    },
                    {
                        itineraryHeading: "Day 9: Departure          ",
                        itineraryText1: "Congratulations! You’ve made it! ",
                        itineraryText2: "Departure with loads of memories & new friends                    ",
                    }
                ],
                relatedData: [
                    {
                        name: "Ladakh Deluxe Tour - Delhi to Srinagar - 11D/10N ",
                        photo: LadakhImg4,
                        links: "/ladakh-deluxe-tour-delhi-to-strinagar-11D/10N",
                        days: "11 Days 10 Nights",
                        totalReview: 5,
                        price: 29999,
                        discount: 10,
                    }
                    , {
                        name: "Ladakh Premium Tour - Delhi to Srinagar - 11D/10N                ",
                        photo: LadakhImg9,
                        links: "/ladakh-premium-tour-delhi-to-strinagar-11D/10N",
                        days: "11 Days 10 Nights",
                        totalReview: 5,
                        price: 56999,
                        discount: 10,
                    }
                ],
                imageData: [
                    {
                        photo: LadakhImg6
                    },
                    {
                        photo: LadakhImg7
                    },
                    {
                        photo: LadakhImg8
                    },
                    {
                        photo: LadakhImg9
                    },
                    {
                        photo: LadakhImg10
                    },
                ]
            },
            {
                name: "Ladakh Leh to Leh - 5N6D - Umling-La       ",
                photo: LadakhImg10,
                mainImg: LadakhBanner2,
                links: "/ladakh-leh-to-leh-5N&6D-umling-la",
                days: "6 Days 5 Nights",
                totalReview: 5,
                price: 56999,
                discount: 10,
                date: "12/02/2024",
                ages: "Min Age: 12+",
                pickup: "Pickup: Airpot",
                content1: `Get Ready for an unforgettable adventure to Ladakh, the "Land of High Passes," in the Himalayas. Leh Ladakh is a paradise for nature lovers and adventure seekers alike.
                `,

                services: [
                    {
                        serviceName: "Departure & Return Location - ",
                        serviceText: "Delhi"
                    },

                ],
                inclusions: [
                    {
                        inclusionsText: "Team Captain                        "
                    },
                    {
                        inclusionsText: "Royal Enfield Himalayan motorcycle with fuel  "
                    },
                    {
                        inclusionsText: "Comfy Hotel Stay"
                    },
                    {
                        inclusionsText: "Delicious Food "
                    },
                    {
                        inclusionsText: "1-Hour Shikara Ride on Dal Lake, Srinagar"
                    },
                    {
                        inclusionsText: "Complimentary Houseboat Stay.",
                    },
                    {
                        inclusionsText: "All transportation",
                    },
                    {
                        inclusionsText: "Portable oxygen cylinders & First Aid Kit",
                    },
                    {
                        inclusionsText: "Helmets for Rider and Pillion, Knee Guard & Riding Jacket for Both Rider & Pillion",
                    },
                    {
                        inclusionsText: "Mechanic ",
                    },
                    {
                        inclusionsText: "Inner Line Permit ",
                    },
                    {
                        inclusionsText: "A backup vehicle for Luggage ",
                    },
                    {
                        inclusionsText: "Certificate of Riding on completion",
                    },

                ],
                exclusions: [
                    {
                        exclusionsText: "Air or rail fares "
                    },
                    {
                        exclusionsText: "Anything not explicitly listed in the inclusions is not covered."
                    },
                    {
                        exclusionsText: "Lunch"
                    },
                    {
                        exclusionsText: "Any personal expenses"
                    },
                    {
                        exclusionsText: "Parking tickets"
                    },
                    {
                        exclusionsText: "GST @ 5% is Excluded Inner line permits and Restricted area permits for Ladakh region "
                    },


                ],
                complementaries: [
                    {
                        complementariesName: "Complementaries",
                        complementariesText: ""
                    }
                ],
                expect: [
                    {
                        expectHeading: "What to Expect?",
                        expectText: `Ladakh   is a hill station nestled in the Beas River Valley of Himachal Pradesh, India. It is known for its natural beauty, adventure activities, and pleasant climate. Here's a glimpse of what you can expect on a trip to Ladakh
                        :`,
                        expectSubData: [
                            {
                                expectSubName: "Challenging terrain",
                                expectSubText: "The roads in Ladakh can be rough and challenging, with steep climbs, loose gravel, and water crossings.  "
                            },
                            {
                                expectSubName: "High altitude",
                                expectSubText: " Ladakh is located at a high altitude, so be sure to acclimatize properly before you start riding.                                 "
                            },
                            {
                                expectSubName: "Unique culture",
                                expectSubText: "Ladakh is a Buddhist region with a rich culture and history. You'll have the opportunity to visit monasteries, gompas, and meet the friendly Ladakhi people.                                "
                            },
                            {
                                expectSubName: "Unforgettable experience",
                                expectSubText: "A Ladakh bike tour is a challenging but rewarding experience that will stay with you for a lifetime.                                "
                            },
                            {
                                expectSubName: "Breathtaking landscapes",
                                expectSubText: "Ladakh is home to some of the highest mountain passes in the world, including the Khardung La and Chang La.  "
                            },


                        ]
                    }
                ],
                itineraryName: "Itinerary",
                itinerarySubData: [
                    {
                        itineraryHeading: "Day 1:  Arrival at Leh ",
                        itineraryText1: "Arrive at leh airport, greeted by our representative and transfer to hotel           ",
                        itineraryText2: "Take it easy today to acclimate to the altitude          ",
                        itineraryText3: "Explore Leh’s Local Market, Shanti Stupa & Leh Palace                         ",
                        itineraryText4: "Overnight Stay at leh "
                    },
                    {
                        itineraryHeading: "Day 2: Leh Local sightseeing              ",
                        itineraryText1: "Explore Leh city, visit the Hall of Fame, Sangam Point      ",
                        itineraryText2: "Lunch at Nemo Market, back to Leh, and explore Leh Bazaar ",
                        itineraryText3: "Leisure time in the evening.  ",
                        itineraryText4: "Overnight Stay at Leh."

                    },
                    {
                        itineraryHeading: "Day 3:  Leh to Nubra valley ",
                        itineraryText1: `Get an early start as you embark on a thrilling journey from Leh to Nubra Valley via the Khardung La Pass
                        `,
                        itineraryText2: "Upon reaching Nubra Valley, check into your hotel in Hunder or Diskit and spend the rest of the day exploring the surrounding area. ",
                        itineraryText3: `You can visit the Diskit Monastery, the largest monastery in Nubra Valley, or take a camel ride on the sand dunes of Hunder, also known as the "cold desert."`

                    },
                    {
                        itineraryHeading: "Day 4: NUBRA VALLEY to Pangong Lake                ",
                        itineraryText1: "Witness the Sunrise at Pangong Lake  ",
                        itineraryText2: `Ride through Shayok Valley to Nubra, indulging in Zipline, Camel Riding & Cultural Shows. 
                        `,
                        itineraryText3: "Overnight Stay at Nubra"
                    },
                    {
                        itineraryHeading: "Day 5:   Pangong to Leh   ",
                        itineraryText1: "Chang-La Pass: One of the highest motorable passes in the world ",
                        itineraryText2: "Tangste Village: Immerse yourself in the local culture, interact with residents, and capture the beauty  ",
                        itineraryText3: `Durbok Village: A hidden gem surrounded by rugged mountains   `,
                        itineraryText4: "Thikse Monastery ",
                        itineraryText5: "Shey Palace: Conclude the day with a visit to the historic Shey Palace, a former royal residence."
                    },

                    {
                        itineraryHeading: "Day 6: Departure          ",
                        itineraryText1: "Congratulations! You’ve made it! ",
                        itineraryText2: "Departure with loads of memories & new friends                    ",
                    }
                ],
                relatedData: [
                    {
                        name: "Ladakh Deluxe Tour - Delhi to Srinagar - 11D/10N ",
                        photo: LadakhImg4,
                        links: "/ladakh-deluxe-tour-delhi-to-strinagar-11D/10N",
                        days: "11 Days 10 Nights",
                        totalReview: 5,
                        price: 29999,
                        discount: 10,
                    }
                    , {
                        name: "Ladakh Premium Tour - Delhi to Srinagar - 11D/10N                ",
                        photo: LadakhImg9,
                        links: "/ladakh-premium-tour-delhi-to-strinagar-11D/10N",
                        days: "11 Days 10 Nights",
                        totalReview: 5,
                        price: 56999,
                        discount: 10,
                    }
                ],
                imageData: [
                    {
                        photo: LadakhImg6
                    },
                    {
                        photo: LadakhImg7
                    },
                    {
                        photo: LadakhImg8
                    },
                    {
                        photo: LadakhImg9
                    },
                    {
                        photo: LadakhImg10
                    },
                ]
            },
            {
                name: "Ladakh Leh to Leh - 6N & 7D    ",
                photo: LadakhImg3,
                mainImg: LadakhBanner2,
                links: "/ladakh-leh-to-leh-6N&7D",
                days: "7 Days 6 Nights",
                totalReview: 5,
                price: 56999,
                discount: 10,
                date: "12/02/2024",
                ages: "Min Age: 12+",
                pickup: "Pickup: Airpot",
                content1: `Get Ready for an unforgettable adventure to Ladakh, the "Land of High Passes," in the Himalayas. Leh Ladakh is a paradise for nature lovers and adventure seekers alike.
                `,

                services: [
                    {
                        serviceName: "Departure & Return Location - ",
                        serviceText: "Delhi"
                    },

                ],
                inclusions: [
                    {
                        inclusionsText: "Team Captain                        "
                    },
                    {
                        inclusionsText: "Royal Enfield Himalayan motorcycle with fuel  "
                    },
                    {
                        inclusionsText: "Comfy Hotel Stay"
                    },
                    {
                        inclusionsText: "Delicious Food "
                    },
                    {
                        inclusionsText: "1-Hour Shikara Ride on Dal Lake, Srinagar"
                    },
                    {
                        inclusionsText: "Complimentary Houseboat Stay.",
                    },
                    {
                        inclusionsText: "All transportation",
                    },
                    {
                        inclusionsText: "Portable oxygen cylinders & First Aid Kit",
                    },
                    {
                        inclusionsText: "Helmets for Rider and Pillion, Knee Guard & Riding Jacket for Both Rider & Pillion",
                    },
                    {
                        inclusionsText: "Mechanic ",
                    },
                    {
                        inclusionsText: "Inner Line Permit ",
                    },
                    {
                        inclusionsText: "A backup vehicle for Luggage ",
                    },
                    {
                        inclusionsText: "Certificate of Riding on completion",
                    },

                ],
                exclusions: [
                    {
                        exclusionsText: "Air or rail fares "
                    },
                    {
                        exclusionsText: "Anything not explicitly listed in the inclusions is not covered."
                    },
                    {
                        exclusionsText: "Lunch"
                    },
                    {
                        exclusionsText: "Any personal expenses"
                    },
                    {
                        exclusionsText: "Parking tickets"
                    },
                    {
                        exclusionsText: "GST @ 5% is Excluded Inner line permits and Restricted area permits for Ladakh region "
                    },


                ],
                complementaries: [
                    {
                        complementariesName: "Complementaries",
                        complementariesText: ""
                    }
                ],
                expect: [
                    {
                        expectHeading: "What to Expect?",
                        expectText: `Ladakh   is a hill station nestled in the Beas River Valley of Himachal Pradesh, India. It is known for its natural beauty, adventure activities, and pleasant climate. Here's a glimpse of what you can expect on a trip to Ladakh
                        :`,
                        expectSubData: [
                            {
                                expectSubName: "Challenging terrain",
                                expectSubText: "The roads in Ladakh can be rough and challenging, with steep climbs, loose gravel, and water crossings.  "
                            },
                            {
                                expectSubName: "High altitude",
                                expectSubText: " Ladakh is located at a high altitude, so be sure to acclimatize properly before you start riding.                                 "
                            },
                            {
                                expectSubName: "Unique culture",
                                expectSubText: "Ladakh is a Buddhist region with a rich culture and history. You'll have the opportunity to visit monasteries, gompas, and meet the friendly Ladakhi people.                                "
                            },
                            {
                                expectSubName: "Unforgettable experience",
                                expectSubText: "A Ladakh bike tour is a challenging but rewarding experience that will stay with you for a lifetime.                                "
                            },
                            {
                                expectSubName: "Breathtaking landscapes",
                                expectSubText: "Ladakh is home to some of the highest mountain passes in the world, including the Khardung La and Chang La.  "
                            },


                        ]
                    }
                ],
                itineraryName: "Itinerary",
                itinerarySubData: [
                    {
                        itineraryHeading: "Day 1:  Arrival at Leh ",
                        itineraryText1: "Arrive at leh airport, greeted by our representative and transfer to hotel           ",
                        itineraryText2: "Take it easy today to acclimate to the altitude          ",
                        itineraryText3: "Explore Leh’s Local Market, Shanti Stupa & Leh Palace                         ",
                        itineraryText4: "Overnight Stay at leh "
                    },
                    {
                        itineraryHeading: "Day 2: Leh Local sightseeing              ",
                        itineraryText1: "Explore Leh city, visit the Hall of Fame, Sangam Point      ",
                        itineraryText2: "Lunch at Nemo Market, back to Leh, and explore Leh Bazaar ",
                        itineraryText3: "Leisure time in the evening.  ",
                        itineraryText4: "Overnight Stay at Leh."

                    },
                    {
                        itineraryHeading: "Day 3:  Leh to Nubra valley ",
                        itineraryText1: `Get an early start as you embark on a thrilling journey from Leh to Nubra Valley via the Khardung La Pass
                        `,
                        itineraryText2: "Upon reaching Nubra Valley, check into your hotel in Hunder or Diskit and spend the rest of the day exploring the surrounding area. ",
                        itineraryText3: `You can visit the Diskit Monastery, the largest monastery in Nubra Valley, or take a camel ride on the sand dunes of Hunder, also known as the "cold desert."`

                    },
                    {
                        itineraryHeading: "Day 4: Nubra valley to Turtuk Village             ",
                        itineraryText1: "Turtuk is a charming village in the Balti tribe region under Indian administration                         ",
                        itineraryText2: `Spend time exploring the village and have lunch. Return to your camp in Nubra. Overnight stay at the camp`,
                    },
                    {
                        itineraryHeading: "Day 5: NUBRA VALLEY to Pangong Lake                ",
                        itineraryText1: "Witness the Sunrise at Pangong Lake  ",
                        itineraryText2: `Ride through Shayok Valley to Nubra, indulging in Zipline, Camel Riding & Cultural Shows. 
                        `,
                        itineraryText3: "Overnight Stay at Nubra"
                    },
                    {
                        itineraryHeading: "Day 6:   Pangong to Leh   ",
                        itineraryText1: "Chang-La Pass: One of the highest motorable passes in the world ",
                        itineraryText2: "Tangste Village: Immerse yourself in the local culture, interact with residents, and capture the beauty  ",
                        itineraryText3: `Durbok Village: A hidden gem surrounded by rugged mountains   `,
                        itineraryText4: "Thikse Monastery ",
                        itineraryText5: "Shey Palace: Conclude the day with a visit to the historic Shey Palace, a former royal residence."
                    },

                    {
                        itineraryHeading: "Day 7: Departure          ",
                        itineraryText1: "Congratulations! You’ve made it! ",
                        itineraryText2: "Departure with loads of memories & new friends                    ",
                    }
                ],
                relatedData: [
                    {
                        name: "Ladakh Deluxe Tour - Delhi to Srinagar - 11D/10N ",
                        photo: LadakhImg4,
                        links: "/ladakh-deluxe-tour-delhi-to-strinagar-11D/10N",
                        days: "11 Days 10 Nights",
                        totalReview: 5,
                        price: 29999,
                        discount: 10,
                    }
                    , {
                        name: "Ladakh Premium Tour - Delhi to Srinagar - 11D/10N                ",
                        photo: LadakhImg9,
                        links: "/ladakh-premium-tour-delhi-to-strinagar-11D/10N",
                        days: "11 Days 10 Nights",
                        totalReview: 5,
                        price: 56999,
                        discount: 10,
                    }
                ],
                imageData: [
                    {
                        photo: LadakhImg6
                    },
                    {
                        photo: LadakhImg7
                    },
                    {
                        photo: LadakhImg8
                    },
                    {
                        photo: LadakhImg9
                    },
                    {
                        photo: LadakhImg10
                    },
                ]
            },
            {
                name: "Ladakh Leh to Leh - 7N8D - Umling-La  ",
                photo: LadakhImg7,
                mainImg: LadakhBanner2,
                links: "/ladakh-leh-to-leh-7N&8D-umling-la",
                days: "8 Days 7 Nights",
                totalReview: 5,
                price: 56999,
                discount: 10,
                date: "12/02/2024",
                ages: "Min Age: 12+",
                pickup: "Pickup: Airpot",
                content1: `Get Ready for an unforgettable adventure to Ladakh, the "Land of High Passes," in the Himalayas. Leh Ladakh is a paradise for nature lovers and adventure seekers alike.
                `,

                services: [
                    {
                        serviceName: "Departure & Return Location - ",
                        serviceText: "Delhi"
                    },

                ],
                inclusions: [
                    {
                        inclusionsText: "Team Captain                        "
                    },
                    {
                        inclusionsText: "Royal Enfield Himalayan motorcycle with fuel  "
                    },
                    {
                        inclusionsText: "Comfy Hotel Stay"
                    },
                    {
                        inclusionsText: "Delicious Food "
                    },
                    {
                        inclusionsText: "1-Hour Shikara Ride on Dal Lake, Srinagar"
                    },
                    {
                        inclusionsText: "Complimentary Houseboat Stay.",
                    },
                    {
                        inclusionsText: "All transportation",
                    },
                    {
                        inclusionsText: "Portable oxygen cylinders & First Aid Kit",
                    },
                    {
                        inclusionsText: "Helmets for Rider and Pillion, Knee Guard & Riding Jacket for Both Rider & Pillion",
                    },
                    {
                        inclusionsText: "Mechanic ",
                    },
                    {
                        inclusionsText: "Inner Line Permit ",
                    },
                    {
                        inclusionsText: "A backup vehicle for Luggage ",
                    },
                    {
                        inclusionsText: "Certificate of Riding on completion",
                    },

                ],
                exclusions: [
                    {
                        exclusionsText: "Air or rail fares "
                    },
                    {
                        exclusionsText: "Anything not explicitly listed in the inclusions is not covered."
                    },
                    {
                        exclusionsText: "Lunch"
                    },
                    {
                        exclusionsText: "Any personal expenses"
                    },
                    {
                        exclusionsText: "Parking tickets"
                    },
                    {
                        exclusionsText: "GST @ 5% is Excluded Inner line permits and Restricted area permits for Ladakh region "
                    },


                ],
                complementaries: [
                    {
                        complementariesName: "Complementaries",
                        complementariesText: ""
                    }
                ],
                expect: [
                    {
                        expectHeading: "What to Expect?",
                        expectText: `Ladakh   is a hill station nestled in the Beas River Valley of Himachal Pradesh, India. It is known for its natural beauty, adventure activities, and pleasant climate. Here's a glimpse of what you can expect on a trip to Ladakh
                        :`,
                        expectSubData: [
                            {
                                expectSubName: "Challenging terrain",
                                expectSubText: "The roads in Ladakh can be rough and challenging, with steep climbs, loose gravel, and water crossings.  "
                            },
                            {
                                expectSubName: "High altitude",
                                expectSubText: " Ladakh is located at a high altitude, so be sure to acclimatize properly before you start riding.                                 "
                            },
                            {
                                expectSubName: "Unique culture",
                                expectSubText: "Ladakh is a Buddhist region with a rich culture and history. You'll have the opportunity to visit monasteries, gompas, and meet the friendly Ladakhi people.                                "
                            },
                            {
                                expectSubName: "Unforgettable experience",
                                expectSubText: "A Ladakh bike tour is a challenging but rewarding experience that will stay with you for a lifetime.                                "
                            },
                            {
                                expectSubName: "Breathtaking landscapes",
                                expectSubText: "Ladakh is home to some of the highest mountain passes in the world, including the Khardung La and Chang La.  "
                            },


                        ]
                    }
                ],
                itineraryName: "Itinerary",
                itinerarySubData: [
                    {
                        itineraryHeading: "Day 1:  Arrival at Leh ",
                        itineraryText1: "Arrive at leh airport, greeted by our representative and transfer to hotel           ",
                        itineraryText2: "Take it easy today to acclimate to the altitude          ",
                        itineraryText3: "Explore Leh’s Local Market, Shanti Stupa & Leh Palace                         ",
                        itineraryText4: "Overnight Stay at leh "
                    },
                    {
                        itineraryHeading: "Day 2: Leh Local sightseeing              ",
                        itineraryText1: "Explore Leh city, visit the Hall of Fame, Sangam Point      ",
                        itineraryText2: "Lunch at Nemo Market, back to Leh, and explore Leh Bazaar ",
                        itineraryText3: "Leisure time in the evening.  ",
                        itineraryText4: "Overnight Stay at Leh."

                    },
                    {
                        itineraryHeading: "Day 3:  Leh to Hanle ",
                        itineraryText1: `Start early for a 260 km ride toh Hanle via Upshi and Chumathang 
                        `,
                        itineraryText2: "Enjoy a bath at Chumathang Hot Spring   ",
                        itineraryText3: `Explore the Indian Astronomical Observatory or Hanle monastery `,
                        itineraryText4: "Overnight Stay at Hanle"

                    },
                    {
                        itineraryHeading: "Day 4: Hanle to Umling-la-pass           ",
                        itineraryText1: "Take a Breakfast and head towards Hanle Bridge         ",
                        itineraryText2: `Ride through Kalatartar Plain, an off-road terrain. `,
                        itineraryText3: "Experience the blacktop road, reaching Nerbo-La Pass & above Everest Base Camp. Overnight Stay at Hanle"
                    },
                    {
                        itineraryHeading: "Day 5: Hanle to PANGONG LAKE   ",
                        itineraryText1: "Take a early morning ride to Pangong Lake ",
                        itineraryText2: `Pass through Loma Bend, Tsaga-La pass & Chuchul War Memorial `,
                        itineraryText3: "Visit Chuchul Village, have lunch & reach the Pangong Lake ",
                        itineraryText4: "Overnight Stay at Pangong Lake"
                    },
                    {
                        itineraryHeading: "Day 6: PANGONG to NUBRA VALLEY  ",
                        itineraryText1: "Witness the Sunrise at Pangong Lake  ",
                        itineraryText2: "Ride through Shayok Valley to Nubra, indulging in Zipline, Camel Riding & Cultural Shows.  ",
                        itineraryText3: `Overnight Stay at Nubra  `,
                    },

                    {
                        itineraryHeading: "Day 7: NUBRA to Leh Khardung-la pass   ",
                        itineraryText1: "Ride back to leh from Nubra, visiting Diskit Monastry if time permits  ",
                        itineraryText2: "Enjoy the scenic valley of Nubra on your way to Leh             ",
                        itineraryText3: "Reach Khardung-La Pass & Descend to Leh ",
                        itineraryText4: "Overnight Stay at Leh      "
                    },
                    {
                        itineraryHeading: "Day 8: Departure          ",
                        itineraryText1: "Congratulations! You’ve made it! ",
                        itineraryText2: "Departure with loads of memories & new friends                    ",
                    }
                ],
                relatedData: [
                    {
                        name: "Ladakh Deluxe Tour - Delhi to Srinagar - 11D/10N ",
                        photo: LadakhImg4,
                        links: "/ladakh-deluxe-tour-delhi-to-strinagar-11D/10N",
                        days: "11 Days 10 Nights",
                        totalReview: 5,
                        price: 29999,
                        discount: 10,
                    }
                    , {
                        name: "Ladakh Premium Tour - Delhi to Srinagar - 11D/10N                ",
                        photo: LadakhImg9,
                        links: "/ladakh-premium-tour-delhi-to-strinagar-11D/10N",
                        days: "11 Days 10 Nights",
                        totalReview: 5,
                        price: 56999,
                        discount: 10,
                    }
                ],
                imageData: [
                    {
                        photo: LadakhImg6
                    },
                    {
                        photo: LadakhImg7
                    },
                    {
                        photo: LadakhImg8
                    },
                    {
                        photo: LadakhImg9
                    },
                    {
                        photo: LadakhImg10
                    },
                ]
            },
            {
                name: "Ladakh Srinagar to Leh - 7N/8D   ",
                photo: LadakhImg8,
                mainImg: LadakhBanner2,
                links: "/ladakh-srinagar-to-leh-7N&8D",
                days: "8 Days 7 Nights",
                totalReview: 5,
                price: 56999,
                discount: 10,
                date: "12/02/2024",
                ages: "Min Age: 12+",
                pickup: "Pickup: Airpot",
                content1: `Get Ready for an unforgettable adventure to Ladakh, the "Land of High Passes," in the Himalayas. Leh Ladakh is a paradise for nature lovers and adventure seekers alike.
                `,

                services: [
                    {
                        serviceName: "Departure & Return Location - ",
                        serviceText: "Delhi"
                    },

                ],
                inclusions: [
                    {
                        inclusionsText: "Team Captain                        "
                    },
                    {
                        inclusionsText: "Royal Enfield Himalayan motorcycle with fuel  "
                    },
                    {
                        inclusionsText: "Comfy Hotel Stay"
                    },
                    {
                        inclusionsText: "Delicious Food "
                    },
                    {
                        inclusionsText: "1-Hour Shikara Ride on Dal Lake, Srinagar"
                    },
                    {
                        inclusionsText: "Complimentary Houseboat Stay.",
                    },
                    {
                        inclusionsText: "All transportation",
                    },
                    {
                        inclusionsText: "Portable oxygen cylinders & First Aid Kit",
                    },
                    {
                        inclusionsText: "Helmets for Rider and Pillion, Knee Guard & Riding Jacket for Both Rider & Pillion",
                    },
                    {
                        inclusionsText: "Mechanic ",
                    },
                    {
                        inclusionsText: "Inner Line Permit ",
                    },
                    {
                        inclusionsText: "A backup vehicle for Luggage ",
                    },
                    {
                        inclusionsText: "Certificate of Riding on completion",
                    },

                ],
                exclusions: [
                    {
                        exclusionsText: "Air or rail fares "
                    },
                    {
                        exclusionsText: "Anything not explicitly listed in the inclusions is not covered."
                    },
                    {
                        exclusionsText: "Lunch"
                    },
                    {
                        exclusionsText: "Any personal expenses"
                    },
                    {
                        exclusionsText: "Parking tickets"
                    },
                    {
                        exclusionsText: "GST @ 5% is Excluded Inner line permits and Restricted area permits for Ladakh region "
                    },


                ],
                complementaries: [
                    {
                        complementariesName: "Complementaries",
                        complementariesText: ""
                    }
                ],
                expect: [
                    {
                        expectHeading: "What to Expect?",
                        expectText: `Ladakh   is a hill station nestled in the Beas River Valley of Himachal Pradesh, India. It is known for its natural beauty, adventure activities, and pleasant climate. Here's a glimpse of what you can expect on a trip to Ladakh
                        :`,
                        expectSubData: [
                            {
                                expectSubName: "Challenging terrain",
                                expectSubText: "The roads in Ladakh can be rough and challenging, with steep climbs, loose gravel, and water crossings.  "
                            },
                            {
                                expectSubName: "High altitude",
                                expectSubText: " Ladakh is located at a high altitude, so be sure to acclimatize properly before you start riding.                                 "
                            },
                            {
                                expectSubName: "Unique culture",
                                expectSubText: "Ladakh is a Buddhist region with a rich culture and history. You'll have the opportunity to visit monasteries, gompas, and meet the friendly Ladakhi people.                                "
                            },
                            {
                                expectSubName: "Unforgettable experience",
                                expectSubText: "A Ladakh bike tour is a challenging but rewarding experience that will stay with you for a lifetime.                                "
                            },
                            {
                                expectSubName: "Breathtaking landscapes",
                                expectSubText: "Ladakh is home to some of the highest mountain passes in the world, including the Khardung La and Chang La.  "
                            },


                        ]
                    }
                ],
                itineraryName: "Itinerary",
                itinerarySubData: [
                    {
                        itineraryHeading: "Day 1:  Arrival at Srinagar      ",
                        itineraryText1: "Arrive in Srinagar and check into hotels.    ",
                        itineraryText2: "Explore the charm of srinagar with a leisurely exploration.   ",
                        itineraryText3: "Overnight stay at the hotel.                ",
                    },
                    {
                        itineraryHeading: "Day 2: Srinagar to Kargil  ",
                        itineraryText1: "This journey is about 200 kilometers and can take anywhere between 7 to 10 hours depending on your travel mode and sightseeing stops.     ",
                        itineraryText2: "On your way to kargil, you can make a detour to Sonamarg, a hill station known for its meadows and glaciers. ",
                        itineraryText3: "You can also visit the Drass War Memorial, another war memorial dedicated to the Indian soldiers who fought in the Kargil War.",

                    },
                    {
                        itineraryHeading: "Day 3:  Kargil to Leh",
                        itineraryText1: `After breakfast, embark on a scenic drive from Kargil to Leh, a distance of about 220 kilometers.
                        `,
                        itineraryText2: "Along the way, stop at some of the following places:  ",
                        itineraryText3: `Magnetic Hill: An optical illusion that makes it appear vehicles roll uphill on their own.`,
                        itineraryText4: "Hall of Fame: A war memorial dedicated to the Indian soldiers who died in the Kargil War.",
                        itineraryText5: "Indus-Zanskar confluence: The meeting point of the Indus and Zanskar rivers."

                    },
                    {
                        itineraryHeading: "Day 4:  Leh to Nubra valley          ",
                        itineraryText1: "Get an early start as you embark on a thrilling journey from Leh to Nubra Valley via the Khardung La Pass ",
                        itineraryText2: `Upon reaching Nubra Valley, check into your hotel in Hunder or Diskit and spend the rest of the day exploring the surrounding area.  `,
                        itineraryText3: `You can visit the Diskit Monastery, the largest monastery in Nubra Valley, or take a camel ride on the sand dunes of Hunder, also known as the "cold desert."`
                    },
                    {
                        itineraryHeading: "Day 5: Nubra valley to Turtuk Village       ",
                        itineraryText1: "Turtuk is a charming village in the Balti tribe region under Indian administration ",
                        itineraryText2: `Spend time exploring the village and have lunch. Return to your camp in Nubra. Overnight stay at the camp `,
                    },
                    {
                        itineraryHeading: "Day 6: Nubra valley to Pangong Lake                        ",
                        itineraryText1: "Get an early start today as it's a long drive to Pangong Lake. ",
                        itineraryText2: `Take the Shyok Road, a more scenic route than going back to Leh and then to Pangong Lake. `,
                        itineraryText3: "Pangong Tso, also known as Pangong Lake, is a high-altitude lake famous for its stunning beauty and changing colors."
                    },

                    {
                        itineraryHeading: "Day 7: Pangong to Leh ",
                        itineraryText1: "Chang-La Pass: One of the highest motorable passes in the world                         ",
                        itineraryText2: "Tangste Village: Immerse yourself in the local culture, interact with residents, and capture the beauty   ",
                        itineraryText3: "Durbok Village: A hidden gem surrounded by rugged mountains   ",
                        itineraryText4: "Thikse Monastery  ",
                        itineraryText5: "Shey Palace: Conclude the day with a visit to the historic Shey Palace, a former royal residence."
                    },
                    {
                        itineraryHeading: "Day 8: Departure          ",
                        itineraryText1: "Congratulations! You’ve made it! ",
                        itineraryText2: "Departure with loads of memories & new friends                    ",
                    }
                ],
                relatedData: [
                    {
                        name: "Ladakh Deluxe Tour - Delhi to Srinagar - 11D/10N ",
                        photo: LadakhImg4,
                        links: "/ladakh-deluxe-tour-delhi-to-strinagar-11D/10N",
                        days: "11 Days 10 Nights",
                        totalReview: 5,
                        price: 29999,
                        discount: 10,
                    }
                    , {
                        name: "Ladakh Premium Tour - Delhi to Srinagar - 11D/10N                ",
                        photo: LadakhImg9,
                        links: "/ladakh-premium-tour-delhi-to-strinagar-11D/10N",
                        days: "11 Days 10 Nights",
                        totalReview: 5,
                        price: 56999,
                        discount: 10,
                    }
                ],
                imageData: [
                    {
                        photo: LadakhImg6
                    },
                    {
                        photo: LadakhImg7
                    },
                    {
                        photo: LadakhImg8
                    },
                    {
                        photo: LadakhImg9
                    },
                    {
                        photo: LadakhImg10
                    },
                ]
            },
        ]

    },
    {
        title: "Spiti Valley        ",
        img: SpitiValley,
        url: "/spiti-valley",
        description: `Hike through breathtaking landscapes, witness the crystal-clear waters of Chandratal Lake, and go on a spiritual journey visiting old monasteries.`,
        bannerImg: SpitiValleyBanner,
        tripSubTours: [
            {
                name: "Spiti Valley - Bike Trip - 10N/11D                ",
                photo: SpitiValleyImg1,
                mainImg: SpitiValleyBanner1,
                links: "/spiti-valley-bike-trip-10N/11N",
                days: "11 Days 10 Nights",
                totalReview: 5,
                price: 23999,
                discount: 10,
                date: "12/02/2024",
                ages: "Min Age: 12+",
                pickup: "Pickup: Airpot",
                content1: "Hike through breathtaking landscapes, witness the crystal-clear waters of Chandratal Lake, and go on a spiritual journey visiting old monasteries.",

                services: [
                    {
                        serviceName: "Departure & Return Location - ",
                        serviceText: "Delhi"
                    },

                ],
                inclusions: [
                    {
                        inclusionsText: "Team Captain                        "
                    },
                    {
                        inclusionsText: "RE-Himalayan 411CC bike with fuel        "
                    },
                    {
                        inclusionsText: "Comfy Hotel Stay"
                    },
                    {
                        inclusionsText: "Delicious Food "
                    },
                    {
                        inclusionsText: "All transportation"
                    },
                    {
                        inclusionsText: "Portable oxygen cylinders & First Aid Kit"
                    },


                ],
                exclusions: [
                    {
                        exclusionsText: "Travel expense                  "
                    },
                    {
                        exclusionsText: "Anything not explicitly listed in the inclusions is not covered."
                    },
                    {
                        exclusionsText: "Lunch"
                    },
                    {
                        exclusionsText: "Any personal expenses"
                    },
                    {
                        exclusionsText: "Parking tickets"
                    },
                    {
                        exclusionsText: "GST @ 5% is Excluded"
                    },

                ],
                complementaries: [
                    {
                        complementariesName: "Complementaries",
                        complementariesText: ""
                    }
                ],
                expect: [
                    {
                        expectHeading: "What to Expect?",
                        expectText: ``,
                        expectSubData: [
                            {
                                expectSubName: "Dramatic Landscapes",
                                expectSubText: "Spiti Valley is a cold desert mountain region. The landscape is characterized by barren mountains, deep gorges, and a scattering of colorful villages.                                "
                            },
                            {
                                expectSubName: "Remote Villages",
                                expectSubText: " Spiti Valley is sparsely populated, and the villages here have a very unique charm.                                 "
                            },
                            {
                                expectSubName: "Buddhist Monasteries",
                                expectSubText: "  Spiti Valley is a center of Tibetan Buddhism, and there are many beautiful monasteries scattered throughout the valley.            "
                            },
                            {
                                expectSubName: "Adventure Activities",
                                expectSubText: "  Spiti Valley is a great place for adventure activities like trekking, mountain biking, and camping.   "
                            },


                        ]
                    }
                ],
                itineraryName: "Itinerary",
                itinerarySubData: [
                    {
                        itineraryHeading: "Day 1:  Delhi to manali                ",
                        itineraryText1: "Depart from Delhi for an exciting adventure to Spiti.      ",
                        itineraryText2: "Enjoy a comfortable overnight journey in a Volvo bus                    ",
                        itineraryText3: "Pack a neck pillow, and snacks for a cozy trip.",
                    },
                    {
                        itineraryHeading: "Day 2:  ARRIVAL at Manali                        ",
                        itineraryText1: "Check in into a comfortable accommodation.",
                        itineraryText2: "Explore the charm of Manali with a leisurely exploration. ",
                        itineraryText3: "Rest and recharge for the journey ahead."

                    },
                    {
                        itineraryHeading: "Day 3:  MANALI TO JIBHI                        ",
                        itineraryText1: `Wake up early morning & after breakfast start your journey to Spiti Valley. 
                        `,
                        itineraryText2: "You will then be travelling through the rich forests of Deodar, breathtaking views of the valley, snow covered mountain peaks and fast flowing rivers. ",
                        itineraryText3: "Once you reach Jibhi you will check in to the hotel and take some rest. Later in the evening, explore the surroundings of the hotel."

                    },
                    {
                        itineraryHeading: "Day 4: Jibhi To Chitkul                        ",
                        itineraryText1: "Wake up early morning, have breakfast and check out from the hotel. After Check-out from the hotel, we will head towards Chitkul. ",
                        itineraryText2: `Enjoy the view En-route. Sangla is a town in Baspa Valley (Also referred to as the Sangla Valley) in Kinnaur District of Himachal Pradesh, close to the Tibetan border. Reach Chitkul by evening check-in at the Camps & visit 'THE LAST INDIAN VILLAGE". `,
                        itineraryText3: 'Have delicious dinner and over night stay in Rakchham'
                    },
                    {
                        itineraryHeading: "Day 5: Chitkul To Kalpa                        ",
                        itineraryText1: "Wake up early morning, have breakfast and check out from the hotel. After Check-out from the camp/homestay, we will head towards Kalpa.   ",
                        itineraryText2: "En-route enjoy the suicide point of Roghi in Kalpa. Nako is a beautiful village which is located at an altitude of 3625mt and has the backdrop of the highest mountain in Kinnaur District of Himachal Pradesh, close to the Tibetan border.  ",
                        itineraryText3: `Reach Nako/Kalpa by evening check-in at the hotel & visit frozen Nako Lake. Have delicious dinner and over night stay at hotel.`
                    },
                    {
                        itineraryHeading: "Day 6:   Kalpa To Kaza    ",
                        itineraryText1: "After an early morning breakfast, you will be leaving for Kaza.  ",
                        itineraryText2: "En-route, you will be taking the Indo-Tiber Highway and pass through Khab bridge that marks the confluence of Spiti and the Satluj River.              ",
                        itineraryText3: `Overnight Stay at Kaza.`,
                    },
                    {
                        itineraryHeading: "Day 7:   Explore Kaza                        ",
                        itineraryText1: "Wake up early morning and after having the breakfast, head for Spiti Valley divine sightseeing.",
                        itineraryText2: "Visit the most scenic monastery of Spiti Valley The Key Monastery.    ",
                        itineraryText3: `Further, drive to the highest suspension bridge in Asia - The Chicham Bridge. `,
                        itineraryText4: "Visit the iconic villages of Spiti-Hikkim(location of the World's Highest Post Office), Komik (the highest village in the world connected through a motorable road) & Langza (the site for the holy Buddha Statue) ",
                        itineraryText5: "Overnight Stay at Kaza"

                    },
                    {
                        itineraryHeading: "Day 8:  Kaza To Chandratal                        ",
                        itineraryText1: "Post breakfast, your motorbike expedition will be moving towards the next location which is the beautiful crescent-shaped Chandratal Lake.   ",
                        itineraryText2: "After passing Kunzum La you will take a right before Baltal to visit Chandratal Lake. The beauty of this place is spectacular and you will have to park your vehicles and then trek for two kilometres to reach the lake.  ",
                        itineraryText3: `Overnight Stay at chandratal`,

                    },
                    {
                        itineraryHeading: "Day 9: Chandratal To Manali  ",
                        itineraryText1: "Today we will start riding towards manali.  ",
                        itineraryText2: "On the way you’ll be crossing the atal tunnel and reach manali by evening.  ",
                        itineraryText3: `Overnight Stay in Manali`,
                    },
                    {
                        itineraryHeading: "Day 10: Departure Manali                        ",
                        itineraryText1: "Check out from the hotel.",
                        itineraryText2: "Board in your bus for overnight journey. ",
                        itineraryText3: `Departure with loads of memories & new friends`,
                    },
                    {
                        itineraryHeading: "Day 11:  Reached Delhi                        ",
                        itineraryText1: "Congratulations! You’ve made it! ",
                        itineraryText2: "Departure with loads of memories & new friends"

                    },
                ],
                relatedData: [
                    {
                        name: "Spiti Valley - Bike Trip - 10N/11D                ",
                        photo: SpitiValleyImg1,
                        links: "/spiti-valley-bike-trip-10N/11N",
                        days: "11 Days 10 Nights",
                        totalReview: 5,
                        price: 23999,
                        discount: 10,
                    },
                    {
                        name: "Spiti Valley - Backpacking Trip - 8N/9D                ",
                        photo: SpitiValleyImg2,
                        links: "/spiti-valley-backpacking-trip-8N/9N",
                        days: "9 Days 8 Nights",
                        totalReview: 5,
                        price: 24999,
                        discount: 10,
                    }
                ],
                imageData: [
                    {
                        photo: SpitiValley
                    },
                    {
                        photo: SpitiValley2
                    },
                    {
                        photo: SpitiValleyImg1
                    },
                    {
                        photo: SpitiValleyImg2
                    }
                ]
            },
            {
                name: "Spiti Valley - Backpacking Trip - 8N/9D                ",
                photo: SpitiValleyImg2,
                mainImg: SpitiValleyBanner2,
                links: "/spiti-valley-backpacking-trip-8N/9N",
                days: "9 Days 8 Nights",
                totalReview: 5,
                price: 24999,
                discount: 10,
                date: "12/02/2024",
                ages: "Min Age: 12+",
                pickup: "Pickup: Airpot",
                content1: "Hike through breathtaking landscapes, witness the crystal-clear waters of Chandratal Lake, and go on a spiritual journey visiting old monasteries.",

                services: [
                    {
                        serviceName: "Departure & Return Location - ",
                        serviceText: "Delhi"
                    },

                ],
                inclusions: [
                    {
                        inclusionsText: "Team Captain                        "
                    },
                    {
                        inclusionsText: "RE-Himalayan 411CC bike with fuel        "
                    },
                    {
                        inclusionsText: "Comfy Hotel Stay"
                    },
                    {
                        inclusionsText: "Delicious Food "
                    },
                    {
                        inclusionsText: "All transportation - Tempo Traveller [Shimla to Shimla] & AC Volvo Bus [Delhi to Shimla]                        "
                    },
                    {
                        inclusionsText: "Portable oxygen cylinders & First Aid Kit"
                    },


                ],
                exclusions: [
                    {
                        exclusionsText: "Travel expense                  "
                    },
                    {
                        exclusionsText: "Anything not explicitly listed in the inclusions is not covered."
                    },
                    {
                        exclusionsText: "Lunch"
                    },
                    {
                        exclusionsText: "Any personal expenses"
                    },
                    {
                        exclusionsText: "Parking tickets"
                    },
                    {
                        exclusionsText: "GST @ 5% is Excluded"
                    },

                ],
                complementaries: [
                    {
                        complementariesName: "Complementaries",
                        complementariesText: ""
                    }
                ],
                expect: [
                    {
                        expectHeading: "What to Expect?",
                        expectText: ``,
                        expectSubData: [
                            {
                                expectSubName: "Dramatic Landscapes",
                                expectSubText: "Spiti Valley is a cold desert mountain region. The landscape is characterized by barren mountains, deep gorges, and a scattering of colorful villages.                                "
                            },
                            {
                                expectSubName: "Remote Villages",
                                expectSubText: " Spiti Valley is sparsely populated, and the villages here have a very unique charm.                                 "
                            },
                            {
                                expectSubName: "Buddhist Monasteries",
                                expectSubText: "  Spiti Valley is a center of Tibetan Buddhism, and there are many beautiful monasteries scattered throughout the valley.            "
                            },
                            {
                                expectSubName: "Adventure Activities",
                                expectSubText: "  Spiti Valley is a great place for adventure activities like trekking, mountain biking, and camping.   "
                            },


                        ]
                    }
                ],
                itineraryName: "Itinerary",
                itinerarySubData: [
                    {
                        itineraryHeading: "Day 1: Delhi to Shimla                        ",
                        itineraryText1: "The group assembles at the pick-up point by 10:00 PM (tentative).                         ",
                        itineraryText2: "We 'll meet the team captains and a small tour briefing will also be conducted.  ",
                        itineraryText3: "Afterward, we’ll head out on an overnight journey to Shimla.",
                        itineraryText4: "Note: The pickup point would be Majnu Ka Tila (tentative)"
                    },
                    {
                        itineraryHeading: "Day 2: Shimla to Chitkul/sangla                        ",
                        itineraryText1: "Early in the morning, we’ll arrive in Shimla via the Hindustan-Tibet highway. ",
                        itineraryText2: "We’ll be changing our transportation from bus to Tempo Traveler at this point and will head out for Chitkul/Sangla.  ",
                        itineraryText3: "Upon reaching Chitkul/Sangla, we will check into our hotels where we will indulge in group bonding exercises. ",
                        itineraryText4: "Dinner followed by an overnight stay in Chitkul/Sangla."

                    },
                    {
                        itineraryHeading: "Day 3:   Chitkul/sangla to kalpa                        ",
                        itineraryText1: `Wake up early morning, have breakfast and check out from the hotel. `,
                        itineraryText2: "After Check-out from the camp/homestay, we will head towards Kalpa. En-route enjoy the suicide point of Roghi in Kalpa. ",
                        itineraryText3: "Nako is a beautiful village which is located at an altitude of 3625mt and has the backdrop of the highest mountain in Kinnaur District of Himachal Pradesh, close to the Tibetan border. ",
                        itineraryText4: "Reach Nako/Kalpa by evening check-in at the hotel & visit frozen Nako Lake. Have delicious dinner and over night stay at hotel."

                    },
                    {
                        itineraryHeading: "Day 4:  KALPA TO NAKO             ",
                        itineraryText1: "Post breakfast, we will head towards Nako. ",
                        itineraryText2: `In Nako, we’ll visit the serene Nako lake and pay our reverence to the Nako Monastery.  `,
                        itineraryText3: `Afterward, we 'll head back to our designated stays. Dinner and overnight stay at Nako.`
                    },
                    {
                        itineraryHeading: "Day 5: NAKO TO KAZA                        ",
                        itineraryText1: "Post breakfast, we will head towards the headquarters of Spiti which is Kaza.  ",
                        itineraryText2: "Enjoy the scenic drive along the Spiti River, stopping at vantage points to capture the stunning Himalayan landscapes.  ",
                        itineraryText3: `Afterward, we 'll head back to our designated stays. Dinner and overnight stay at kaza.`
                    },
                    {
                        itineraryHeading: "Day 6:  SPITI SIGHTSEEING DAY ",
                        itineraryText1: "Post breakfast, we’ll head out to visit the Key Monastery locally known as Kye Gompa, followed by Chicham Bridge which is Asia’s highest bridge.  ",
                        itineraryText2: "Later in the day, we will visit the highest post office in the world, Hikkim.    ",
                        itineraryText3: `Afterward, we’ll move towards Komic village which is one of the highest villages in the world connected by a motorable road. `,
                        itineraryText4: "Last but not least, we will visit the land of fossils, Langza, and visit the Buddha statue that sits on top of a hill overlooking the village. ",
                        itineraryText5: "Finally, we’ll head back to Kaza where we will have dinner and spend the night."
                    },
                    {
                        itineraryHeading: "Day 7:  KAZA TO KALPA                        ",
                        itineraryText1: "After breakfast, we will start our return journey to Kalpa. ",
                        itineraryText2: "We will reach Kalpa by evening where we will have dinner followed by an overnight stay."

                    },
                    {
                        itineraryHeading: "Day 8: KALPA TO SHIMLA                        ",
                        itineraryText1: "After breakfast, we 'll resume our return journey and head towards Shimla.  ",
                        itineraryText2: "Upon reaching Shimla, we will board an overnight bus towards Delhi.",


                    },
                    {
                        itineraryHeading: "Day 9:  Reached Delhi                        ",
                        itineraryText1: "Congratulations! You’ve made it!  ",
                        itineraryText2: "Departure with loads of memories & new friends  ",

                    },

                ],
                relatedData: [
                    {
                        name: "Spiti Valley - Bike Trip - 10N/11D                ",
                        photo: SpitiValleyImg1,
                        links: "/spiti-valley-bike-trip-10N/11N",
                        days: "11 Days 10 Nights",
                        totalReview: 5,
                        price: 23999,
                        discount: 10,
                    },
                    {
                        name: "Spiti Valley - Backpacking Trip - 8N/9D                ",
                        photo: SpitiValleyImg2,
                        links: "/spiti-valley-backpacking-trip-8N/9N",
                        days: "9 Days 8 Nights",
                        totalReview: 5,
                        price: 24999,
                        discount: 10,
                    }
                ],
                imageData: [
                    {
                        photo: SpitiValley
                    },
                    {
                        photo: SpitiValley2
                    },
                    {
                        photo: SpitiValleyImg1
                    },
                    {
                        photo: SpitiValleyImg2
                    }
                ]
            },

        ]

    },
    {
        title: "Kedarnath Dham/4 Dham        ",
        img: KedarNathImg4,
        bannerImg: KedarNathBanner,
        url: "/kedarnath-dham/4-dham",
        description: `Kedarnath Dham, one of the 12 Jyotirlingas, represents the radiant light of Shiva. One of the Char Dham pilgrimage sites and part of the Panch Kedar. The temple opens only for six months during the summer due to harsh winter conditions.`,
        tripSubTours: [
            {
                name: "Kedarnath Dham - 3N/4D     ",
                photo: KedarNathImg1,
                mainImg: KedarNathBanner1,
                links: "/kedarnath-dham-3N/4D",
                days: "4 Days 3 Nights",
                totalReview: 5,
                price: 11999,
                discount: 10,
                date: "12/02/2024",
                ages: "Min Age: 12+",
                pickup: "Pickup: Airpot",
                content1: "Kedarnath Dham, one of the 12 Jyotirlingas, represents the radiant light of Shiva. One of the Char Dham pilgrimage sites and part of the Panch Kedar. The temple opens only for six months during the summer due to harsh winter conditions.",
                services: [
                    {
                        serviceName: "Departure & Return Location - ",
                        serviceText: "Delhi"
                    },

                ],
                inclusions: [
                    {
                        inclusionsText: "Defined Sightseeing                         "
                    },
                    {
                        inclusionsText: "Breakfast/Dinner                         "
                    },
                    {
                        inclusionsText: "2 Nights of Stay in Standard Hotel 1 Night stay in camps "
                    },
                    {
                        inclusionsText: "Tempo Traveler/SUV/Car"
                    },
                    {
                        inclusionsText: "Drivers Tips Toll & State Taxes"
                    },


                ],
                exclusions: [
                    {
                        exclusionsText: "Anything not explicitly listed in the inclusions is not covered.                             "
                    },
                    {
                        exclusionsText: "Lunch"
                    },
                    {
                        exclusionsText: "Any personal expenses"
                    },
                    {
                        exclusionsText: "Parking tickets"
                    },
                    {
                        exclusionsText: "GST @ 5% is Excluded"
                    },

                ],
                complementaries: [
                    {
                        complementariesName: "Complementaries",
                        complementariesText: ""
                    }
                ],
                expect: [
                    {
                        expectHeading: "What to Expect?",
                        expectText: ``,
                        expectSubData: [
                            {
                                expectSubName: "A spiritual experience",
                                expectSubText: "Kedarnath is one of the holiest places in Hinduism, and a visit here is a must for devout Hindus. "
                            },
                            {
                                expectSubName: "Stunning Himalayan beauty",
                                expectSubText: " Kedarnath is nestled in the Garhwal Himalayas, and the scenery here is simply breathtaking.            "
                            },
                            {
                                expectSubName: "The Kedarnath Temple",
                                expectSubText: "The star attraction of Kedarnath is the Kedarnath Temple, one of the 12 Jyotirlingas of Lord Shiva.                "
                            },
                            {
                                expectSubName: "A tough trek",
                                expectSubText: "The trek to Kedarnath is not for the faint of heart. It is a 16-km uphill hike that can be quite challenging.         "
                            },
                            {
                                expectSubName: "Cold weather",
                                expectSubText: "Kedarnath is located at a high altitude, and the weather here can be quite cold, even in the summer months.   "
                            },


                        ]
                    }
                ],
                itineraryName: "Itinerary",
                itinerarySubData: [
                    {
                        itineraryHeading: "Day 1:  Set Sail from Haridwar                        ",
                        itineraryText1: "Meet our tour representative for a guided transfer to Kedarnath Ji temple.              ",
                        itineraryText2: "Start your journey from Haridwar to Guptkashi/Sonprayag. Take a holy Ganga Snan (ritualistic bath) in the Ganges at Haridwar.         ",
                        itineraryText3: "Check into the hotel upon reaching your destination for an overnight stay",
                    },
                    {
                        itineraryHeading: "Day 2:  Gaurikund to Kedarnath Ji Trek    ",
                        itineraryText1: "Start the journey at 5:30 a.m. to Gaurikund.                ",
                        itineraryText2: 'Sonprayag/Phata is the closest stop before Gaurikund, just 6 KM away. From Gaurikund, take the well-marked path to Kedarnath for trekking. ',
                        itineraryText3: "You can also rent a Pony or Doli for the journey."

                    },
                    {
                        itineraryHeading: "Day 3: Kedarnath to Phata/Sonprayag                        ",
                        itineraryText1: `Start your day with Aarti and Abhishek at Kedarnath temple 
                        `,
                        itineraryText2: "Have breakfast after the spiritual ceremony Go back down the path you came from Reach Gaurikund and drive to Phata/Sonprayag ",
                        itineraryText3: "Check into a hotel for the night"

                    },
                    {
                        itineraryHeading: "Day 4:  Sonprayag to Haridwar                        ",
                        itineraryText1: "Morning: Visit temple, then have breakfast. ",
                        itineraryText2: `Drive to Haridwar via Rishikesh. As the journey comes to an end, Cherish happy memories of the trip.`,
                    },
                ],
                relatedData: [
                    {
                        name: "Char Dham - 8N/9D",
                        photo: KedarNathImg5,
                        links: "/char-dham-8N/9D",
                        days: "9 Days 8 Nights",
                        totalReview: 5,
                        price: 24999,
                        discount: 10,
                    },
                    {
                        name: "Do Dham - 4N/5D  ",
                        photo: KedarNathImg2,
                        mainImg: KedarNathImg2,
                        links: "/do-dham-4N/5D",
                        days: "5 Days 4 Nights",
                        totalReview: 5,
                        price: 17999,
                        discount: 10,
                    }
                ],
                imageData: [
                    {
                        photo: KedarNathImg1
                    },
                    {
                        photo: KedarNathImg2
                    },
                    {
                        photo: KedarNathImg3
                    },
                    {
                        photo: KedarNathImg4
                    },
                    {
                        photo: KedarNathImg5
                    }
                ]
            },
            {
                name: "Do Dham - 4N/5D  ",
                photo: KedarNathImg2,
                mainImg: KedarNathBanner2,
                links: "/do-dham-4N/5D",
                days: "5 Days 4 Nights",
                totalReview: 5,
                price: 17999,
                discount: 10,
                date: "12/02/2024",
                ages: "Min Age: 12+",
                pickup: "Pickup: Airpot",
                content1: "Do Dham Yatra is a Hindu pilgrimage in Uttarakhand, India, involving visits to two of the four sacred shrines - Char Dhams. One is Kedarnath & Other is Badrinath.",

                services: [
                    {
                        serviceName: "Departure & Return Location - ",
                        serviceText: "Delhi"
                    },

                ],
                inclusions: [
                    {
                        inclusionsText: "Defined Sightseeing                         "
                    },
                    {
                        inclusionsText: "Breakfast/Dinner "
                    },
                    {
                        inclusionsText: "2 Nights of Stay in Standard Hotel 1 Night stay in camps "
                    },
                    {
                        inclusionsText: "Tempo Traveler/SUV/Car"
                    },
                    {
                        inclusionsText: "Drivers Tips Toll & State Taxes"
                    },


                ],
                exclusions: [
                    {
                        exclusionsText: "Anything not explicitly listed in the inclusions is not covered.                             "
                    },
                    {
                        exclusionsText: "Lunch"
                    },
                    {
                        exclusionsText: "Any personal expenses"
                    },
                    {
                        exclusionsText: "Parking tickets"
                    },
                    {
                        exclusionsText: "GST @ 5% is Excluded"
                    },

                ],
                complementaries: [
                    {
                        complementariesName: "Complementaries",
                        complementariesText: ""
                    }
                ],
                expect: [
                    {
                        expectHeading: "What to Expect?",
                        expectText: ``,
                        expectSubData: [
                            {
                                expectSubName: "Scenic Beauty",
                                expectSubText: "The route to the Dhams traverses through the Garhwal Himalayas, offering breathtaking views of snow-capped peaks, lush valleys, and gushing rivers."
                            },
                            {
                                expectSubName: "Spiritual Aura",
                                expectSubText: "The entire journey is filled with a spiritual aura. Pilgrims chant hymns, take holy dips in rivers, and participate in pujas and aartis. "
                            },
                            {
                                expectSubName: "Adventure Activities",
                                expectSubText: "Depending on your chosen route, you might have opportunities for trekking, camping, and exploring nature trails."
                            },
                            {
                                expectSubName: "Simple Amenities",
                                expectSubText: "Since the Dhams are located in remote areas, the stay and food options are usually basic.  "
                            },

                        ]
                    }
                ],
                itineraryName: "Itinerary",
                itinerarySubData: [
                    {
                        itineraryHeading: "Day 1:  Set Sail from Haridwar        ",
                        itineraryText1: "Meet our tour representative for a guided transfer to Kedarnath Ji temple.          ",
                        itineraryText2: "Start your journey from Haridwar to Guptkashi/Sonprayag. ",
                        itineraryText3: "Take a holy Ganga Snan (ritualistic bath) in the Ganges at Haridwar. ",
                        itineraryText4: "Check into the hotel upon reaching your destination for an overnight stay"
                    },
                    {
                        itineraryHeading: "Day 2:  Gaurikund to Kedarnath Ji Trek                        ",
                        itineraryText1: "Start the journey at 5:30 a.m. to Gaurikund.                   ",
                        itineraryText2: 'Sonprayag/Phata is the closest stop before Gaurikund, just 6 KM away. ',
                        itineraryText3: "From Gaurikund, take the well-marked path to Kedarnath for trekking. ",
                        itineraryText4: "You can also rent a Pony or Doli for the journey."

                    },
                    {
                        itineraryHeading: "Day 3: Kedarnath to Phata/Sonprayag              ",
                        itineraryText1: `Start your day with Aarti and Abhishek at Kedarnath temple.                         `,
                        itineraryText2: "Have breakfast after the spiritual ceremony  ",
                        itineraryText3: "Go back down the path you came from Reach Gaurikund and drive to Phata/Sonprayag Check into a hotel for the night"

                    },
                    {
                        itineraryHeading: "Day 4:  Sonprayag to Badrinath          ",
                        itineraryText1: "Start your day with breakfast and a journey to Shri Badrinath Ji temple. See many interesting temples along the way. ",
                        itineraryText2: `Check in to your hotel in Badrinath upon arrival. Take a holy dip in Tapt Kund and visit the main temple for prayers. `,
                        itineraryText3: "Relax in the afternoon. ",
                        itineraryText4: "Attend the evening Arti Darshan at the shrine (optional). ",
                        itineraryText5: "Spend the night at your hotel in Joshimath."
                    },
                    {
                        itineraryHeading: "Day 4:  Badrinath to Haridwar      ",
                        itineraryText1: "Start your morning with a temple visit (Darshan).  ",
                        itineraryText2: `Enjoy breakfast and begin your scenic drive to Haridwar via Rishikesh.  `,
                        itineraryText3: "Reach Haridwar, marking the end of your spiritual Chardham journey. ",
                        itineraryText4: "Take home unforgettable memories of this transformative experience. ",

                    },
                ],
                relatedData: [
                    {
                        name: "Kedarnath Dham - 3N/4D     ",
                        photo: KedarNathImg1,
                        links: "/kedarnath-dham-3N/4D",
                        days: "4 Days 3 Nights",
                        totalReview: 5,
                        price: 11999,
                        discount: 10,
                    },
                    {
                        name: "Char Dham - 8N/9D",
                        photo: KedarNathImg5,
                        links: "/char-dham-8N/9D",
                        days: "9 Days 8 Nights",
                        totalReview: 5,
                        price: 24999,
                        discount: 10,
                    }
                ],
                imageData: [
                    {
                        photo: KedarNathImg6
                    },
                    {
                        photo: KedarNathImg7
                    },
                    {
                        photo: KedarNathImg8
                    },
                    {
                        photo: KedarNathImg9
                    },
                    {
                        photo: KedarNathImg10
                    }
                ]
            },
            {
                name: "Char Dham - 8N/9D",
                photo: KedarNathImg5,
                mainImg: KedarNathBannner3,
                links: "/char-dham-8N/9D",
                days: "9 Days 8 Nights",
                totalReview: 5,
                price: 24999,
                discount: 10,
                date: "12/02/2024",
                ages: "Min Age: 12+",
                pickup: "Pickup: Airpot",
                content1: "Char Dham is located entirely in the Himalayas of Uttarakhand.",
                content2: "Yamunotri - source of the Yamuna River and dedicated to the goddess Yamuna ",
                content3: "Gangotri - source of the Ganges River and dedicated to the goddess Ganga",
                content4: "Kedarnath - enshrines Lord Shiva",
                content5: "Badrinath - same as the original Char Dham",
                services: [
                    {
                        serviceName: "Departure & Return Location - ",
                        serviceText: "Delhi"
                    },

                ],
                inclusions: [
                    {
                        inclusionsText: "Defined Sightseeing                         "
                    },
                    {
                        inclusionsText: "Breakfast/Dinner "
                    },
                    {
                        inclusionsText: "2 Nights of Stay in Standard Hotel 1 Night stay in camps "
                    },
                    {
                        inclusionsText: "Tempo Traveler/SUV/Car"
                    },
                    {
                        inclusionsText: "Drivers Tips Toll & State Taxes"
                    },


                ],
                exclusions: [
                    {
                        exclusionsText: "Anything not explicitly listed in the inclusions is not covered.                             "
                    },
                    {
                        exclusionsText: "Lunch"
                    },
                    {
                        exclusionsText: "Any personal expenses"
                    },
                    {
                        exclusionsText: "Parking tickets"
                    },
                    {
                        exclusionsText: "GST @ 5% is Excluded"
                    },

                ],
                complementaries: [
                    {
                        complementariesName: "Complementaries",
                        complementariesText: ""
                    }
                ],
                expect: [
                    {
                        expectHeading: "What to Expect?",
                        expectText: ``,
                        expectSubData: [
                            {
                                expectSubName: "Mesmerizing Beauty",
                                expectSubText: " The entire route of Char Dham is breathtaking, with snow-capped mountains, lush green valleys, and gushing rivers.          "
                            },
                            {
                                expectSubName: "Spiritual Significance",
                                expectSubText: "The Char Dham Yatra is considered a highly sacred pilgrimage for Hindus, offering a chance to cleanse the soul and wash away sins. Be prepared for crowds, especially during peak season."
                            },
                            {
                                expectSubName: "Simple Amenities",
                                expectSubText: "The towns serving as base camps for the Char Dham Yatra are small and offer simple amenities. Don't expect luxurious accommodations."
                            },
                            {
                                expectSubName: "Challenging Journey",
                                expectSubText: " The roads to the Char Dham shrines are often steep, narrow, and winding. Be prepared for long drives and potential altitude sickness. "
                            },

                        ]
                    }
                ],
                itineraryName: "Itinerary",
                itinerarySubData: [
                    {
                        itineraryHeading: "Day 1:  Set Sail from Haridwar              ",
                        itineraryText1: "Meet our tour representative for a guided transfer to Kedarnath Ji temple.     ",
                        itineraryText2: "Start your journey from Haridwar to Guptkashi/Sonprayag.  ",
                        itineraryText3: "Take a holy Ganga Snan (ritualistic bath) in the Ganges at Haridwar.  ",
                        itineraryText4: "Check into the hotel upon reaching your destination for an overnight stay"
                    },
                    {
                        itineraryHeading: "Day 2: Janki Chatti to Yamunotri and Back      ",
                        itineraryText1: "Morning: Have breakfast and drive to Janki Chatti.               ",
                        itineraryText2: 'Mid-morning: Start a 6 km trek to Yamunotri Dham. ',
                        itineraryText3: "Afternoon: Visit the Yamunotri temple and seek blessings.",
                        itineraryText4: "Late afternoon: Trek back to Janki Chatti.",
                        itineraryText5: "Evening: Drive back to Barkot for dinner and overnight stay.            "

                    },
                    {
                        itineraryHeading: "Day 3: Barkot to Uttarkashi          ",
                        itineraryText1: `Have breakfast Drive to Uttarkashi          `,
                        itineraryText2: "Check in to hotel upon arrival Visit Kashi Vishwanath Temple in the evening  ",
                        itineraryText3: "Enjoy dinner and stay overnight at hotel"

                    },
                    {
                        itineraryHeading: "Day 4: Uttarkashi to Gangotri        ",
                        itineraryText1: "Early breakfast at hotel Drive to Gangotri    ",
                        itineraryText2: `Take a holy dip in the Ganges Visit Gangotri temple for prayers 
                        `,
                        itineraryText3: "Drive back to Uttarkashi Dinner and overnight stay at hotel    ",

                    },
                    {
                        itineraryHeading: "Day 5:  Uttarkashi to Sonprayag/ Phata  ",
                        itineraryText1: "Today, your journey continues as you depart from Uttarkashi towards Guptkashi/Sonprayag.  ",
                        itineraryText2: `Upon reaching Phata/Sonprayag, check in at the hotel where you will be staying overnight  `,

                    },
                    {
                        itineraryHeading: "Day 6:  Gaurikund to Kedarnath Ji Trek       ",
                        itineraryText1: " Start driving to Gaurikund at 5:30 AM. Stop at Sonprayag (6 km before Gaurikund). ",
                        itineraryText2: ` Hire a local vehicle at Sonprayag to reach Gaurikund. `,
                        itineraryText3: "Hike or rent a pony/doli to reach Kedarnath from Gaurikund (well-defined path). ",
                        itineraryText4: "Check in to your hotel/camp at Kedarnath and relax."
                    },
                    {
                        itineraryHeading: "Day 7: Kedarnath to Phata/ Sonprayag   ",
                        itineraryText1: " Start your day with blessings: Attend Aarti and Abhishek at Kedarnath Temple.  ",
                        itineraryText2: `Enjoy breakfast after the ceremony. `,
                        itineraryText3: "Take the same path back down. ",
                        itineraryText4: "Check in to your hotel at Phata/Sonprayag near Gaurikund. Rest up for tomorrow: Enjoy dinner and a comfortable stay"
                    },
                    {
                        itineraryHeading: "Day 8: Phata/Sonprayag to Badrinath  ",
                        itineraryText1: " Start your day with breakfast and a journey to Shri Badrinath Ji temple. See many interesting temples along the way.  ",
                        itineraryText2: ` Check in to your hotel in Badrinath upon arrival. Take a holy dip in Tapt Kund and visit the main temple for prayers. `,
                        itineraryText3: "Relax in the afternoon. ",
                        itineraryText4: "Attend the evening Arti Darshan at the shrine (optional). ",
                        itineraryText5: "Spend the night at your hotel in Joshimath."
                    },
                    {
                        itineraryHeading: "DAY 9: Badrinath to Haridwar     ",
                        itineraryText1: "Start your morning with a temple visit (Darshan).  ",
                        itineraryText2: `Enjoy breakfast and begin your scenic drive to Haridwar via Rishikesh.  `,
                        itineraryText3: "Reach Haridwar, marking the end of your spiritual Chardham journey. ",
                        itineraryText4: "Take home unforgettable memories of this transformative experience.    "
                    },
                ],
                relatedData: [
                    {
                        name: "Kedarnath Dham - 3N/4D     ",
                        photo: KedarNathImg1,
                        links: "/kedarnath-dham-3N/4D",
                        days: "4 Days 3 Nights",
                        totalReview: 5,
                        price: 11999,
                        discount: 10,
                    },
                    {
                        name: "Do Dham - 4N/5D  ",
                        photo: KedarNathImg2,
                        mainImg: KedarNathImg2,
                        links: "/do-dham-4N/5D",
                        days: "5 Days 4 Nights",
                        totalReview: 5,
                        price: 17999,
                        discount: 10,
                    }
                ],
                imageData: [
                    {
                        photo: KedarNathImg11
                    },
                    {
                        photo: KedarNathImg12
                    },
                    {
                        photo: KedarNathImg13
                    },
                    {
                        photo: KedarNathImg14
                    },
                    {
                        photo: KedarNathImg15
                    },
                    {
                        photo: KedarNathImg16
                    }
                ]
            },


        ]

    },
]