import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Manali from "../assets/manali/manali1.webp";
import Kasmir from "../assets/slider1.jpg";
import Ladakh from "../assets/kedarnath/kedarnath10.webp";
import { IoTimeOutline } from "react-icons/io5";
import { FaStar } from "react-icons/fa6";
import { LuIndianRupee } from "react-icons/lu";
import { VscSymbolEvent } from "react-icons/vsc";
import KashmirImg from "../assets/kashmir/kashmir2.webp"
import KashmirImg6 from "../assets/kashmir/kashmir-tour-3.webp"
import LadakhImg from "../assets/ladakh/ladakh10.webp"
import CharDham from "../assets/kedarnath/kedarnath5.webp"
import SpitiValley from "../assets/spiti-v/spiti3.png"
import Aos from "aos";
const PopularTour = () => {
  useEffect(() => {
    window.scrollTo({ top: "0", behavior: "smooth" })
    Aos.init({
      duration: 1000
    });
  })
  return (
    <>
      {/* popular */}
      <div className="container-section margin-100">
        <div className="home-destination-section position-relative">
          <h3 className="heading-h3 text-center">Popular Tours</h3>
          <span className="text-heading-span text-center blur-text">POPULAR</span>
          <div className="row margin-50 pt-2">
            <div className="col-md-4 col-sm-12 mb-4" data-aos="fade-right">
              <div className="popular-tour-img-box">
                <div className="popular-img">
                  <Link to={"/manali-budget-plan-4N5D"}>
                    <img src={Manali} alt="" />
                  </Link>
                </div>
                <div class="tour-content">
                  <span className="best-seller"> Best Seller</span>
                  <h5 className="heading-h5 mb-4">
                    <Link to={"/manali-budget-plan-4N5D"} className="text-black d-flex">
                      <span>
                        <VscSymbolEvent className="event-icon" />
                      </span>
                      Manali Budget Plan - 4N5D
                    </Link>
                  </h5>


                  <div className="d-flex justify-content-between">
                    <div >
                      <div>
                        <span>
                          <IoTimeOutline className="clock-icon" />
                        </span>
                        <span>5 Days 4 Nights</span>
                      </div>
                      <div className="review-content">
                        <div>
                          <span>
                            <FaStar className="star-icon" />
                          </span>
                          <span>
                            <FaStar className="star-icon" />
                          </span>
                          <span>
                            <FaStar className="star-icon" />
                          </span>
                          <span>
                            <FaStar className="star-icon" />
                          </span>
                          <span>
                            <FaStar className="star-icon" />
                          </span>
                          <span>(1 Review)</span>
                        </div>


                      </div>
                    </div>
                    <div>
                      <div>
                        <p className="para less-price-div" style={{ marginBottom: "2px" }}>
                          <s>
                            ₹ 16,498
                          </s>
                        </p>
                      </div>
                      <h4 className="heading-h4">
                        <LuIndianRupee /> 14,999
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-12 mb-4" data-aos="fade-up">
              <div className="popular-tour-img-box">
                <div className="popular-img">
                  <Link to={"/Kashmir-Backpacking-Trip-6N/7D"}>
                    <img src={KashmirImg} alt="" />
                  </Link>
                </div>
                <div class="tour-content">
                  <span className="best-seller"> Best Seller</span>
                  <h5 className="heading-h5 mb-4">
                    <Link to={"/Kashmir-Backpacking-Trip-5N/6D"} className="text-black d-flex">
                      <span>
                        <VscSymbolEvent className="event-icon" />
                      </span>
                      Kashmir Backpacking Trip - 6N/7D
                    </Link>
                  </h5>
                  <div className="d-flex justify-content-between">
                    <div >
                      <div>
                        <span>
                          <IoTimeOutline className="clock-icon" />
                        </span>
                        <span>5 Days 4 Nights</span>
                      </div>
                      <div className="review-content">
                        <div>
                          <span>
                            <FaStar className="star-icon" />
                          </span>
                          <span>
                            <FaStar className="star-icon" />
                          </span>
                          <span>
                            <FaStar className="star-icon" />
                          </span>
                          <span>
                            <FaStar className="star-icon" />
                          </span>
                          <span>
                            <FaStar className="star-icon" />
                          </span>
                          <span>(1 Review)</span>
                        </div>


                      </div>
                    </div>
                    <div>


                      <div>
                        <p className="para less-price-div" style={{ marginBottom: "2px" }}>
                          <s>
                            ₹ 23,098
                          </s>
                        </p>
                      </div>
                      <h4 className="heading-h4">
                        <LuIndianRupee />  20,999
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-12 mb-4" data-aos="fade-left">
              <div className="popular-tour-img-box">
                <div className="popular-img">
                  <Link to={"/ladakh-deluxe-tour-delhi-to-strinagar-11D/10N"}>
                    <img src={LadakhImg} alt="" />
                  </Link>
                </div>
                <div class="tour-content">
                  <h5 className="heading-h5 mb-4">
                    <Link to={"/ladakh-deluxe-tour-delhi-to-strinagar-11D/10N"} className="text-black d-flex">
                      <span>
                        <VscSymbolEvent className="event-icon" />
                      </span>
                      Ladakh Deluxe Tour - Delhi to Srinagar - 11D/10N
                    </Link>
                  </h5>
                  <div className="d-flex justify-content-between">
                    <div >
                      <div>
                        <span>
                          <IoTimeOutline className="clock-icon" />
                        </span>
                        <span>5 Days 4 Nights</span>
                      </div>
                      <div className="review-content">
                        <div>
                          <span>
                            <FaStar className="star-icon" />
                          </span>
                          <span>
                            <FaStar className="star-icon" />
                          </span>
                          <span>
                            <FaStar className="star-icon" />
                          </span>
                          <span>
                            <FaStar className="star-icon" />
                          </span>
                          <span>
                            <FaStar className="star-icon" />
                          </span>
                          <span>(1 Review)</span>
                        </div>


                      </div>
                    </div>
                    <div>


                      <div>
                        <p className="para less-price-div" style={{ marginBottom: "2px" }}>
                          <s>
                            ₹ 32,998
                          </s>
                        </p>
                      </div>
                      <h4 className="heading-h4">
                        <LuIndianRupee /> 29,999
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* offer block */}
      <div className="container-section margin-100 pt-3">
        <div className="row">
          <div className="col-md-6 col-sm-12 pe-0">
            <div className="offers-container">
              <h3 className="heading-h3" data-aos="flip-up">Enjoy Summer Deals</h3>
              <h2 className="heading-h2" data-aos="flip-down">Up to 40% Discount!</h2>
              <button data-aos="zoom-in">LEARN MORE</button>
              <h5 className="heading-h5" data-aos="flip-up">TrypScanner</h5>
              <p className="" data-aos="fade-down"> T&C Applied</p>
            </div>
          </div>
          <div className="col-md-6 col-sm-12  ps-0">
            <img src={Ladakh} alt="" />
          </div>
        </div>
      </div>

      {/* popular */}
      <div className="container-section margin-100">
        <div className="home-destination-section position-relative">
          <h2 className="heading-h3 text-center">Our Recommended</h2>
          <span className="text-heading-span text-center blur-text" data-aos="fade-up">Recommended</span>
          <div className="row margin-50 ">
            <div className="col-md-4 col-sm-12 mb-4" data-aos="fade-right">
              <div className="popular-tour-img-box">
                <div className="popular-img">
                  <Link to={"/char-dham-8N/9D"}>
                    <img src={CharDham} alt="" />
                  </Link>
                </div>
                <div class="tour-content">
                  <h5 className="heading-h5 mb-4">
                    <Link to={"/char-dham-8N/9D"} className="text-black">
                      <span>
                        <VscSymbolEvent className="event-icon" />
                      </span>
                      Char Dham - 8N/9D
                    </Link>
                  </h5>
                  <div className="d-flex justify-content-between">
                    <div >
                      <div>
                        <span>
                          <IoTimeOutline className="clock-icon" />
                        </span>
                        <span>9 Days 8 Nights</span>
                      </div>
                      <div className="review-content">
                        <div>
                          <span>
                            <FaStar className="star-icon" />
                          </span>
                          <span>
                            <FaStar className="star-icon" />
                          </span>
                          <span>
                            <FaStar className="star-icon" />
                          </span>
                          <span>
                            <FaStar className="star-icon" />
                          </span>
                          <span>
                            <FaStar className="star-icon" />
                          </span>
                          <span>(6 Review)</span>
                        </div>


                      </div>
                    </div>
                    <div>


                      <div>
                        <p className="para less-price-div" style={{ marginBottom: "2px" }}>
                          <s>
                            ₹ 19,798
                          </s>
                        </p>
                      </div>
                      <h4 className="heading-h4">
                        <LuIndianRupee /> 17,999
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-12 mb-4" data-aos="fade-down">
              <div className="popular-tour-img-box">
                <div className="popular-img">
                  <Link to={"/spiti-valley-backpacking-trip-8N/9N"}>
                    <img src={SpitiValley} alt="" />
                  </Link>
                </div>
                <div class="tour-content">
                  <h5 className="heading-h5 mb-4">
                    <Link to={"/spiti-valley-backpacking-trip-8N/9N"} className="text-black">
                      <span>
                        <VscSymbolEvent className="event-icon" />
                      </span>
                      Spiti Valley - Backpacking Trip - 8N/9D
                    </Link>
                  </h5>
                  <div className="d-flex justify-content-between">
                    <div >
                      <div>
                        <span>
                          <IoTimeOutline className="clock-icon" />
                        </span>
                        <span>9 Days 8 Nights</span>
                      </div>
                      <div className="review-content">
                        <div>
                          <span>
                            <FaStar className="star-icon" />
                          </span>
                          <span>
                            <FaStar className="star-icon" />
                          </span>
                          <span>
                            <FaStar className="star-icon" />
                          </span>
                          <span>
                            <FaStar className="star-icon" />
                          </span>
                          <span>
                            <FaStar className="star-icon" />
                          </span>
                          <span>(5 Review)</span>
                        </div>


                      </div>
                    </div>
                    <div>


                      <div>
                        <p className="para less-price-div" style={{ marginBottom: "2px" }}>
                          <s>
                            ₹ 27,498
                          </s>
                        </p>
                      </div>
                      <h4 className="heading-h4">
                        <LuIndianRupee /> 24,999
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-12 mb-4" data-aos="fade-left">
              <div className="popular-tour-img-box">
                <div className="popular-img">
                  <Link to={"/Kashmir-Backpacking-Trip-6N/7D"}>
                    <img src={KashmirImg6} alt="" />
                  </Link>
                </div>
                <div class="tour-content">
                  <span className="best-seller"> Best Seller</span>
                  <h5 className="heading-h5 mb-4">
                    <Link to={"/Kashmir-Backpacking-Trip-6N/7D"} className="text-black">
                      <span>
                        <VscSymbolEvent className="event-icon" />
                      </span>
                      Kashmir Backpacking Trip - 6N/7D
                    </Link>
                  </h5>
                  <div className="d-flex justify-content-between">
                    <div >
                      <div>
                        <span>
                          <IoTimeOutline className="clock-icon" />
                        </span>
                        <span>7 Days 6 Nights</span>
                      </div>
                      <div className="review-content">
                        <div>
                          <span>
                            <FaStar className="star-icon" />
                          </span>
                          <span>
                            <FaStar className="star-icon" />
                          </span>
                          <span>
                            <FaStar className="star-icon" />
                          </span>
                          <span>
                            <FaStar className="star-icon" />
                          </span>
                          <span>
                            <FaStar className="star-icon" />
                          </span>
                          <span>(1 Review)</span>
                        </div>


                      </div>
                    </div>
                    <div>


                      <div>
                        <p className="para less-price-div" style={{ marginBottom: "2px" }}>
                          <s>
                            ₹ 23,098
                          </s>
                        </p>
                      </div>
                      <h4 className="heading-h4">
                        <LuIndianRupee />  20,999
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PopularTour;
