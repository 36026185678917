import React, { useEffect, useState } from "react";
import KashmirBannerImg from "../assets/ladakh.avif";
import Manali from "../assets/manali.jpg";
import "./trypdetails.css";
import { IoTimeOutline } from "react-icons/io5";
import { FaCircle, FaStar } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { LuIndianRupee } from "react-icons/lu";
import { VscSymbolEvent } from "react-icons/vsc";

import { FaRegDotCircle } from "react-icons/fa";
import { FaCheckCircle } from "react-icons/fa";
import { RxCrossCircled } from "react-icons/rx";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
// import required modules
import { EffectFade, Navigation, Pagination } from "swiper/modules";
import { TripsFaq } from "./TripsFaq";
import { useLocation } from 'react-router-dom';
import { MdOutlineDateRange } from "react-icons/md";
import { FaUser } from "react-icons/fa";
import { IoLocation } from "react-icons/io5";
import { PiAirplaneTiltFill } from "react-icons/pi";
import { RiCustomerService2Fill } from "react-icons/ri";
import { MdStars } from "react-icons/md";
import { PiHandCoinsDuotone } from "react-icons/pi";
import { HiOutlineCurrencyRupee } from "react-icons/hi";
import { IoCallSharp } from "react-icons/io5";
import { MdOutlineMailOutline } from "react-icons/md";
import Aos from "aos";
import axios from "axios";
const TripDetails = ({ name,
    photo,
    mainImg,
    days,
    price,
    totalReview,
    relatedData,
    date,
    ages,
    pickup,
    content1,
    content2,
    keypointName,
    keyPoints,
    services,
    inclusions,
    exclusions,
    complementaries,
    expect,
    itineraryName,
    itinerarySubData,
    imageData

}) => {
    const [scrolled, setScrolled] = useState(false);
    // useEffect(() => {
    //     gsap.registerPlugin(ScrollTrigger);
    //     const scrollTriggerConfig = {
    //         trigger: ".navbar-container-section",
    //         start: "top top",
    //         end: "bottom bottom",
    //         pin: ".right",
    //     };
    //     ScrollTrigger.create(scrollTriggerConfig);
    //     return () => ScrollTrigger.getAll().forEach((instance) => instance.kill());
    // }, []);
    // const location = useLocation();

    // useEffect(() => {
    //     window.scrollTo(0, 0);
    // }, [location]);


    function calculateDiscountedPrice(originalPrice, discountPercentage) {
        // Convert the discount percentage to a decimal
        const discountDecimal = discountPercentage / 100;
        // Calculate the discount amount
        const discountAmount = originalPrice * discountDecimal;
        console.log(discountPercentage)
        // Subtract the discount amount from the original price to get the discounted price
        const discountedPrice = originalPrice + discountAmount;
        return discountedPrice;
    }




    const [isOpen, setIsOpen] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        phone: "",
        email: '',
        subject: '',
        message: ''
    });

    const togglePopup = () => {
        setIsOpen(!isOpen);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(formData); // You can send this data to your backend or handle it as per your requirement

        try {
            togglePopup();
            const data = await axios.post(`https://emails.trypscanner.com/mail.php?subject=trypscanner&message=name: ${formData.name} email: ${formData.email} phone: ${formData.phone} subject: ${formData.subject} message: ${formData.message}`)

        } catch (error) {

        }

    };

    useEffect(() => {

        Aos.init({
            duration: 1000
        });
    }, [])

    return (
        <>
            <div className="tryp-detail-banner">
                <img src={mainImg} alt="" />
                <div className="container-section">
                    <div className="tour-banner-content"></div>
                </div>
            </div>
            <div className="home-popup">
                {isOpen && (
                    <div className="popup">
                        <div className="popup-inner">
                            <button onClick={togglePopup} className="close-btn">
                                Close
                            </button>
                            <form onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label>Name:</label>
                                    <input
                                        type="text"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Email:</label>
                                    <input
                                        type="email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Phone:</label>
                                    <input
                                        type="text"
                                        name="phone"
                                        value={formData.phone}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Subject:</label>
                                    <input
                                        type="text"
                                        name="subject"
                                        value={formData.subject}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Message:</label>
                                    <textarea
                                        name="message"
                                        value={formData.message}
                                        onChange={handleChange}
                                        required
                                    ></textarea>
                                </div>
                                <button type="submit">Submit</button>
                            </form>
                        </div>
                    </div>
                )}
            </div>
            {/* tryp content */}
            <div className="details-navbar">
                <div className="container-section">
                    <div className="navbar-container">
                        <ul className="ul">
                            <li>
                                <a href="#details">Details</a>
                            </li>
                            <li>
                                <a href="#photo">Photo</a>
                            </li>
                            <li>
                                <a href="#itinerary">Itinerary</a>
                            </li>
                            <li>
                                <a href="#map">Map</a>
                            </li>
                            <li>
                                <a href="#FAQ">FAQ</a>
                            </li>
                            {/* <li>
                                <a href="#reviews">Reviews</a>
                            </li> */}
                        </ul>
                    </div>
                </div>
            </div>

            {/*  */}

            <div className="container-section">
                <div class="navbar-container-section mt-5 ">
                    <div class="left">
                        <div class="detailsWrapper">
                            {/* details */}
                            <div class="details" id="details">
                                <h3 className="heading-h3" data-aos="fade-down">
                                    {name}
                                </h3>
                                <div className="review-content">
                                    <div>
                                        <span>
                                            <FaStar className="star-icon" />
                                        </span>
                                        <span>
                                            <FaStar className="star-icon" />
                                        </span>
                                        <span>
                                            <FaStar className="star-icon" />
                                        </span>
                                        <span>
                                            <FaStar className="star-icon" />
                                        </span>
                                        <span>
                                            <FaStar className="star-icon" />
                                        </span>
                                        <span>({totalReview} Review)</span>
                                    </div>
                                </div>
                            </div>
                            <div className="details">
                                <div className="row">

                                    <div className="col-md-4 col-sm-6 mt-4" data-aos="fade-up">
                                        <div className="time-people">
                                            <span className="clock-icon 2">
                                                <IoTimeOutline />
                                            </span>
                                            <span>{days}</span>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-sm-6 mt-4" data-aos="fade-up">
                                        <div className="time-people">
                                            <span className="clock-icon 2">
                                                <MdOutlineDateRange />
                                            </span>
                                            <span>{date}</span>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-sm-6 mt-4" data-aos="fade-up">
                                        <div className="time-people">
                                            <span className="clock-icon 2">
                                                <FaUser />
                                            </span>
                                            <span>{ages}</span>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-sm-6 mt-4" data-aos="fade-up">
                                        <div className="time-people">
                                            <span className="clock-icon 2">
                                                <IoLocation />
                                            </span>
                                            <span>{pickup}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr className="my-3" />
                            <div className="details">
                                <p className="para">
                                    {content1}
                                </p>
                                <p className="para">
                                    {content2}
                                </p>
                            </div>

                            <hr className="mt-2 mb-3" />
                            {/* services */}
                            {services.map((service, i) => (
                                <div className="details">
                                    <div className="row" data-aos="fade-up">
                                        <div className="col-md-6 col-sm-12">
                                            <h5 className="heading-h5">{service.serviceName}</h5>
                                        </div>
                                        <div className="col-md-6 col-sm-12">
                                            <p className="para">
                                                {service.serviceText}
                                            </p>
                                        </div>
                                    </div>
                                    <hr className="my-3" />
                                </div>
                            ))}

                            {/* inclusions  */}

                            <div className="details">
                                <div className="row">
                                    <div className="col-md-6 col-sm-12" data-aos="fade-up">
                                        <h5 className="heading-h5">Inclusions</h5>
                                    </div>
                                    <div className="col-md-6 col-sm-12" >
                                        <ul className="ul">

                                            {inclusions.map((items, i) => (
                                                <li key={i} className="d-flex" data-aos="fade-down">
                                                    <span className="clock-icon green">
                                                        <FaCheckCircle />

                                                    </span>{" "}
                                                    <span>{items.inclusionsText}</span>{" "}
                                                </li>
                                            ))}

                                        </ul>
                                    </div>
                                </div>
                                <hr className="my-3" />
                            </div>


                            {/* exclusions */}
                            <div className="details">
                                <div className="row">
                                    <div className="col-md-6 col-sm-12">
                                        <h5 className="heading-h5" data-aos="fade-down">Exclusions</h5>
                                    </div>
                                    <div className="col-md-6 col-sm-12">
                                        <ul className="ul">
                                            {exclusions.map((ex, i) => (


                                                <li key={i} className="d-flex" data-aos="fade-up">
                                                    <span className="clock-icon">
                                                        <RxCrossCircled />
                                                    </span>
                                                    <span>{ex.exclusionsText}</span>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                                <hr className="my-3" />
                            </div>
                            {/* Complementaries */}
                            <div className="details">
                                <div className="row">
                                    <div className="col-md-6 col-sm-12">
                                        <h5 className="heading-h5" data-aos="fade-down">Complementaries</h5>
                                    </div>
                                    <div className="col-md-6 col-sm-12">
                                        <ul className="ul">
                                            {complementaries?.map((comp, i) => (


                                                <li key={i} data-aos="fade-up">
                                                    {/* <span className="clock-icon">
                                                        <RxCrossCircled />
                                                    </span> */}
                                                    -
                                                    <span>{comp.complementariesText}</span>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                                <hr className="mb-1 mt-0" />


                            </div>

                            {/* itinerary */}

                            <div className="details mt-4" id="itinerary">
                                <h2 className="heading-h2" data-aos="fade-up">{itineraryName}</h2>
                            </div>
                            {/* faq */}
                            <div className="details mb-4" id="FAQ">
                                <TripsFaq itinerarySubData={itinerarySubData} />
                            </div>
                            {/* photo */}
                            {expect ? <hr className="my-2" /> : ""}
                            <div className="details mb-4" id="photo">
                                <h2 className="heading-h2 my-2" data-aos="fade-down">Photos</h2>
                                <Swiper
                                    spaceBetween={30}
                                    effect={"fade"}
                                    loop={true}
                                    navigation={true}
                                    modules={[EffectFade, Navigation]}
                                    className="mySwiper photo-slider"
                                >

                                    {imageData.map((item, i) => (

                                        <SwiperSlide key={i}>
                                            <img src={item.photo} />
                                        </SwiperSlide>
                                    ))}
                                    {/* <SwiperSlide>
                                        <img src="https://swiperjs.com/demos/images/nature-2.jpg" />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <img src="https://swiperjs.com/demos/images/nature-3.jpg" />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <img src="https://swiperjs.com/demos/images/nature-4.jpg" />
                                    </SwiperSlide> */}
                                </Swiper>
                            </div>


                            {/* expect */}
                            <div className="details mt-4">
                                <h3 className="heading-h3 mb-3" data-aos="fade-down">{expect && expect[0]?.expectHeading}</h3>
                                <p className="para" data-aos="fade-down">
                                    {expect && expect[0]?.expectText}
                                </p>

                                <ul style={{ marginLeft: "-10px", listStyleType: "disc" }}>
                                    {expect && expect[0]?.expectSubData?.map((expec, i) => (
                                        <li key={i} data-aos="fade-up">
                                            <div className="d-flex align-items-center">

                                                <h6 className="mb-0"> <b> {expec.expectSubName} </b></h6>
                                            </div>
                                            <p >{expec.expectSubText}</p>

                                        </li>
                                    ))}
                                </ul>
                            </div>

                            <hr className="mt-0" />
                            {/* map */}
                            <div className="details mb-4 mt-3" id="map">
                                <h2 className="heading-h2" data-aos="fade-down">Map</h2>
                                <div className="my-2">
                                    {/* <iframe
                                        src="https://www.google.com/maps/d/embed?mid=1mGgtylMQHGAKR6HR8r8YLe5W4LU"
                                        width="100%"
                                        height={480}
                                        style={{ pointerEvents: "none" }}
                                    /> */}
                                    <iframe src="https://www.google.com.qa/maps/d/embed?mid=1ezov52w66liMTcG36lZgJALqZcIpaqE&ehbc=2E312F" width="100%" height="480" style={{ pointerEvents: "none" }} />
                                </div>
                            </div>
                            {/*  */}
                            <div className="details my-4">
                                <h2 className="heading-h2" data-aos="zoom-in">Related Tours</h2>
                                <div className="row mt-3">
                                    {relatedData.map((item, i) => (
                                        <div className="col-md-6 col-sm-12 mb-4" data-aos="fade-up" key={i}>
                                            <div className="popular-tour-img-box">
                                                <div className="popular-img">
                                                    <Link to={item.links}>
                                                        <img src={item.photo} alt="" />
                                                    </Link>
                                                </div>
                                                <div class="tour-content">
                                                    <span className="best-seller"> Best Seller</span>
                                                    <h5 className="heading-h5 mb-4">
                                                        <Link to={item.links} className="text-black">
                                                            <span>
                                                                <VscSymbolEvent className="event-icon" />
                                                            </span>
                                                            {item.name}
                                                        </Link>
                                                    </h5>
                                                    <div className="d-flex justify-content-between">
                                                        <div >
                                                            <div>
                                                                <span>
                                                                    <IoTimeOutline className="clock-icon" />
                                                                </span>
                                                                <span>{item.days}</span>
                                                            </div>
                                                            <div className="review-content">
                                                                <div>
                                                                    <span>
                                                                        <FaStar className="star-icon" />
                                                                    </span>
                                                                    <span>
                                                                        <FaStar className="star-icon" />
                                                                    </span>
                                                                    <span>
                                                                        <FaStar className="star-icon" />
                                                                    </span>
                                                                    <span>
                                                                        <FaStar className="star-icon" />
                                                                    </span>
                                                                    <span>
                                                                        <FaStar className="star-icon" />
                                                                    </span>
                                                                    <span>({item.totalReview} Review)</span>
                                                                </div>


                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div>
                                                                <p className="para less-price-div" style={{ marginBottom: "2px" }}>
                                                                    <s>
                                                                        ₹  {Math.floor(calculateDiscountedPrice(Number(item.price), item.discount)).toLocaleString()}/-
                                                                        {console.log("gg", item)}
                                                                    </s>
                                                                </p>
                                                            </div>
                                                            <h4 className="heading-h4">
                                                                <LuIndianRupee />  {(item.price).toLocaleString()}/-
                                                            </h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    ))}
                                    {/* <div className="col-md-6 col-sm-12 mb-4">
                                        <div className="popular-tour-img-box">
                                            <div className="popular-img">
                                                <Link to={""}>
                                                    <img src={Manali} alt="" />
                                                </Link>
                                            </div>
                                            <div class="tour-content">
                                                <span className="best-seller"> Best Seller</span>
                                                <h5 className="heading-h5 mb-4">
                                                    <Link to={""} className="text-black">
                                                        <span>
                                                            <VscSymbolEvent className="event-icon" />
                                                        </span>
                                                        Manali
                                                    </Link>
                                                </h5>
                                                <div className="d-flex justify-content-between">
                                                    <div >
                                                        <div>
                                                            <span>
                                                                <IoTimeOutline className="clock-icon" />
                                                            </span>
                                                            <span>5 Days 4 Nights</span>
                                                        </div>
                                                        <div className="review-content">
                                                            <div>
                                                                <span>
                                                                    <FaStar className="star-icon" />
                                                                </span>
                                                                <span>
                                                                    <FaStar className="star-icon" />
                                                                </span>
                                                                <span>
                                                                    <FaStar className="star-icon" />
                                                                </span>
                                                                <span>
                                                                    <FaStar className="star-icon" />
                                                                </span>
                                                                <span>
                                                                    <FaStar className="star-icon" />
                                                                </span>
                                                                <span>(1 Review)</span>
                                                            </div>


                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div>
                                                            <p className="para less-price-div" style={{ marginBottom: "2px" }}>
                                                                <s>
                                                                    ₹ 3000
                                                                </s>
                                                            </p>
                                                        </div>
                                                        <h4 className="heading-h4">
                                                            <LuIndianRupee /> 2000
                                                        </h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={` right `} >
                        <div >

                            <div className="proceed-book-section" data-aos="fade-left">
                                <p className="para">Price</p>
                                <hr />
                                <div className="prices"><span><PiAirplaneTiltFill /></span><span>₹ {price.toLocaleString()}/-</span></div>
                                <input type="date" name="" id="" />
                                <select name="" id="">
                                    <option value="">Number Of People</option>
                                    <option value="">1</option>
                                    <option value="">2</option>
                                    <option value="">3</option>
                                    <option value="">4</option>
                                    <option value="">5</option>
                                    <option value="">6</option>
                                </select>
                                <br />
                                <button className="search-btn" onClick={togglePopup}>Proceed Book</button>
                            </div>
                            <div className="why-booj-us" data-aos="fade-left">
                                <h6 className="heading-h6 mb-2"><b>Why Book With Us?</b></h6>
                                {/* <hr /> */}
                                <div className="ins"> <HiOutlineCurrencyRupee /><span>Seamless Best Price Guarantee</span></div>

                                <div className="ins"> <RiCustomerService2Fill /><span>24/7 Customer Support</span></div>

                                <div className="ins"><MdStars /> <span>Expert Curated Tours & Activities</span></div>

                                <div className="ins"> <PiHandCoinsDuotone /><span>Complimentary Travel Insurance</span></div>
                            </div>
                            <div className="get-question" data-aos="fade-left">
                                <div className="over-lay"></div>
                                <div className="cont">
                                    <h6 className="heading-h6 mb-4"><b>Have a question?</b></h6>
                                    <p >Do not hesitate to give us a call. We are an expert team and we are happy to talk to you.</p>
                                    <div className="ins"> <IoCallSharp /><span><a href="tel:+91 7982928446">+91 7982928446</a></span></div>

                                    <div className="ins mt-2"> <MdOutlineMailOutline /><span><a href="mailto:support@trypscanner.com"> support@trypscanner.com</a></span></div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TripDetails;
