import React, { Fragment, useEffect } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Routes, Route, useParams } from "react-router-dom";
import HomePage from "./hompage/HomePage";
import Footer from "./layout/Footer";
import AboutPage from "./about/AboutPage";
import Contact from "./layout/Contact";
// import Navigation from "./layout/Navigation";
import Navbar from "./layout/Navbar";
import { useLocation } from 'react-router-dom';
import { TripData } from "./tripsdetails/TripData";
import ToursPage from "./tripsdetails/ToursPage";
import TripDetails from "./tripsdetails/TripDetails";
import "./responsive.css"

import 'aos/dist/aos.css';
function App() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      <Navbar />
      {/* <Navigation /> */}
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/contact" element={<Contact />} />

        {TripData.map((data, i) => (
          <Route
            key={i}
            path={data.url}
            element={
              <ToursPage
                tripSubTours={data.tripSubTours}
                title={data.title}
                bannerImg={data.bannerImg}
              />
            }
          />
        ))}

        {TripData.map((subData, i) => (
          <Fragment key={i}>
            {subData.tripSubTours.map((item, i) => (
              <>
                <Route
                  key={i}
                  path={item.links}
                  element={
                    <TripDetails
                      name={item.name}
                      photo={item.photo}
                      days={item.days}
                      totalReview={item.totalReview}
                      price={item.price}
                      discount={item.discount}
                      date={item.date}
                      ages={item.ages}
                      pickup={item.pickup}
                      content1={item.content1}
                      content2={item.content2}
                      keypointName={item.keypointName}
                      keyPoints={item.keyPoints}
                      services={item.services}
                      inclusions={item.inclusions}
                      exclusions={item.exclusions}
                      complementaries={item.complementaries}
                      expect={item.expect}
                      mainImg={item.mainImg}
                      itineraryName={item.itineraryName}
                      itinerarySubData={item.itinerarySubData}
                      relatedData={item.relatedData}
                      imageData={item.imageData}
                    />
                  }
                />
              </>
            ))}
          </Fragment>
        ))}


      </Routes>
      <Footer />
    </>
  );
}

export default App;
