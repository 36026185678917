import React, { useEffect, useState } from "react";
import ContactBanner from "../assets/contact-banner.png";
import { IoCallSharp } from "react-icons/io5";
import { MdOutlineMail } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";
import axios from "axios";
import Aos from "aos";
const Contact = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    Aos.init({
      duration: 1000
    })
  }, []);

  const [formData, setFormData] = useState({
    name: '',
    phone: "",
    email: '',
    subject: '',
    message: ''
  });


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(formData); // You can send this data to your backend or handle it as per your requirement


    alert("Your form submit  successfully")

    try {
      const data = await axios.post(`https://emails.trypscanner.com/mail.php?subject=trypscanner&message=name: ${formData.name} email: ${formData.email} phone: ${formData.phone} subject: ${formData.subject} message: ${formData.message}`)
      setFormData({
        name: '',
        phone: "",
        email: '',
        subject: '',
        message: ''
      });


    } catch (error) {
      setFormData({
        name: '',
        email: '',
        phone: "",
        subject: '',
        message: ''
      });
    }
  };
  return (
    <>
      <div className="contact-banner page">
        <img src={ContactBanner} alt="" />
        <div className="contact-container">
          {/* <h1 className="heading-h1 text-white">CONTACT US</h1>
          <h5 className="heading-h5">Get Intouch</h5> */}
        </div>
      </div>
      <div className="container-section margin-100">
        <div className="row">
          <div className="col-md-4  col-sm-12">
            <div className="contact-content">
              <h3 className="heading-h3">
                <IoCallSharp />
              </h3>
              <h4 className="heading-h4 text-center" style={{ float: "unset" }}>
                PHONE
              </h4>{" "}
              <br />
              {/* <p className="para">
                A wonderful serenity has taken possession of my entire soul,
                like these sweet mornings.
              </p> */}
              <p > <a href="tel:+91 7982928446" className="color-black">+91 7982928446</a></p>
            </div>
          </div>
          <div className="col-md-4  col-sm-12">
            <div className="contact-content">
              <h3 className="heading-h3">
                <MdOutlineMail />
              </h3>
              <h4 className="heading-h4 text-center" style={{ float: "unset" }}>
                EMAIL
              </h4>{" "}
              <br />
              {/* <p className="para">
                A wonderful serenity has taken possession of my entire soul,
                like these sweet mornings.
              </p> */}
              <p ><a href="mailto:support@trypscanner.com" className="color-black">support@trypscanner.com</a></p>
            </div>
          </div>
          <div className="col-md-4  col-sm-12">
            <div className="contact-content">
              <h3 className="heading-h3">
                <FaLocationDot />
              </h3>
              <h4 className="heading-h4 text-center" style={{ float: "unset" }}>
                LOCATION
              </h4>{" "}
              <br />
              {/* <p className="para">
                4 apt. Flawing Street. The Grand Avenue. Liverpool, UK 33342
              </p> */}
              <p >


                <a href="https://maps.app.goo.gl/v5Tp5tZwU6U2wUPt6" className="color-black" target="_blank"> View On Google Map</a>
              </p>

            </div>
          </div>
        </div>
      </div>

      {/* contact-form */}
      <div className="browser-tour-section margin-100">
        <div className="container-section">
          <div className="contact-form-section">
            <h2 className="heading-h2" data-aos="fade-up">LEAVE US YOUR INFO</h2>
            <p className="para" data-aos="fade-down">And we will get back to you.</p>
            <div className="contact-form-container">
              <form onSubmit={handleSubmit}>
                <div>
                  <input type="text" placeholder="Full Name"

                    value={formData.name}
                    onChange={handleChange}
                    name="name" id="" />
                  <input type="email" placeholder="Email" name="email"
                    value={formData.email}
                    onChange={handleChange} id="" />
                </div>
                <div>
                  <input type="text" placeholder="Subject" name="subject"
                    value={formData.subject}
                    onChange={handleChange} id="" />
                </div>
                <div>
                  <input type="text" placeholder="Phone" name="phone"
                    value={formData.phone}
                    onChange={handleChange} id="" />
                </div>
                <div>
                  <textarea
                    placeholder="Message"
                    id=""
                    cols="30"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    rows="10"
                  ></textarea>
                </div>
                <div>
                  <button className="search-btn">SUBMIT</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
