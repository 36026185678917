import React from "react";
import "./footer.css";
import { IoCallSharp } from "react-icons/io5";
import { FaFacebookF } from "react-icons/fa";
import { FaPinterestP } from "react-icons/fa";
import { IoLogoInstagram } from "react-icons/io5";
import { FaTwitter } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import Logo from "../assets/white-logo.png";
import { useNavigate } from "react-router-dom";
import { FaLinkedin } from "react-icons/fa6";
const Footer = () => {
  const navigate = useNavigate()
  return (
    <>
      <footer className="margin-100">
        <div className="container-section">
          <div className="row">
            <div className="col-md-3 col-sm-6">
              <img src={Logo} className="footer-logo" alt="" />
              <p>
                TrypScanner, formerly The Elite Explorers is a renowned travel company based in Delhi NCR that offers a comprehensive range of Adventure, Leisure, Pilgrimage, and International Tours.
              </p>
              {/* <h5 className="heading-h5"></h5> */}
            </div>
            <div className="col-md-3 col-sm-6">
              <h5 className="heading-h5 ">About Us</h5>
              <ul className="ul mt-4">
                <li onClick={() => navigate("/about")}>Our Story</li>
                <li onClick={() => navigate("")}>Travel Blog & Tips</li>
                <li onClick={() => navigate("")}>Working With Us</li>
                <li onClick={() => navigate("/contact")}>Be Our Partner</li>
              </ul>
            </div>
            <div className="col-md-3 col-sm-6">
              <h5 className="heading-h5">Support</h5>
              <ul className="ul mt-4">
                <li onClick={() => navigate("/contact")}>Customer Support</li>
                <li onClick={() => navigate("")}>Privacy & Policy</li>
                <li onClick={() => navigate("/contact")}>Contact Channels</li>

              </ul>
            </div>
            <div className="col-md-3 col-sm-6">
              <h5 className="heading-h5">Call Us</h5>
              <h6 className="heading-h6">
                <span>
                  <IoCallSharp />
                </span>{" "}
                +91 7982928446
              </h6>
              <h5 className="heading-h5">Email</h5>
              <h6 className="heading-h6">support@trypscanner.com</h6>
            </div>
          </div>
        </div>
      </footer>
      <div className="copyright-section">
        <div className="container-section">
          <div className="copyright-container">
            <div>
              <p>&copy; TrypScanner All Rights Reserved</p>{" "}
            </div>
            <div>
              <span style={{ color: "gray" }}>Follow Us On</span>
              <span className="social-media-icons ms-3">
                <span>
                  <a href="https://www.facebook.com/trypscanner">

                    <FaFacebookF />
                  </a>
                </span>
                <span>
                  <a href="https://www.instagram.com/trypscanner/">
                    <IoLogoInstagram />

                  </a>
                </span>
                <span>
                  <a href="https://www.linkedin.com/company/trypscanner/?viewAsMember=true">
                    <FaLinkedin />
                  </a>
                </span>

              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
