import React, { useState, useEffect } from "react";
import "./navbar.css";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../assets/logo1.png";

import { MdKeyboardArrowDown } from "react-icons/md";
import { FaBars } from "react-icons/fa";
import { RxCross1 } from "react-icons/rx";
const Navbar = () => {

  const navigate = useNavigate();
  const [scrolled, setScrolled] = useState(false);
  const [open, setOpen] = useState(false);
  const handleClickOpen = (i) => {
    if (open === i) {
      return setOpen(true);
    }
    setOpen(i);

  }
  // console.log("object", auth)
  const [selected, setSelected] = useState(null);
  const toggle = (i) => {
    if (selected === i) {
      return setSelected(null);
    }
    setSelected(i);
  };
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const [subActive, setSetSubActive] = useState(false);
  const handleSubActive = () => setSetSubActive(!subActive);
  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 100) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  });

  let x = ["navbar"];
  if (scrolled) {
    x.push("scrolled");
  }
  return (

    <>

      {/* // <header className={x.join(" ")}>
    //   <div className="logo" onClick={() => navigate("/")}>
    //     <img src={Logo} alt="" />
    //   </div>

    //   <nav className="navigation">
    //     <ul>
    //       <li>
    //         <Link to="">Home</Link>
    //       </li>
    //       <li className="">
    //         <Link to="">Trips</Link>
    //         <ul className="dropdown">
    //           <li>
    //             <Link to={"/ladakh"}>Ladakh</Link>
    //           </li>
    //           <li>
    //             <Link to={"/kashmir"}>Kashmir</Link>
    //           </li>
    //           <li>
    //             <Link to={"/manali"}>Manali</Link>
    //           </li>
    //           <li>
    //             <Link to={"/spiti-valley"}>Spiti Valley</Link>
    //           </li>
    //           <li>
    //             <Link to={"/kedarnath"}>Kedarnath</Link>
    //           </li>
    //         </ul>
    //       </li>
    //       <li>
    //         <Link to="/about">About</Link>
    //       </li>
    //       <li>
    //         <Link to="/contact">Contact Us</Link>
    //       </li>
    //     </ul>
    //   </nav>
    // </header> */}


      <nav className={x.join(" ")}>
        <div className="nav-section">
          <div className="nav-container">
            <div className="logo" onClick={() => navigate("/")}>
              <img src={Logo} alt="" />
            </div>
            <ul className={click ? "ul active" : "ul"}>
              {NavData.map((item, i) => (
                <li key={i} onClick={() => { item.submenu ? handleSubActive() : handleClick(); }}>
                  <Link to={item.url}>{item.name}</Link>
                  {item.submenu && (<span><MdKeyboardArrowDown /></span>)}
                  {item.submenu && (
                    <>
                      <ul className={subActive ? 'dropdown-ul active' : 'dropdown-ul'} >
                        {item.subNav.map((data, i) => (
                          <li key={i} onClick={handleClick}><Link to={data.surl}>{data.sname}</Link></li>
                        ))}
                      </ul>
                    </>
                  )}
                </li>
              ))}

            </ul>
            <span className="bar-section" onClick={handleClick}>
              {click ? <RxCross1 className='cross-icons' /> : <FaBars />}
            </span>
          </div>
        </div>
      </nav>
    </>
  );
};
export default Navbar;


const NavData = [
  {
    name: "Home",
    url: "/",
  },
  {
    name: "Trips",
    url: "",
    submenu: true,
    subNav: [
      {
        sname: "Ladakh",
        surl: "/ladakh",
      },


      {
        sname: "Kashmir",
        surl: "/kashmir",
      },
      {
        sname: "Manali",
        surl: "/manali",
      },
      {
        sname: "Spiti Valley",
        surl: "/spiti-valley",
      },
      {
        sname: "Kedarnath",
        surl: "/kedarnath-dham/4-dham",
      },

    ]
  },
  {
    name: "About",
    url: "/about",
  },

  {
    name: "Contact Us",
    url: "/contact",
  },
]