import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import Manali from "../assets/manali.jpg";
import Kasmir from "../assets/slider1.jpg";
import Ladakh from "../assets/ladakh.avif";
import { TripData } from "../tripsdetails/TripData";
import Aos from "aos";
const PopularDestination = () => {
  useEffect(() => {
    Aos.init({
      duration: 1000
    });
  }, [])
  return (
    <>
      <div className="container-section margin-50">
        <div className="home-destination-section">
          <h2 className="heading-h3 text-center">Popular Destinations</h2>
          <div className="row ">
            {TripData.map((item, i) => (
              < Fragment key={i} >
                {i === 0 ? (
                  <div className="col-md-8 mt-4 col-sm-12" data-aos="fade-down" >
                    <div className="popular-img-box">
                      <Link to={item.url}>
                        <img src={item.img} alt="" />
                        <span className="tour-count">{item?.tripSubTours?.length} Tours</span>

                        <h5 className="heading-h5 show-and-remove">{item.title}</h5>
                        <div class="transition overlay ">
                          <div className="slow-animations">
                            <h5 className="heading-h5">{item.title}</h5>
                            <p className="para">
                              {item.description}
                            </p>
                            <Link to={item.url} className="view-tour">
                              View Tour
                            </Link>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                ) : (
                  <div className="col-md-4 col-sm-12 mt-4" data-aos="fade-up">
                    <div className="popular-img-box">
                      <Link to={item.url}>
                        <img src={item.img} alt="" />
                        <span className="tour-count">{item?.tripSubTours?.length} Tours</span>
                        {/* {console.log("object", item?.tripSubTours?.length)} */}
                        <h5 className="heading-h5 show-and-remove">{item.title}</h5>
                        <div class="transition overlay">
                          <div className="slow-animations">
                            <h5 className="heading-h5">{item.title}</h5>
                            <p className="para">
                              {item.description}
                            </p>
                            <Link to={item.url} className="view-tour">
                              View Tour
                            </Link>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>

                )}
              </ Fragment>
            ))}


          </div>
        </div>
      </div>
    </>
  );
};

export default PopularDestination;
