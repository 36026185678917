import React, { useEffect } from "react";
import AboutBanner from "./AboutBanner";

const AboutPage = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <AboutBanner />
    </>
  );
};

export default AboutPage;
